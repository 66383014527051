export const validateContactUsForm = (values) => {
  const errors = {};

  // Validate Name
  if (typeof values.name !== "string" || values.name.trim() === "") {
    errors.name = "Name must not be empty";
  }

  // Validate Email
  if (typeof values.email !== "string" || values.email.trim() === "") {
    errors.email = "Email must not be empty";
  }

  // Validate Mobile
  if (typeof values.mobile !== "string" || values.mobile.trim() === "") {
    errors.mobile = "Mobile Number must not be empty";
  }

  // Validate Comments
  if (typeof values.comments !== "string" || values.comments.trim() === "") {
    errors.comments = "Comment must not be empty";
  }

  return errors;
};

export const validateNewsletterSubscribe = (values) => {
  const errors = {};
  // Validate Name
  if (typeof values.email !== "string" || values.email.trim() === "") {
    errors.email = "Email must not be empty";
  }
  return errors;
};
