/* eslint-disable no-eq-null */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import {
  Map,
  Polygon,
  Marker,
  InfoWindow,
  GoogleApiWrapper,
} from "google-maps-react";
import Button from "react-bootstrap/Button";
import popupImg from "../../assets/images/project-detail/mask_group.jpg";

const ProjectMapContainer = (props) => {
  const [show, setShow] = useState(false);
  const [clickInfo, setClickInfo] = useState(false);
  const [showform, setShowform] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    initMap();
    removeIframe();

    const clickPoly = (index) => {
      debugger;

      var square = coordinates[index];

      if (square != undefined) {
        square.dispatchEvent("click");
      }
    };
  });

  var map = "";
  var infoWindow = "";
  var google = window.google;
  var coordinates = [];

  const locationArray = [
    {
      lat: "28.6699",
      lng: "77.373001",
      name: "Pramod",
      image: "https://via.placeholder.com/300x250.png?text=Text+1",
    },
    {
      lat: "28.6698",
      lng: "77.373001",
      name: "Nitesh ",
      image: "https://via.placeholder.com/300x250.png?text=Text+1",
    },
    {
      lat: "28.6697",
      lng: "77.373001",
      name: "Prateek Jha",
      image: "https://via.placeholder.com/300x250.png?text=Text+3",
    },
  ];
  var highlitBB = "";

  const removeIframe = () => {
    console.log(document.getElementsByTagName("iframe"));
    var chkiframe = setInterval(function () {
      if (document.getElementsByTagName("iframe").length > 0) {
        clearInterval(chkiframe);

        var iframe = document.getElementsByTagName("iframe")[0];
        console.log("check", document.getElementsByTagName("iframe")[0]);
        iframe.parentNode.removeChild(iframe);
      }
    }, 2000);
  };

  const checkMap = () => {
    var chkMapInt = setInterval(function () {
      if (document.getElementById("map")) {
        clearInterval(chkMapInt);
        initMap();
      }
    }, 500);
  };

  const initMap = () => {
    if (document.getElementById("map") == null) {
      checkMap();
      return false;
    }

    const uluru = { lat: 28.66993, lng: 77.373001 };
    // The map, centered at Uluru
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 20,
      center: uluru,
      mapTypeId: "terrain",
    });

    infoWindow = new google.maps.InfoWindow();

    if (locationArray.length) {
      for (let index = 0; index < locationArray.length; index++) {
        coordinates.push(calculateCoordinates(locationArray[index], index));
      }
    }
  };

  const calculateCoordinates = (location, index) => {
    var miles = 0.00621371;
    // var miles = 10;
    var top = maxLatLongOnBearing(location.lat, location.lng, 45, miles);
    var right = maxLatLongOnBearing(location.lat, location.lng, 135, miles);
    var bottom = maxLatLongOnBearing(location.lat, location.lng, 225, miles);
    var left = maxLatLongOnBearing(location.lat, location.lng, 315, miles);

    var infoContent = `<div className="infocontent" style="width:200px;"><div className="thumb"> <img src="${location.image}" alt="${location.name}" style="width:100%;"></div><div  className="content" style="width:50%;display:inline-block;">${location.name}</div></div></div>`;

    return drawSquare(top, right, bottom, left, location, index);
  };

  const maxLatLongOnBearing = (lat, lng, bearing, distance) => {
    var lonRads = toRad(lng);
    var latRads = toRad(lat);
    var bearingRads = toRad(bearing);
    var maxLatRads = Math.asin(
      Math.sin(latRads) * Math.cos(distance / 6371) +
        Math.cos(latRads) * Math.sin(distance / 6371) * Math.cos(bearingRads)
    );
    var maxLonRads =
      lonRads +
      Math.atan2(
        Math.sin(bearingRads) * Math.sin(distance / 6371) * Math.cos(latRads),
        Math.cos(distance / 6371) - Math.sin(latRads) * Math.sin(maxLatRads)
      );

    var maxLat = radiansToDegrees(maxLatRads);
    var maxLong = radiansToDegrees(maxLonRads);

    var coordinate = {
      lat: maxLat,
      lng: maxLong,
    };

    return coordinate;
  };

  const toRad = (value) => {
    return (value * Math.PI) / 180;
  };
  const radiansToDegrees = (radians) => {
    var pi = Math.PI;
    return radians * (180 / pi);
  };

  const drawSquare = (top, right, bottom, left, location, index) => {
    console.log(top.lat);
    console.log(top.lng);
    var flightPlanCoordinates = [
      { lat: top.lat, lng: top.lng },
      { lat: right.lat, lng: right.lng },
      { lat: bottom.lat, lng: bottom.lng },
      { lat: left.lat, lng: left.lng },
    ];

    console.log(flightPlanCoordinates);

    var square = new google.maps.Polygon({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#000000",
      strokeOpacity: 1.0,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    square.setMap(map);

    const cont = document.createElement("div");
    cont.classList.add("infocontent2");
    cont.addEventListener("click", () => clickPoly(index));
    cont.style.width = "100px";
    cont.innerText = "View More";

    google.maps.event.addListener(square, "mouseover", function () {
      highlight(this, location, cont);
    });
    google.maps.event.addListener(square, "mouseout", function () {
      clearHighlight();
    });

    createClickablePoly(square, map);

    return square;
  };

  const clickPoly = (index) => {
    setShowform(true);
  };

  const clearHighlight = (obj) => {
    highlitBB.setOptions({
      strokeColor: "#000000",
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      infoWindow: "",
    });
  };

  const highlight = (obj, location, cont) => {
    var latLng = new google.maps.LatLng(location.lat, location.lng);

    infoWindow.setContent(cont);
    infoWindow.setPosition(latLng);
    infoWindow.open(map);

    obj.setOptions({
      strokeColor: "#FF0000",
      fillColor: "#000000",
      fillOpacity: 0.35,
      infoWindow: infoWindow,
    });

    highlitBB = obj;
  };
  const createMouseinPoly = (poly, html, map) => {
    var contentString = html;
    google.maps.event.addListener(poly, "click", function (event) {
      infoWindow.setContent(contentString);
      infoWindow.setPosition(event.latLng);
      infoWindow.open(map);
    });
  };

  const createClickablePoly = (poly, map) => {
    // var contentString = html;
    google.maps.event.addListener(poly, "click", function (event) {
      /*
       * infoWindow.setContent(contentString);
       *  infoWindow.setPosition(event.latLng);
       *  infoWindow.open(map);
       */
    });
  };

  const handleModalShow = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <span onClick={handleModalShow}>
        <i
          className="fa fa-map-marker"
          aria-hidden="true"
          style={{ marginTop: "10px", fontsize: "33px" }}
        ></i>
      </span>
      <Modal show={isOpen} onHide={handleModalClose}>
        <div
          id="map"
          className="mfp-hide white-popup-block p-4 position-relative"
        ></div>
        <div className={showform ? "show-form" : "hide-form"}>
          <div className="position-absolute modal-box">
            <div className="">
              <nav>
                <div
                  style={{ height: "45px" }}
                  className="nav nav-tabs"
                  id="nav-tab"
                  role="tablist"
                >
                  <button
                    className="nav-link w-50 db-form-tab active"
                    id="crypto-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crypto-home"
                    type="button"
                    role="tab"
                    aria-controls="crypto-home"
                    aria-selected="true"
                    style={{ height: "45px !important" }}
                  >
                    Crypto
                  </button>
                  <button
                    className="nav-link w-50 db-form-tab"
                    id="fiat-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#fiat-profile"
                    type="button"
                    role="tab"
                    aria-controls="fiat-profile"
                    aria-selected="false"
                    style={{ height: "45px !important" }}
                  >
                    Fiat
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show px-4 active"
                  id="crypto-home"
                  role="tabpanel"
                  aria-labelledby="crypto-home-tab"
                >
                  <form className="row g-3 my-3">
                    <div className="row">
                      <div className="col-md-8">
                        <label
                          for="inputEmail4"
                          className="form-label label-gr"
                        >
                          Get
                        </label>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control bg-gray"
                            id="inputEmail4"
                          />
                        </div>
                        <label
                          for="inputEmail4"
                          className="form-label label-green"
                        >
                          USD price: $0
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label
                          for="inputPassword4"
                          className="form-label label-gr"
                        >
                          Token
                        </label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control bg-gray"
                            id="inputPassword4"
                          />
                          <div className="input-group-text bg-gr">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <label
                          for="inputEmail4"
                          className="form-label label-gr"
                        >
                          Pay
                        </label>
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control bg-gray"
                            id="inputEmail4"
                          />
                          <div className="input-group-text bg-gr">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </div>
                        </div>
                        <label
                          for="inputEmail4"
                          className="form-label label-red"
                        >
                          Minimum Sunset Energy NFT to purchase is 1.63672
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label
                          for="inputPassword4"
                          className="form-label label-gr"
                        >
                          Currency
                        </label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control bg-gray"
                            id="inputPassword4"
                          />
                          <div className="input-group-text bg-gr">
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-grid">
                      <button
                        type="submit"
                        style={{ width: "100%" }}
                        className="btn btn-primary green-btn"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane py-4 px-4 fade"
                  id="fiat-profile"
                  role="tabpanel"
                  aria-labelledby="fiat-profile-tab"
                >
                  <form className="row g-3 my-3">
                    <div className="col-12">
                      <label for="inputAddress" className="form-label label-gr">
                        Your Wallet Address
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control bg-gray"
                          id="inputAddress"
                          placeholder="1234 Main St"
                        />
                        <div className="input-group-text bg-gr">
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <label for="inputEmail4" className="form-label label-gr">
                        Get
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control bg-gray"
                          id="inputEmail4"
                        />
                      </div>
                      <label
                        for="inputEmail4"
                        className="form-label label-green"
                      >
                        USD price: $0
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label
                        for="inputPassword4"
                        className="form-label label-gr"
                      >
                        Token
                      </label>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control bg-gray"
                          id="inputPassword4"
                        />
                        <div className="input-group-text bg-gr">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <label for="inputEmail4" className="form-label label-gr">
                        Pay
                      </label>
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control bg-gray"
                          id="inputEmail4"
                        />
                        <div className="input-group-text bg-gr">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                      <label for="inputEmail4" className="form-label label-red">
                        Minimum Sunset Energy NFT to purchase is 1.63672
                      </label>
                    </div>
                    <div className="col-md-4">
                      <label
                        for="inputPassword4"
                        className="form-label label-gr"
                      >
                        Currency
                      </label>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control bg-gray"
                          id="inputPassword4"
                        />
                        <div className="input-group-text bg-gr">
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                      <div className="yellow-warning">
                        <div className="row p-2 my-2">
                          <div className="col-sm-1 col-xs-1 p-2 d-flex align-items-start justify-content-end">
                            <i
                              className="fa fa-info-circle warning-text"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div className="col-sm-11 col-xs-11 pe-2 ps-2">
                            <p className="m-0 warning-text">
                              Lorem, ipsum dolor sit amet consectetur
                              adipisicing elit. Illo deserunt sunt voluptatibus
                              ab minus, eos non fugit itaque quas eum nisi
                              magnam id! Rem, molestiae nam dolore delectus
                              deserunt libero!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-grid">
                      <button
                        type="submit"
                        style={{ width: "100%" }}
                        className="btn btn-primary green-btn"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: "AIzaSyD6EDQzaD1qrpl4VMt949oWCq2RGxwACjM",
})(ProjectMapContainer);
