import React from 'react';
import natureBanner from "../../assets/images/nature-nft-banner.jpg";
import energyBanner from "../../assets/images/energy-nft-banner.png";

const DashboardBanner = ({ type = 'energy' }) => {
    return (
        <>
            <div className={ `${type}-nft` }>
                <img src={ type === 'energy' ? energyBanner : natureBanner }
                    className="dashboard-tab-image" alt="" />
            </div>
        </>

    );
};

export default DashboardBanner;
