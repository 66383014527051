/* eslint-disable new-cap */
import React, { useState } from 'react';
import IconCardBg from './icon-card-bg';
import ReactHtmlParser from 'react-html-parser';
import "../../assets/css/promotion-banner.css";

const PromotionBanner = ({ promotionBody, promotionContent }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="section-3 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5 promotion-banner-main">
            <div className="card border-radidus-16 p-4 p-sm-4 p-md-5 p-lg-5">
                <div className="card-body p-0">
                    <div className="row">
                        {
                            ReactHtmlParser(promotionBody)
                        }

                        <table>
                            <tbody>
                                <tr className="sunset-ffffff-bg">
                                    {
                                        promotionContent.map((content, index) => {
                                            return <td onClick={() => setActiveIndex(index)} key={index}>
                                                <IconCardBg key={index} isActive={activeIndex === index} icon={content.promotion_line_image} iconText={content.heading} mainClassses={activeIndex === index ? "card border-radidus-8 text-center" : index === (promotionContent.length - 1) || index === (activeIndex - 1) ? "text-center" : "text-center border-right-2-C4C4C4"} />
                                            </td>;
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr className="sunset-ffffff-bg">
                                    {
                                        promotionContent.map((content, index) => {
                                            return <td key={index}>
                                                <div className={activeIndex === index ? "active-tab-pointer" : "inactive-tab-pointer"}></div>                                       </td>;
                                        })
                                    }
                                </tr>
                            </tbody>
                        </table>

                        <div className="card active-tab-content border-radidus-8 p-3 p-sm-3 p-md-4 p-lg-5">
                            <div className="card-body">
                                {
                                    ReactHtmlParser(promotionContent[activeIndex].content)
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default PromotionBanner;
