/* eslint-disable default-param-last */
import { MESSAGE_TYPES, MESSAGE_VARIANT } from "../redux-constant-types";

const initialState = {
    message: "",
    messageType: ""
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_TYPES.SET_ERROR_MESSAGE: return {
            ...state,
            message: action.payload.message,
            messageType: MESSAGE_VARIANT.ERROR
        };
        case MESSAGE_TYPES.SET_SUCCESS_MESSAGE: return {
            ...state,
            message: action.payload.message,
            messageType: MESSAGE_VARIANT.SUCCESS
        };
        default: return state;
    }
};

export default messageReducer;
