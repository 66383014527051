import React from "react";
import DasboardHeading from "./dashboard-heading";
import EnergyDasboardSummary from "./energynft-dashboard-summary";
import EnergyNFTGraph from "./energy-nft-graph";
import EnergyNFTTable from "./energy-nft-table";
import DashboardBanner from "./dashboard-banner";

const EnergyTabReport = ({ page }) => {
  return (
    <div
      className="tab-pane fade new-radius pb-4"
      id="energynft"
      role="tabpanel"
      aria-labelledby="energynft-tab"
    >
      <DashboardBanner type="energy" />

      {/* <DasboardHeading headingType="reports" page={page} /> */}
      <EnergyDasboardSummary />
      <div className="dashboard-graph my-5 mx-1 mx-md-5">
        <div className="tab-content" id="pills-tabContent">
          {/* <EnergyNFTGraph /> */}
          <EnergyNFTTable page={page} />
        </div>
      </div>
    </div>
  );
};

export default EnergyTabReport;
