import { MESSAGE_VARIANT, MESSAGE_TYPES } from "../redux-constant-types";

const setSuccessMessage = (message) => {
    return {
        type: MESSAGE_TYPES.SET_SUCCESS_MESSAGE,
        payload: {
            message,
            messageType: MESSAGE_VARIANT.SUCCESS
        }
    };
};

const setErrorMessage = (message) => {
    return {
        type: MESSAGE_TYPES.SET_ERROR_MESSAGE,
        payload: {
            message,
            messageType: MESSAGE_VARIANT.ERROR
        }
    };
};

export { setSuccessMessage, setErrorMessage };
