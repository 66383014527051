/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HowToBuy = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  var workflow = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    dots: false,
    centerPadding: '0px',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                adaptiveHeight: true,
            },
        },
    ],
  };
  return (
    <>
      {bannerSection &&
        <section className="workflow-section">
          <div className="container">
              <div className="title-head text-center">
                  {ReactHtmlParser(bannerSection.title)}
                  {ReactHtmlParser(bannerSection.banner_text)}
              </div>
              <Slider {...workflow} className="workflow-slider buySlider" id="buySlider">
                {bannerSection &&
                  bannerSection.banner_image.map((bannerImage, banner_key) => {
                    return (
                  <div key={banner_key}>
                      <div className="slide-item">
                          <figure>
                              <img src={PATH+bannerImage.image_url} alt="" />
                          </figure>
                          {ReactHtmlParser(bannerImage.heading)}
                      </div>
                  </div>
                  );
                })}
              </Slider>
          </div>
      </section>
      }
    </>
  );
};

export default HowToBuy;
