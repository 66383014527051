const MESSAGE_TYPES = {
    SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
    SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE"
};

const MESSAGE_VARIANT = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    WARNING: "WARNING"
};

export { MESSAGE_TYPES, MESSAGE_VARIANT };
