import React from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/home.css";
import homeSection5Img from "../../assets/images/sunset-nature-home-section5.svg";
import bankIcon from "../../assets/icons/bank.svg";
import profitIcon from "../../assets/icons/profit-icon.svg";
import profitIcon1 from "../../assets/icons/profit-icon1.svg";
import currencyIcon from "../../assets/icons/currency.svg";
import checkbookIcon from "../../assets/icons/checkbook.svg";
import homePageEnergyImage from "../../assets/images/sunset-energy-home-section2.svg";
import CountEntryCard from '../../components/modals/count-entry-card';
import bannerImage1 from "../../assets/images/sunset-nature-home-banner1.png";
import bannerImage2 from "../../assets/images/sunset-nature-home-banner2.png";
import bannerImage from "../../assets/images/sunset-energy-banner.png";
import MarketAndFeatures from '../../components/shared/market-and-features';
import IconCardBg from '../../components/modals/icon-card-bg';
import EnergyBanner from '../../components/shared/energy-banner';
import NewsAndMedia from '../../components/shared/news-and-media';
import GlobalCommunity from '../../components/shared/global-community';
import SupplierSection from '../../components/shared/supplier-section';

const SunsetEnergyHome = () => {
    return (
        <ThemeContainer type="public" module="energy">
            <EnergyBanner
                bannerImage={bannerImage}
                image1={bannerImage1}
                image2={bannerImage2}
                bannerText="The Sunset Capital only supplier of 100% Green & Renewable electricity Token provider."
            />
            <div className="home-main">
                <div className="section-1 row sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5 p-xl-5">
                    <div className="col-12">
                        <div className="fact-and-figures m-auto ml-5">
                            <MarketAndFeatures />
                        </div>
                    </div>
                </div>

                <div className="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="card border-radidus-16">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="entry-card w-100 p-5">
                                        <CountEntryCard counts={{ count1Value: "41 MW", count1Text: "Renewable Energy", count2Value: "250,000", count2Text: "kg/year of green H2", count3Value: "02", count3Text: "Datecenters" }} titleColor="#47DE00"/>
                                    </div>
                                    <img src={homePageEnergyImage} className="w-100"/>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                                    <br /><br /><br /><br />
                                    <h3 className=" font-36 font-w-500 mb-4">About Sunset Capital</h3>
                                    <p className="font-18 font-w-400 font-656565 mb-4">We are ideally positioned to work to accelerate the transition towards a carbonneutral economy, through emerging technologies and more environmentally friendly solutions, targeting off-grid areas and many industries from many hardto-abate carbon sectors.</p>
                                    <p className="font-20 font-w-600 mb-4 font-bold">Renewable Energy Project Specs:</p>
                                    <p className="font-18 font-w-400 font-656565 mb-4">1 MW of Renewable Energy (01 Mixed Wind & Solar Power Plant and 07 Solar Power Plant); </p>
                                    <p className="font-18 font-w-400 font-656565 mb-4"> 02 Green Hydrogen Plant production capacity of up to 250,000 kg/year of green H2.</p>
                                    <p className="font-18 font-w-400 font-656565 mb-4"> 02 Datacenters;</p>
                                    <button className= "btn sunset-yellow-bg font-20 font-w-500 font-272E35">Discover More</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="section-3 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="card border-radidus-16 p-lg-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <h1 className="font-42 font-w-400 mb-4">Why Own Sunset Watt Token?</h1>
                                <p className=" font-18 font-w-400 mb-4">Sunset Watt Tokens (otherwise known as the "SWT") is a reference to the physical land in forest area where you can do your banding, & each SWT constitutes 1 Watts of Green Energy.</p>

                                <div className="row mt-5">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-2 p-0">
                                        <IconCardBg isActive={true} icon={profitIcon} iconText="A stable asset"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={profitIcon1} iconText="Preserves wealth"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={bankIcon} iconText="Price maintains"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={currencyIcon} iconText="Hedge against"/>
                                    </div>
                                    <div className="col-lg-2 p-0">
                                        <IconCardBg isActive={false} icon={checkbookIcon} iconText="Long track record"/>
                                    </div>
                                    <div className="col-lg-1"></div>
                                </div>

                                <div className="card border-radidus-8 mt-5 p-lg-5">
                                    <div className="card-body">
                                        <h2>A Stable Asset</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae. Praesent at bibendum tortor. Pellentesque at purus at est varius ullamcorper. Aliquam arcu purus, euismod non turpis vitae, venenatis tincidunt metus. Duis vulputate a risus non lobortis. Mauris posuere, nulla ac facilisis egestas, nulla diam pulvinar neque.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="section-5 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                            <h3 className="font-36 font-w-600 mb-4">Superpowers for Smart Investors</h3>
                            <p className="font-18 font-w-400 font-656565 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae. Praesent at bibendum tortor. Pellentesque at purus at est varius ullamcorper. Aliquam arcu purus, euismod non turpis vitae, venenatis tincidunt metus. Investor Docs</p>
                            <button className="btn sunset-yellow-bg font-20 font-w-500 font-272E35">Investor Docs</button>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <img src={homeSection5Img} className="w-100" />
                        </div>
                    </div>
                </div>

                <div className="section-6 row sunset-ffffff-bg">
                    <SupplierSection />
                </div>

                <div className="section-7 sunset-F2F2F2-bg">
                    <NewsAndMedia
                        title1 = ""
                        heading1 = ""
                        title2 = "News and Media"
                        heading2 = "Sunset Capital in News & Media"
                    />
                </div>

                <div className="section-8 sunset-ffffff-bg">
                    <GlobalCommunity
                        title1 = ""
                        heading1 = "A global community"
                    />
                </div>
            </div>

        </ThemeContainer>
    );
};

export default SunsetEnergyHome;
