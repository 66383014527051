import React, { useEffect, useState } from "react";
import ThemeContainer from "../../containers/theme-container";
import { getProjectsList } from "../../core/services/api/project";
import "../../assets/css/project-listing.css";
import "../../assets/css/dashboard-style.css";
import ProjectListBanner from "../../components/shared/project-list-banner";
import ProjectsSection from "../../components/shared/projects-section";
import ProjectNewsMedia from "../../components/shared/project-news-media";
import ProjectListDesc from "../../components/shared/project-list-desc";
import NewsAndMedia from "../../components/shared/news-and-media";
import GlobalCommunity from "../../components/shared/global-community";

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const getProjectsData = async () => {
    const result = await getProjectsList();
    if (result) {
      setProjects(result.token_list);
    }
  };

  useEffect(() => {
    getProjectsData();
  }, []);

  return (
    <ThemeContainer type="public" module="project">
      <div className="main-content container-fluid p-0 header-margin">
        <ProjectListBanner />

        <ProjectsSection projects={projects} />

        <section className="news-det m-2 m-md-5 pb-5">
          <NewsAndMedia
            title1=""
            heading1=""
            title2="News and Media"
            heading2="Sunset Capital in News & Media"
          />
        </section>

        {/* <ProjectNewsMedia /> */}

        <section className="container-fluid commu py-5 section-8 sunset-ffffff-bg">
          <GlobalCommunity title1="" heading1="A global community" />
        </section>

        {/* <ProjectListDesc /> */}
      </div>
    </ThemeContainer>
  );
};

export default ProjectList;
