/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";

const Whitepaper = ({ bannerSection }) => {
  
  return (
    <section className="ourlatest-section">
      <div className="container">
        <div className="flex">
          <div className="col-left">
              {ReactHtmlParser(bannerSection.title)}
          </div>
          <div className="col-right">
            {ReactHtmlParser(bannerSection.banner_text)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Whitepaper;
