/* eslint-disable camelcase */
import URLS from "../../../configs/api-end-points";
import apiServices from "../data/api_service";

const getProjectsList = async () => {
  const data = {};
  const url = URLS.POST_TOKEN_LIST;
  const result = await apiServices.post(url, data);
  return result;
};

const getProjectDetail = async (values) => {
  const data = {
    token_id: values,
  };
  const url = URLS.POST_TOKEN_DETAIL;
  const result = await apiServices.post(url, data);
  return result;
};

export { getProjectsList, getProjectDetail };
