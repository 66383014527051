const extendTime = (date, offset = 0) => {
    const dt = new Date(date);
    dt.setHours(dt.getHours() + offset);
    return dt;
};

const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month =
    date.getMonth() + 1 <= 9
      ? `${`0${date.getMonth() + 1}`}`
      : date.getMonth() + 1;
  const day =
    date.getDate() + 1 <= 9 ? `${`0${date.getDate()}`}` : date.getDate();
  const hours =
    date.getHours() + 1 <= 9 ? `${`0${date.getHours()}`}` : date.getHours();
  const minutes =
    date.getMinutes() + 1 <= 9
      ? `${`0${date.getMinutes()}`}`
      : date.getMinutes();
  const seconds =
    date.getSeconds() + 1 <= 9
      ? `${`0${date.getSeconds()}`}`
      : date.getSeconds();
  const milliseconds = date.getMilliseconds();

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export { extendTime, formatDateTime };
