import React from "react";
import newsImg from "../../assets/images/project-detail/spain-forest-nft-project-image.png";
import newsLogo from "../../assets/images/project-detail/cnb_logo.png";

const ProjectNewsMedia = () => {
  return (
    <>
      <section className="news-det m-2 m-md-5 pb-5">
        <div className="main-head my-5">
          <h5 className="head-4">News and Media</h5>
          <h2 className="head-5">Sunset Capital In News and Media</h2>
        </div>
        <div className="news-det-inner">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card new-card position-relative">
                    <img
                      src={newsImg}
                      className="card-img-top position-relative"
                      alt="..."
                    />
                    <img
                      src={newsLogo}
                      className="card-img-icon position-absolute"
                      alt="..."
                    />
                    <div className="card-body new-card-text">
                      <h6 className="card-heading">Card Heading</h6>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 py-3">
                  <div className="card new-card position-relative">
                    <img
                      src={newsImg}
                      className="card-img-top position-relative"
                      alt="..."
                    />
                    <img
                      src={newsLogo}
                      className="card-img-icon-s position-absolute"
                      alt="..."
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 py-3">
                  <div className="card new-card position-relative">
                    <img
                      src={newsImg}
                      className="card-img-top position-relative"
                      alt="..."
                    />
                    <img
                      src={newsLogo}
                      className="card-img-icon-s position-absolute"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="col-lg-12 col-md-12 py-1">
                <div className="card new-card position-relative">
                  <img
                    src={newsImg}
                    className="card-img-top position-relative"
                    alt="..."
                  />
                  <img
                    src={newsLogo}
                    className="card-img-icon-s position-absolute"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 py-2">
                <div className="card new-card position-relative">
                  <img
                    src={newsImg}
                    className="card-img-top position-relative"
                    alt="..."
                  />
                  <img
                    src={newsLogo}
                    className="card-img-icon-s position-absolute"
                    alt="..."
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 py-2">
                <div className="card new-card position-relative">
                  <img
                    src={newsImg}
                    className="card-img-top position-relative"
                    alt="..."
                  />
                  <img
                    src={newsLogo}
                    className="card-img-icon-s position-absolute"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectNewsMedia;
