import { NFTS } from "../redux-constant-types";

const setMasterData = (values) => {
  return {
    type: NFTS.SET_MASTER_DATA,
    payload: {
      masterData: values,
    },
  };
};
const setEnergyMasterData = (values) => {
  return {
    type: NFTS.SET_ENERGY_MASTER_DATA,
    payload: {
      energyMasterData: values,
    },
  };
};

const setDashboardData = (values) => {
  return {
    type: NFTS.SET_DASHBOARD_DATA,
    payload: {
      dashboard: values.dashboard,
      offSet: values.offSet,
    },
  };
};
const setEnergyDashboardData = (values) => {
  return {
    type: NFTS.SET_ENERGY_DASHBOARD_DATA,
    payload: {
      energyDashboard: values,
    },
  };
};
const setSelectedCrypto = (values) => {
  return {
    type: NFTS.SET_SELECTED_CRYPTO,
    payload: {
      selectedCrypto: values,
    },
  };
};
const setAssetRequest = (values) => {
  return {
    type: NFTS.SET_ASSET_REQUEST,
    payload: {
      assetRequest: values,
    },
  };
};
const setPaymentId = (values) => {
  return {
    type: NFTS.SET_PAYMENT_ID,
    payload: {
      assetRequestId: values.assetRequestId,
      paymentId: values.paymentId,
    },
  };
};

export {
  setMasterData,
  setDashboardData,
  setSelectedCrypto,
  setEnergyDashboardData,
  setEnergyMasterData,
  setPaymentId,
  setAssetRequest,
};
