import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import NatureEnergyTabs from "../../components/shared/nature-and-energy-tabs";
import EnergyNFTTabDashboard from "../../components/shared/energynft-tab-content-dashboard";
const EnergyNft = () => {
  return (
    <ThemeContainer type="private" module="nature" pageTitle="Energy NFT">
      <div className="col-sm p-3 min-vh-100 d-flex flex-wrap">
        <div className="dashboard-tiles m-0 m-md-5">
          <div className="dashboard-pills">
            <NatureEnergyTabs naturenft="no" />

            <div className="tab-content" id="myTabContent">
              <EnergyNFTTabDashboard naturenft="no" page={"energy"} />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default EnergyNft;
