import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { validateUserLogin } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { multiLogin } from "../../../core/services/api/user";
import { useSelector, useDispatch } from "react-redux";
import loginSubmit from "../../../core/utils/login_submit";
import useAlert from "../../../hooks/use-alert";
import userIcon from "../../../assets/icons/userIcon.svg";
import emailIcon from "../../../assets/icons/emailIcon.svg";
import mobileIcon from "../../../assets/icons/mobileIcon.svg";
import { getTFA } from "../../../core/utils/token";
import { Form } from "react-bootstrap";
import { updateProfile } from "../../../core/services/api/nft";

const LoginForm = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [loading, setLoading] = useState(true);

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      name: values.name ? values.name : "",
      mobile: values.mobile ? values.mobile : "",
    };
    const res = await updateProfile(data);
    if (res.success) {
      showAlert("SUCCESS", "Success", res.message);
    }
  };

  const handleTFA = () => {
    navigate("/opt_verify");
  };
  const { values, errors, handleChange, setValues } = useForm(
    handleLogin,
    validateUserLogin
  );
  const checkTFA = getTFA();
  const getData = () => {
    setValues((preValues) => ({
      ...preValues,
      email: data.email,
      name: data.name,
      mobile: data.mobile,
    }));
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading === false && (
        <form onSubmit={(e) => handleLogin(e)}>
          <div className="mb-2">
            {checkTFA == "false" ? (
              <>
                <label
                  htmlFor="usernameAndEmail"
                  className="form-label font-14 font-w-500 font-292930"
                >
                  Check 2FA
                </label>
                <Form>
                  <Form.Check type="switch" onChange={handleTFA} />
                </Form>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="usernameAndEmail"
              className="form-label font-14 font-w-500 font-292930"
            >
              Name
            </label>
            <div className="input-group">
              <span className="input-group-text input-icon">
                <img src={userIcon} />
              </span>
              <input
                type="text"
                className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3"
                name="name"
                value={values.name === undefined ? "" : values.name}
                onChange={handleChange}
              />
            </div>
            {errors.name && (
              <p className="text-danger">
                <small>{errors.name}</small>
              </p>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="usernameAndEmail"
              className="form-label font-14 font-w-500 font-292930"
            >
              Email
            </label>
            <div className="input-group">
              <span className="input-group-text input-icon">
                <img src={emailIcon} />
              </span>
              <input
                type="text"
                disabled
                className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3"
                name="email"
                value={values.email === undefined ? "" : values.email}
                onChange={handleChange}
              />
            </div>
            {errors.email && (
              <p className="text-danger">
                <small>{errors.email}</small>
              </p>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="usernameAndEmail"
              className="form-label font-14 font-w-500 font-292930"
            >
              Mobile
            </label>
            <div className="input-group">
              <span className="input-group-text input-icon">
                <img src={mobileIcon} />
              </span>
              <input
                type="text"
                className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3"
                name="mobile"
                value={values.mobile === undefined ? "" : values.mobile}
                onChange={handleChange}
              />
            </div>
            {errors.mobile && (
              <p className="text-danger">
                <small>{errors.mobile}</small>
              </p>
            )}
          </div>
          <button
            type="submit"
            className="btn sunset-yellow-bg px-lg-5 py-lg-3 py-sm-3 px-sm-4 border-radidus-8"
          >
            Update
          </button>
          <button
            type="submit"
            className="btn px-lg-5 py-lg-3 py-sm-3 px-sm-4 border-radidus-8"
          >
            <NavLink className="nav-link font-000000" to="/dashboard">
              Cancel
            </NavLink>
          </button>
        </form>
      )}
    </>
  );
};

export default LoginForm;
