import { getAccessToken, getUserId } from "../utils/token";
import { checkERC20Validation } from "../services/api/nft";
// service to validate user signup form
export const validateUserSignup = (values) => {
  const errors = {};

  // Validate Name
  if (typeof values.name !== "string" || values.name.trim() === "") {
    errors.name = "Name must not be empty";
  }

  // Validate User Name
  if (typeof values.username !== "string" || values.username.trim() === "") {
    errors.username = "User email must not be empty";
  }

  // Validate Mobile Number
  /*if (typeof values.mobile !== "string" || values.mobile.trim() === "") {
    errors.mobile = "Mobile Number must not be empty";
  }*/

  // Validate Password
  if (typeof values.password !== "string" || values.password.trim() === "") {
    errors.password = "Password must not be empty";
  }

  // Validate Confirm Password
  if (
    typeof values.confirmPassword !== "string" ||
    values.confirmPassword.trim() === ""
  ) {
    errors.confirmPassword = "Confirm Password must not be empty";
  }

  // Validate Password Match
  if (
    values.password &&
    values.confirmPassword &&
    values.password.trim() !== values.confirmPassword.trim()
  ) {
    errors.confirmPassword = "Password does not match";
  }

  return errors;
};
// service to validate user Update profile
export const validateUserUpdate = (values) => {
  const errors = {};
  // Validate Name
  if (typeof values.name !== "string" || values.name.trim() === "") {
    errors.name = "Name must not be empty";
  }
  // Validate Name
  if (typeof values.zip !== "string" || values.zip.trim() === "") {
    errors.zip = "Zip must not be empty";
  }

  // Validate Phone Number
  /*if (typeof values.phone !== "string" || values.phone.trim() === "") {
    errors.phone = "Phone Number must not be empty";
  }*/
  // Validate Mobile Number
  if (typeof values.mobile !== "string" || values.mobile.trim() === "") {
    errors.mobile = "Mobile Number must not be empty";
  }
  // Validate country
  if (typeof values.country !== "string" || values.country.trim() === "") {
    errors.country = "Country must not be empty";
  }
  // Validate state
  if (typeof values.state !== "string" || values.state.trim() === "") {
    errors.state = "State must not be empty";
  }
  // Validate Adderss
  if (typeof values.address !== "string" || values.address.trim() === "") {
    errors.address = "Address must not be empty";
  }

  return errors;
};
export const validateAddDFender = (values) => {
  const errors = {};
  // Validate Wallet Id
  // if (typeof values.walletId !== "string" || values.walletId.trim() === "") {
  //   errors.walletId = "Wallet Id must not be empty";
  // }
  // Validate Node Id
  if (typeof values.nodeId !== "string" || values.nodeId.trim() === "") {
    errors.nodeId = "Node Id must not be empty";
  }

  return errors;
};
// service to validate user login form
export const validateUserLogin = (values) => {
  const errors = {};

  // Validate User Name
  if (typeof values.login !== "string" || values.login.trim() === "") {
    errors.login = "User email must not be empty";
  }

  // Validate Password
  if (typeof values.password !== "string" || values.password.trim() === "") {
    errors.password = "Password must not be empty";
  }

  return errors;
};
// service to validate forget password form
export const validateForgetPassword = (values) => {
  const errors = {};

  // Validate User Name
  if (typeof values.username !== "string" || values.username.trim() === "") {
    errors.username = "User email must not be empty";
  }

  return errors;
};

// service to validate confirm password form
export const validateConfirmPassword = (values) => {
  const errors = {};

  // Validate User Name
  if (typeof values.username !== "string" || values.username.trim() === "") {
    errors.username = "User email must not be empty";
  }

  // Validate Confirmation Code
  if (
    typeof values.confirmationCode !== "string" ||
    values.confirmationCode.trim() === ""
  ) {
    errors.confirmationCode = "Confirmation code must not be empty";
  }

  return errors;
};
// service to validate confirm forget password form
export const validateConfirmForgetPassword = (values) => {
  const errors = {};

  // Validate User Name
  if (typeof values.username !== "string" || values.username.trim() === "") {
    errors.username = "User email must not be empty";
  }

  // Validate Confirmation Code
  if (
    typeof values.confirmationCode !== "string" ||
    values.confirmationCode.trim() === ""
  ) {
    errors.confirmationCode = "Confirmation code must not be empty";
  }

  // Validate Password
  if (typeof values.password !== "string" || values.password.trim() === "") {
    errors.password = "Password must not be empty";
  }

  return errors;
};

export const validateConfirmWallet = (values) => {
  const errors = {};
  // Validate Wallet1
  if (
    typeof values.wallet !== "string" ||
    values.wallet.trim() === "" ||
    values.wallet === "undefined" ||
    values.wallet === "false"
  ) {
    errors.wallet = "Wallet cannot not be empty";
  }
  // Validate Wallet2
  if (
    typeof values.confirmWallet !== "string" ||
    values.confirmWallet.trim() === ""
  ) {
    errors.confirmWallet = "Confirm Wallet cannot not be empty";
  }

  // Validate Confirmation Code
  if (
    values.wallet &&
    values.confirmWallet &&
    values.wallet.trim() !== values.confirmWallet.trim()
  ) {
    errors.confirmWallet = "Confirm Wallet does not match";
  }

  return errors;
};
export const validateNatureNFT = (values) => {
  const errors = {};
  // Validate Token
  const numToken = parseFloat(values.token, 10);
  if (typeof values.token !== "string" || values.token.trim() === "") {
    errors.token = "Token cannot not be empty";
  }
  // Validate CryptoToken
  if (
    typeof values.cryptoToken !== "string" ||
    values.cryptoToken.trim() === ""
  ) {
    errors.cryptoToken = "Crypto Token cannot not be empty";
  }
  if (values.token && numToken < values.minimumToken) {
    errors.token = `Token cannot be less then ${values.minimumToken}`;
  }
  return errors;
};
export const validateClubNFT = (values) => {
  const errors = {};
  // Validate Token
  console.log(values,'==================')
  const hasSpecialChar = /[^a-zA-Z0-9.\s]/.test(values.new_Token);
  if (typeof values.new_Token !== "string" || values.new_Token.trim() === "" || values.new_Token.trim() === "0") {
    errors.token = "THCNFT Quantity cannot not be empty";
  }
  if (hasSpecialChar ) {
    errors.token = `Special characters not allowed`;
  }
  const intValue = Number(values.new_Token);
  if (!isNaN(intValue) && ! Number.isInteger(intValue) ) {
    errors.token = `THCNFT Quantity should be integer`;
  }
  console.log(intValue,Number(values.totalbuyHCT),'intValue + values.totalbuyHCT')
  if ((intValue + Number(values.totalbuyHCT)) > Number(values.maxbuyHCT) || values.new_Token > values.maxHCT) {
    errors.token = `A maximum of one per person increasing to 2 after 2 weeks`;
  }
  // if ((intValue < 1 || intValue > 3) ) {
  //   errors.token = `HCT Quantity should be between 1 to 3`;
  // }
  // if (values.new_Token && ! (values.new_Token === '1' || values.new_Token === '2' || values.new_Token === '3')) {
  //   errors.token = `HCT Quantity shoubl be between 1 to 3`;
  // }
  // if (values.new_Token && values.new_Token > values.maxHCT) {
  //   errors.token = `only ${3-Number(values.totalbuyHCT) } Tickets are available for you`;
  // }
  return errors;
};
export const validateQRCode = (values) => {
  const errors = {};
  // Validate OTP
  if (typeof values.password !== "string" || values.password.trim() === "") {
    errors.password = "OTP cannot not be empty";
  }
  return errors;
};

export const checkERC20 = async (values) => {
  const data = {
    address: values,
  };
  const result = await checkERC20Validation(data);
  return result;
};
// service to validate Redemption order
export const validateRedemptionOrder = (values) => {
  const errors = {};
  const num = parseFloat(values.token_qty, 10);
  // Validate Name
  if (typeof values.wallet !== "string" || values.wallet.trim() === "") {
    errors.wallet = "Wallet must not be empty";
  }
  // Validate Name
  if (typeof values.token_qty !== "string" || values.token_qty.trim() === "") {
    errors.token_qty = "Token must not be empty";
  }
  // Validate Token name
  if (typeof values.crypto_token_id === "") {
    errors.crypto_token_id = "Token name must not be empty";
  }
  // Validate Vault
  if (typeof values.vault_id === "" || values.vault_id === "") {
    errors.vault_id = "Vault must not be empty";
  }
  // Validate minimum token
  if (values.minToken > num) {
    errors.cryptoToken = "Minimum Token is Required";
  }

  return errors;
};
