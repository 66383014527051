/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

const BenefitBox = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  return (
    <>
      {bannerSections &&
        <section className="benifir-box">
            <div className="container">
                <div className="flex grid-3">
                {bannerSections &&
                  bannerSections.banner_image.map((bannerImage, banner_key) => {
                  return (
                    <div className="col" key={banner_key}>
                        <div className="cube">
                            <div className="front">
                                <figure>
                                    <img src={PATH+bannerImage.image_url} alt="" />
                                </figure>
                                {ReactHtmlParser(bannerImage.heading)}
                            </div>
                            <div className="bottom">
                                {ReactHtmlParser(bannerImage.heading)}
                                <p>{ReactHtmlParser(bannerImage.text)}</p>
                            </div>
                        </div>
                    </div>
                    );
                })}
                </div>
            </div>
        </section>
      }
    </>
  );
};

export default BenefitBox;
