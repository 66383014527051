/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import workflow1 from "../../assets/images/workflow1.png";
import workflow3 from "../../assets/images/workflow3.png";
import workflow2 from "../../assets/images/workflow2.png";

const Workflow = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  var workflow = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    autoplay: true,
    infinite: true,
    dots: true,
    centerPadding: '0px',
  };
  
  return (
    <section className="workflow-section">
       <div className="container">
          <div className="title-head text-center">
             <h2>Workflow</h2>
             <h3>Crypto Dinar in Workflow</h3>
          </div>
          <Slider {...workflow} className="workflow-slider">
          {bannerSection &&
            bannerSection.banner_image.map((bannerImage, banner_key) => {
              return (
                <div key={banner_key}>
                  <div className="slide-item">
                     <figure><img src={PATH+bannerImage.image_url} alt="" /></figure>
                     {ReactHtmlParser(bannerImage.text)}
                  </div>
                </div>
                );
              })}
          </Slider>
       </div>
    </section>
  );
};

export default Workflow;
