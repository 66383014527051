import React from 'react';
import homeSection6Img from "../../assets/images/home-page-section6-img.svg";

const SupplierSection = () => {
    return (
        <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
                <img src={homeSection6Img} className="w-100" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                <h2 className="font-36 font-w-600 mb-4">Welcome to the colourful world of the only supplier of real, completely green energy </h2>
                <p className="font-20 font-w-400 font-919191 mb-4">When you sign up to Green Energy, you are choosing energy that has been sustainably generated from green and renewable sources.
                </p>
                <p className="font-20 font-w-400 font-919191 mb-5">No gimmicks, no carbon offsetting. Just 100% green gas and electricity for people who genuinely care about their impact on our environment.
                </p>
                <button className="btn sunset-yellow-bg font-20 font-w-500 font-272E35"> Discover More</button>
            </div>
        </div>
    );
};

export default SupplierSection;
