/* eslint-disable new-cap */
import React from "react";
import URLS from "../../configs/api-end-points";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import CountEntryCard from "./count-entry-card";
import ReactHtmlParser from "react-html-parser";
import ImageOnly from "./image-only";

function ImageLeftContentRightWidgetFull({ bannerSection, classes }) {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }
  return (
    <div className={classes}>
      <div className="card border-radidus-16">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {bannerSection && bannerSection.widget && (
                <div className="entry-card container-full p-md-5 p-3">
                  <CountEntryCard
                    widgets={bannerSection.widget}
                    titleColor="font-00A14B"
                  />
                </div>
              )}
              <img
                src={`${PATH}${bannerSection.image_url}`}
                className="w-100"
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5 ">
              <div>{ReactHtmlParser(bannerSection.banner_text)}</div>
              <br />
              <div className="row">
                {bannerSection && bannerSection.banner_image && (
                  <div>
                    <ImageOnly widgets={bannerSection.banner_image} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageLeftContentRightWidgetFull;
