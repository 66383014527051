import React from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/home.css";
import homeSection5Img from "../../assets/images/sunset-nature-home-section5.svg";
import bankIcon from "../../assets/icons/bank.svg";
import profitIcon from "../../assets/icons/profit-icon.svg";
import profitIcon1 from "../../assets/icons/profit-icon1.svg";
import currencyIcon from "../../assets/icons/currency.svg";
import checkbookIcon from "../../assets/icons/checkbook.svg";
import homePageNatureImage from "../../assets/images/home-page-nature-image.svg";
import CountEntryCard from '../../components/modals/count-entry-card';
import bannerImage1 from "../../assets/images/sunset-nature-home-banner1.png";
import bannerImage2 from "../../assets/images/sunset-nature-home-banner2.png";
import bannerImage from "../../assets/images/sunset-nature-home-banner.png";
import MarketAndFeatures from '../../components/shared/market-and-features';
import IconCardBg from '../../components/modals/icon-card-bg';
import NatureBanner from '../../components/shared/nature-banner';
import GlobalCommunity from '../../components/shared/global-community';
import NewsAndMedia from '../../components/shared/news-and-media';
import SupplierSection from '../../components/shared/supplier-section';
import Projects from '../../components/shared/projects';
import { NavLink } from 'react-router-dom';

const SunsetNatureHome = () => {
    return (
        <ThemeContainer type="public" module="nature">
            <NatureBanner
                bannerImage={bannerImage}
                image1={bannerImage1}
                image2={bannerImage2}
                bannerText="The Sunset Capital only supplier of 100% Green & Forest Land Tokens."
            />
            <div className="home-main">
                <div className="section-1 row sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5 p-xl-5">
                    <div className="col-12">
                        <div className="fact-and-figures m-auto ml-5">
                            <MarketAndFeatures />
                        </div>
                    </div>
                </div>

                <div className="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="card border-radidus-16">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="entry-card w-100 p-5">
                                        <CountEntryCard counts={{ count1Value: "1,100,000 M2", count1Text: "Natural Reserve", count2Value: "1,100,000", count2Text: "sustainable tokens", count3Value: "06", count3Text: "Listed on Exchanges" }} titleColor="#47DE00"/>
                                    </div>
                                    <img src={homePageNatureImage} className="w-100"/>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                                    <br /><br /><br /><br />
                                    <h3 className=" font-24 font-w-600 mb-4 font-34A853">About</h3>
                                    <h3 className=" font-36 font-w-500 mb-4">Sunset Capital</h3>
                                    <p className="font-18 font-w-400 font-656565 mb-4">The aim of this project is to implement an NFT marketplace for maintenance of the sustainable assets - Mangroves Natural Reserve. Sunset Digital owns 1,100,000.00 m2 of Mangroves Natural Reserve and wants to tokenize it with 1,100,000 Sunset sustainable tokens.</p>
                                    <p className="font-20 font-w-600 mb-4 font-bold">Project Specs:</p>
                                    <p className="font-18 font-w-400 font-656565 mb-4">Sunset Digital owns 1,100,000.00 m2 of Mangroves Natural Reserve;</p>
                                    <p className="font-18 font-w-400 font-656565 mb-4">1,100,000 Sunset sustainable tokens;</p>
                                    <p className="font-18 font-w-400 font-656565 mb-4">Listed on 6 exchanges;</p>
                                    <NavLink to="/project_details"><button className= "btn sunset-yellow-bg font-20 font-w-500 font-272E35">Discover More</button></NavLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="section-3 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="card border-radidus-16 p-lg-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <h3 className=" font-24 font-w-600 mb-4 font-34A853">Why own</h3>
                                <h1 className="font-42 font-w-400 mb-4">Sunset Forest Land Token?</h1>
                                <p className=" font-18 font-w-400 mb-4">Sunset Forest Land Tokens (otherwise known as the "SFLT") is a reference to the physical land in forest area where you can do your banding, & each SFLT constitutes 1 M2 of Green/ Forest Land.</p>

                                <div className="row mt-5">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-2 p-0">
                                        <IconCardBg isActive={true} icon={profitIcon} iconText="A stable asset"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={profitIcon1} iconText="Preserves wealth"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={bankIcon} iconText="Price maintains"/>
                                    </div>
                                    <div className="col-lg-2 p-0 border-right-2-C4C4C4">
                                        <IconCardBg isActive={false} icon={currencyIcon} iconText="Hedge against"/>
                                    </div>
                                    <div className="col-lg-2 p-0">
                                        <IconCardBg isActive={false} icon={checkbookIcon} iconText="Long track record"/>
                                    </div>
                                    <div className="col-lg-1"></div>
                                </div>

                                <div className="card border-radidus-8 mt-5 p-lg-5">
                                    <div className="card-body">
                                        <h2>A Stable Asset</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae. Praesent at bibendum tortor. Pellentesque at purus at est varius ullamcorper. Aliquam arcu purus, euismod non turpis vitae, venenatis tincidunt metus. Duis vulputate a risus non lobortis. Mauris posuere, nulla ac facilisis egestas, nulla diam pulvinar neque.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="section-4 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <Projects />
                </div>

                <div className="section-5 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5">
                    <div className="card border-radidus-16">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                                    <h3 className=" font-24 font-w-600 mb-4 font-34A853">Investors</h3>
                                    <h3 className="font-36 font-w-600 mb-4">Superpowers for Smart Investors</h3>
                                    <p className="font-18 font-w-400 font-656565 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae. Praesent at bibendum tortor. Pellentesque at purus at est varius ullamcorper. Aliquam arcu purus, euismod non turpis vitae, venenatis tincidunt metus. Investor Docs</p>
                                    <button className="btn sunset-yellow-bg font-20 font-w-500 font-272E35">Investor Docs</button>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6">
                                    <img src={homeSection5Img} className="w-100" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="section-6 row sunset-ffffff-bg">
                    <SupplierSection />
                </div>

                <div className="section-7 sunset-F2F2F2-bg">
                    <NewsAndMedia
                        title1 = "News and Media"
                        heading1 = "Sunset Capital in News & Media"
                        title2 = ""
                        heading2 = ""
                    />
                </div>

                <div className="section-8 sunset-ffffff-bg">
                    <GlobalCommunity
                        title1 = "Community"
                        heading1 = "A global community"
                    />
                </div>
            </div>

        </ThemeContainer>
    );
};

export default SunsetNatureHome;
