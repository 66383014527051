import React from "react";
import sliderImg from "../../assets/images/project-detail/mask_group.jpg";

const ProjectSlider = () => {
  return (
    <>
      <div
        className="col-md-1"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <a
          className="left carousel-control-prev"
          href=".carousel"
          role="button"
          data-slide="prev"
          style={{ marginBottom: "420px", marginLeft: "38px" }}
        >
          <i
            className="fa fa-angle-up"
            style={{ color: "black", fontSize: "25px" }}
          ></i>
        </a>
        <div
          id="carousel-pager"
          className="carousel slide "
          data-ride="false"
          data-interval="500000000"
        >
          <div
            className="carousel-inner vertical"
            style={{ marginTop: "26px" }}
          >
            <div className="carousel-item active">
              <img
                src={sliderImg}
                id="carousel-selector-0"
                className="img-fluid img-fluid1"
                data-target="#carousel-main"
                data-slide-to="0"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                id="carousel-selector-1"
                className="img-fluid img-fluid1"
                data-target="#carousel-main"
                data-slide-to="1"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                id="carousel-selector-2"
                className="img-fluid img-fluid1"
                data-target="#carousel-main"
                data-slide-to="2"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                id="carousel-selector-3"
                className="img-fluid img-fluid1"
                data-target="#carousel-main"
                data-slide-to="3"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                id="carousel-selector-4"
                className="img-fluid img-fluid1"
                data-target="#carousel-main"
                data-slide-to="4"
              />
            </div>
          </div>
        </div>
        <a
          className="right carousel-control-next"
          href=".carousel"
          role="button"
          data-slide="next"
          style={{ marginTop: "432px", marginRight: "44px" }}
        >
          <i
            className="fa fa-angle-down"
            style={{ color: "black", fontSize: "25px" }}
          ></i>
        </a>
      </div>

      <div className="col-md-6">
        <div
          id="carousel-main"
          className="carousel slide "
          data-ride="false"
          data-interval="5000"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={sliderImg}
                className="img-fluid img-fluid2"
                data-slide-number="0"
              />
            </div>

            <div className="carousel-item" style={{ border: "2px solid red" }}>
              <img
                src={sliderImg}
                className="img-fluid img-fluid2"
                data-slide-number="1"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                className="img-fluid img-fluid2"
                data-slide-number="2"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                className="img-fluid img-fluid2"
                data-slide-number="3"
              />
            </div>
            <div className="carousel-item">
              <img
                src={sliderImg}
                className="img-fluid img-fluid2"
                data-slide-number="4"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSlider;
