import React, { useRef, useState, useEffect } from "react";
import { getRedemptionMaster } from "../../core/services/api/nft";
import RedemptionMessage from "./redemption-message";
import RedemptionForms from "./redemption-form";
const RedemptiontabForms = () => {
  const [message, setMessage] = useState();

  const getRedemptionData = async () => {
    const res = await getRedemptionMaster();
    if (res.success) {
      if(res.crypto_assets && res.crypto_assets.length === 1){
        setMessage(res.crypto_assets[0].text_message)
      }
    }
  };
  useEffect(() => {
    getRedemptionData();
  }, []);
  
  return (
    <>
    <div className="col-lg-6  p-0">
      <div className="dashboard-form-inner ">
        <button
          className="nav-link w-100 db-form-tab"
          id="crypto-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#crypto-home"
          type="button"
          role="tab"
          aria-controls="crypto-home"
          aria-selected="true"
        >
          Enter Quantity
        </button>
        <RedemptionForms />
      </div>
    </div>
    <div className="col-lg-6  px-5 d-flex align-items-center justify-content-center order-md-last">            
      <div className="dashboard-form-inner ">
        <RedemptionMessage message={message} />
      </div>
    </div>
    </>
  );
};

export default RedemptiontabForms;
