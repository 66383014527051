/* eslint-disable nonblock-statement-body-position */
import React, { useState } from "react";
import { validateForgetPassword } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { sSOForgetPassword } from "../../../core/services/api/user";
import ConfirmForgetPasswordForm from "./confirm-forget-password-form";
import { useDispatch, useSelector } from "react-redux";
import EmailConfirmIcon from "../../../assets/images/forgotPasswordIcon.svg";
import {
  setUserName,
  setSuccessMessage,
  setErrorMessage,
} from "../../../redux/actions";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import { useNavigate } from "react-router-dom";
import useAlert from "./../../../hooks/use-alert";
import BackIcon from "../../../assets/images/icon-back.png";
import EmailIcon from "../../../assets/images/icon-email.png";

const ForgetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { message, messageType } = useSelector((state) => state.messageReducer);

  const [showMessage, setShowMessage] = useState(false);
  const [verifyConfirmationCode, setVerifyConfirmationCode] = useState(false);

  const handleForgetPasswordSubmit = async () => {
    const result = await sSOForgetPassword(values);
    if (result && result.status) {
      dispatch(setUserName(values));
      dispatch(
        setSuccessMessage("Verification code is sent to your email address.")
      );
      showAlert("SUCCESS", "Success", result.message);
      //setVerifyConfirmationCode(true);
      navigate("/reset-password");
    } else if (result && !result.status) {
      dispatch(setErrorMessage(result.message));
      showAlert("ERROR", "ERROR", result.message);
      setShowMessage(true);
      setShowMessage(true);
    }
  };
  const redirect = () => {
    navigate("/login");
  }
  const { values, errors, handleSubmit, handleChange } = useForm(
    handleForgetPasswordSubmit,
    validateForgetPassword
  );

  if (verifyConfirmationCode)
    return (
      <ConfirmForgetPasswordForm
        setVerifyConfirmationCode={setVerifyConfirmationCode}
      />
    );
  return (
    <>
      <div className="column-text dcomm-login-form-layer">
        <div className="text-left pb-40">
            <button type="button" className="btn-icon" onClick={redirect}><img src={BackIcon} title="Back" className="back-icon" /></button>
        </div>
        <form onSubmit={handleSubmit}>
          {/*showMessage && message && (
            <p
              className={
                messageType === MESSAGE_VARIANT.ERROR
                  ? "text-danger"
                  : "text-success"
              }
            >
              <small>{message}</small>
            </p>
          )*/}
          <div className="text-center">
              <h3 className="crypto-dinar-white font-gil-bold">Forgot Password</h3>
              <div className="icon-box">
                  {/*<span className="icon all-center">
                      <img src={EmailIcon} title="" alt="" />
                  </span>*/}
                  <p className="text-white">Please enter your email address to Receive a Verification Code</p>
              </div>
          </div>
          <div className="log-box">
              <div className="form-group">
                  <input
                    type="email"
                    autoFocus
                    className="form-control"
                    name="username"
                    value={values.username === undefined ? "" : values.username}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  {errors.username && (
                    <p className="text-danger">
                      <small>{errors.username}</small>
                    </p>
                  )}
              </div>
              <div className="form-group pt-10">
                  <button type="submit" className="buy-coin-btn w-100">Send</button>
              </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgetPasswordForm;
