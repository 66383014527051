/* eslint-disable new-cap */
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useLocation } from 'react-router';
import URLS from '../../configs/api-end-points';
import { PROJECT_TYPES } from '../../utils/constants/project-types';

const FactsAndFigures = ({ factsAndFiguresTitle, factsAndFigures }) => {
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <div className="card border-radidus-16">
            <div className="card-body">
                { ReactHtmlParser(factsAndFiguresTitle) }
                <br />
                <div className="row">
                                {
                                    factsAndFigures && factsAndFigures.map((factsAndFigure, index) => {
                                        return <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={index}>
                                            <h2>{factsAndFigure.name}</h2>
                                            {
                                                factsAndFigure.image_url && <img src={`${PATH}${factsAndFigure.image_url}`} />
                                            }
                                            {
                                                factsAndFigure.value1 && <p className="font-12 font-C5997C">{factsAndFigure.value1}</p>
                                            }
                                        </div>;
                                    })
                                }

                </div>

            </div>
        </div>
    );
};

export default FactsAndFigures;
