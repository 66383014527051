/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Structure from "../../assets/images/59.png";

const FeeStructure = ({ bannerSection }) => {
  let headings = [...new Set(bannerSection.widget.map(item => item.head))];
  return (
    <section className="structure-section">
       <div className="container-sm">
          <div className="title-head text-center">
             {ReactHtmlParser(bannerSection.title)}
          </div>
          <div className="flex">
             <div className="col-left">
                <h3>Operations</h3>
                <ul className="structure-tab">
                  {headings &&
                    headings.map((heading, list_key) => {
                    return (
                      <li key={list_key} className={list_key === 1 ? "active" : ""} data-id={heading.replace(/\W/g, '-').toLowerCase()}><Link to="#" onClick={e => e.preventDefault()}>{heading}</Link></li>
                      );
                  })}
                </ul>
             </div>
             <div className="col-right">
                <h3>Fee*</h3>
                <figure className="mobile-img"> <img src={Structure} alt="" /></figure>
                <div className="structure-wrapper">
                  {headings &&
                    headings.map((heading, list_key1) => {
                      return (
                         <div key={list_key1} className="structure-text" id={heading.replace(/\W/g, '-').toLowerCase()}>
                            <ul>
                              {bannerSection.widget.map((banner, index_key) => {
                                return (
                                  <>
                                    {heading === banner.head &&
                                      <li key={index_key}>{ReactHtmlParser(banner.value)}</li>
                                    }
                                  </>
                                );
                              })}
                            </ul>
                         </div>
                      );
                    })}
                </div>
             </div>
          </div>
       </div>
    </section>
  );
};

export default FeeStructure;
