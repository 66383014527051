import React from "react";

const ProjectFiatForm = () => {
  return (
    <>
      <div
        className="tab-pane py-4 px-4 fade"
        id="fiat-profile"
        role="tabpanel"
        aria-labelledby="fiat-profile-tab"
      >
        <form className="row g-3 my-3">
          <div className="col-12">
            <label for="inputAddress" className="form-label label-gr">
              Your Wallet Address
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control bg-gray"
                id="inputAddress"
                placeholder="1234 Main St"
              />
              <div className="input-group-text bg-gr">
                <i className="fa fa-clone" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <label for="inputEmail4" className="form-label label-gr">
              Get
            </label>
            <div className="input-group">
              <input
                type="email"
                className="form-control bg-gray"
                id="inputEmail4"
              />
            </div>
            <label for="inputEmail4" className="form-label label-green">
              USD price: $0
            </label>
          </div>
          <div className="col-md-4">
            <label for="inputPassword4" className="form-label label-gr">
              Token
            </label>
            <div className="input-group">
              <input
                type="password"
                className="form-control bg-gray"
                id="inputPassword4"
              />
              <div className="input-group-text bg-gr">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <label for="inputEmail4" className="form-label label-gr">
              Pay
            </label>
            <div className="input-group">
              <input
                type="email"
                className="form-control bg-gray"
                id="inputEmail4"
              />
              <div className="input-group-text bg-gr">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
            </div>
            <label for="inputEmail4" className="form-label label-red">
              Minimum Sunset Energy NFT to purchase is 1.63672
            </label>
          </div>
          <div className="col-md-4">
            <label for="inputPassword4" className="form-label label-gr">
              Currency
            </label>
            <div className="input-group">
              <input
                type="password"
                className="form-control bg-gray"
                id="inputPassword4"
              />
              <div className="input-group-text bg-gr">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center">
            <div className="yellow-warning">
              <div className="row p-2 my-2">
                <div className="col-sm-1 col-xs-1 p-2 d-flex align-items-start justify-content-end">
                  <i
                    className="fa fa-info-circle warning-text"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="col-sm-11 col-xs-11 pe-2 ps-2">
                  <p className="m-0 warning-text">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Illo deserunt sunt voluptatibus ab minus, eos non fugit
                    itaque quas eum nisi magnam id! Rem, molestiae nam dolore
                    delectus deserunt libero!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-grid">
            <button
              type="submit"
              style={{ width: "100%" }}
              className="btn btn-primary green-btn"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectFiatForm;
