import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/home.css";
import FactsAndFigures from '../../components/shared/facts-and-figures';
import Banner from "../../components/shared/banner";
import { getPageTypeData } from '../../core/services/api/home';
import { BANNER_TYPES } from '../../utils/constants/banner-types';
import ImageLeftContentRightWidgetCard from '../../components/modals/image-left-content-right-widget-card';
import ImageLeftContentRightBannerCard from '../../components/modals/image-left-content-right-banner-card';
import ImageRightContentLeftBannerCard from '../../components/modals/image-right-content-left-banner-card';
import ImageRightContentLeftWidgetCard from '../../components/modals/image-right-content-left-widget-card';
import Image123BannerCard from '../../components/modals/image-123-banner-card';
import { useParams } from 'react-router-dom';
import PromotionBanner from '../../components/modals/promotion-banner';
import { useDispatch, useSelector } from 'react-redux';
import Investors from '../../components/shared/investors';
import { setHeaderListData } from '../../redux/actions/page-action';
import ImageLeftContentRightWidgetContentImage from '../../components/modals/image-left-content-right-widget-content-image';
import ImageRightContentLeftWidgetContentImage from '../../components/modals/image-right-content-left-widget-content-image';
import Image22 from '../../components/shared/image_2_2';
import { activateNewsletterSubscribe } from '../../core/services/api/cms';

const NewsletterSubscribeHome = () => {
    const dispatch = useDispatch();
    const { pageType } = useParams();
    const { headerList } = useSelector((state) => state.pageReducer);
    const [banners, setBanners] = useState([]);
    const [factsAndFiguresTitle, setFactsAndFiguresTitle] = useState("");
    const [factsAndFigures, setFactsAndFigures] = useState([]);
    const [bannerSections, setBannerSections] = useState([]);
    const [promotionBody, setPromotionBody] = useState("");
    const [promotionContent, setPromotionContent] = useState([]);
    const [investors, setInvestors] = useState([]);

    const fetchPageData = async (link = "/api/v1/homepage/corporate", pageType = "corporate") => {
        const result = await getPageTypeData(pageType, link);
        if (result) {
            result.banner_ids && setBanners(result.banner_ids);
            result.count_title && setFactsAndFiguresTitle(result.count_title);
            result.homepage_count_widget && setFactsAndFigures(result.homepage_count_widget);
            result.banner_section && setBannerSections(result.banner_section);
            result.promotion_body && setPromotionBody(result.promotion_body);
            result.homepage_promition_line && setPromotionContent(result.homepage_promition_line);
            result.cms_page_promition_line && setInvestors(result.cms_page_promition_line);
            result.banner_content && result.banner_image && setBanners([{ banner_text: result.banner_content, image_url: result.banner_image }]);
        } else {
            console.log(result);
        }
    };

    const handleActivateNewsletterSubscribe = async (values) => {
        const result = await activateNewsletterSubscribe(values);
    };

    useEffect(() => {
        fetchPageData();

        if (window.location.href.includes("#")) {
            let accessURL = window.location.href;
            accessURL = accessURL.replace("#", "?");
            const url = new URL(accessURL);

            const email = url.searchParams.get("email");
            const secureCode = url.searchParams.get("secure_code");

            const values = {
                email,
                secureCode
            };
            handleActivateNewsletterSubscribe(values);
        }
    }, []);

    return (
        <ThemeContainer type="public" pageTitle="Newletter page">
            <Banner
                banners={banners}
            />
            {investors && investors.length > 0 && <div className="mt-lg-4">
                <Investors investors={investors} />
            </div>
            }
            <div className="home-main">
                {factsAndFigures && factsAndFiguresTitle && <div className="section-1 row sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5 p-xl-5">
                    <div className="col-12">
                        <div className="fact-and-figures ml-5">
                            <FactsAndFigures factsAndFiguresTitle={factsAndFiguresTitle} factsAndFigures={factsAndFigures} />
                        </div>
                    </div>
                </div>
                }
                {promotionContent && promotionContent.length > 0 && <PromotionBanner promotionBody={promotionBody} promotionContent={promotionContent} />}

                {
                    bannerSections && bannerSections.map((bannerSection, index) => {
                        if (bannerSection.banner_type === BANNER_TYPES.IL_CR_WIDGET_CONTENT_IMAGE) return <ImageLeftContentRightWidgetContentImage bannerSection={bannerSection} classes="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IR_CL_WIDGET_CONTENT_IMAGE) return <ImageRightContentLeftWidgetContentImage bannerSection={bannerSection} classes="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IMAGE_2_2) return <Image22 bannerSection={bannerSection} classes="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IL_CR_WIDGET) return <ImageLeftContentRightWidgetCard bannerSection={bannerSection} classes="section-2 sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IR_CL_WIDGET) return <ImageRightContentLeftWidgetCard bannerSection={bannerSection} classes="section-5 row sunset-F2F2F2-bg" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IL_CR) return <ImageLeftContentRightBannerCard bannerSection={bannerSection} classes="section-6 row sunset-ffffff-bg" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IR_CL) return <ImageRightContentLeftBannerCard bannerSection={bannerSection} classes="section-5 row sunset-F2F2F2-bg" key={index} />;
                        if (bannerSection.banner_type === BANNER_TYPES.IMAGE_1_2_3) return <Image123BannerCard bannerSection={bannerSection} classes="section-7 sunset-F2F2F2-bg" key={index} />;
                    })
                }

                {/* <div className="section-6 row sunset-ffffff-bg">
                    <SupplierSection />
                </div>

                <div className="section-7 sunset-F2F2F2-bg">
                    <NewsAndMedia
                        title1 = ""
                        heading1 = ""
                        title2 = "News and Media"
                        heading2 = "Sunset Capital in News & Media"
                    />
                </div> */}

                {/* <div className="section-8 sunset-ffffff-bg">
                    <GlobalCommunity
                        title1 = ""
                        heading1 = "A global community"
                    />
                </div> */}
            </div>

        </ThemeContainer>
    );
};

export default NewsletterSubscribeHome;
