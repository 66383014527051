import React, { useEffect } from "react";
import "../../assets/css/header.css";
import KYCDocuments from "./kyc-document-list";
import { useSelector, useDispatch } from "react-redux";
import { setStartKYC } from "../../redux/actions";
import CryptoCoin from "../../assets/images/crypro-dinar-coin.svg";
import { KYC_STATUS } from "../../utils/constants/kyc-types";
import { getUsetKYCStatus } from "../../core/services/api/kyc";
import { checkUserKYCStatus } from "../../redux/actions/kyc-action";
import useAlert from "../../hooks/use-alert";
const KYCVerificationForm = () => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const { isUserKYCDone, userKYCStatus, rejectedType } = useSelector(
    (state) => state.kycReducer
  );

  const localUserId = localStorage.getItem("user_id");
  const localUserEmail = localStorage.getItem("user_email");
  const startKYC = () => {
    dispatch(setStartKYC(true));
  };
  const loadBlockpassWidget = () => {
    const blockpass = new window.BlockpassKYCConnect(
      process.env.REACT_APP_CLIENT_ID,
      {
        refId: localUserId
        // mainColor: "0a58ca",
        // email: localUserEmail,
      }
    );

    blockpass.startKYCConnect();

    // blockpass.on("KYCConnectSuccess", () => {
    //   alert("Documents submitted for KYC");
    //   dispatch(setStartKYC(false));
    // });
  };
  const handleCheckKYC = async () => {
    const resp = await getUsetKYCStatus();
    if (resp.status) {
      if (resp.kyc_status == "waiting" || resp.kyc_status == "inreview") {
        showAlert(
          "SUCCESS",
          "Success",
          "Your KYC Document is being process. Kindly waiting for few hours"
        );
      } else if (resp.kyc_status == "approved") {
        showAlert("SUCCESS", "Success", "Your KYC Completed");
      } else if (resp.kyc_status == "rejected") {
        showAlert(
          "SUCCESS",
          "Success",
          "Your KYC Documents are Rejected. Please Resubmit them"
        );
      } else {
        showAlert("SUCCESS", "Success", "Please Start Your KYC Processs");
      }
    }
    dispatch(checkUserKYCStatus(getUsetKYCStatus));
  };
  return (
    <>
      <div className="kyc-form py-4 px-4">
        {/*<div className="row text-center">
          <img src={CryptoCoin} className="kyc-img" />
        </div>*/}
        <div className="row text-center justify-content-center mt-4">
          {isUserKYCDone && userKYCStatus == KYC_STATUS.APPROVE ? (
            ""
          ) : userKYCStatus === KYC_STATUS.WAITING ||
            userKYCStatus === KYC_STATUS.IN_REVIEW ||
            userKYCStatus === KYC_STATUS.PENDING ? (
            <buton
              className="border-0 px-4 py-1 kyc-button cursor"
              onClick={handleCheckKYC}>
              Check Your KYC Status
            </buton>
          ) : (
            <button
              className="border-0 px-4 py-1 kyc-button"
              id="blockpass-kyc-connect"
              // onClick={startKYC}>
              onClick={loadBlockpassWidget}>
              {/*<i className="fal fa-user-times"></i> &nbsp;*/}
              {userKYCStatus && userKYCStatus === KYC_STATUS.REJECTED
                ? "KYC Again"
                : "Start KYC"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default KYCVerificationForm;
