import { combineReducers } from "redux";
import userReducer from "./user-reducer";
import messageReducer from "./message-reducer";
import nftReducer from "./nft-reducer";
import kycReducer from "./kyc-reducer";
import paginationReducer from "./pagination-reducer";
import alertReducer from "./alert-reducer";
import pageReducer from "./page-reducer";
const rootReducer = combineReducers({
  userReducer,
  alertReducer,
  messageReducer,
  nftReducer,
  kycReducer,
  paginationReducer,
  pageReducer,
});

export default (state, action) =>
  rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action);
