import React from 'react';
import bankIcon from "../../assets/icons/bank.svg";
import profitIcon from "../../assets/icons/profit-icon.svg";
import profitIcon1 from "../../assets/icons/profit-icon1.svg";
import currencyIcon from "../../assets/icons/currency.svg";
import checkbookIcon from "../../assets/icons/checkbook.svg";
import IconCard from '../modals/icon-card';

const NftToken = ({ title, titleColor }) => {
    return (
        <div className="nft-token-main">
            <h5 className="font-w-600 font-24 mb-0 mb-sm-4 mb-md-4 mb-lg-4" style={{ color: titleColor }}>{title}</h5>
            <h1 className="font-w-500 font-36 mb-0 mb-sm-4 mb-md-4 mb-lg-4">NFT Token</h1>
            <p className="font-16 font-w-400 font-656565 mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie, nulla ut pretium egestas, erat nisl sodales odio, vitae consectetur urna enim et nulla. Maecenas.
            </p>
            <div className="row">
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={true} icon={bankIcon} iconText="A stable asset" /></div>
                <div className="col-1"></div>
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={false} icon={bankIcon} iconText="Preserves wealth" /></div>
                <div className="col-1"></div>
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={false} icon={profitIcon} iconText="Price maintains" /></div>
                <div className="col-1 d-sm-none d-md-block"></div>
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={false} icon={profitIcon1} iconText="Hedge against" /></div>
                <div className="col-1"></div>
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={false} icon={currencyIcon} iconText="Long track record" /></div>
                <div className="col-1"></div>
                <div className="col-5 col-sm-3 col-md-5 col-lg-3"><IconCard isActive={false} icon={checkbookIcon} iconText="ABCDEF" /></div>
            </div>
        </div>
    );
};

export default NftToken;
