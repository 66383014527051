import React, { useState, useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import { getUserName } from "../../core/utils/token";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTokenDetail } from "../../core/services/api/nft";
import TrancheTableData from "../../components/list-data/tranche-table-data";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.png";
import useAlert from "./../../hooks/use-alert";

const TokenHoldings = () => {
  const handleCopy = (address) => {
    showAlert("SUCCESS", "Success", "Copied Successfully");
    copyText(address);
  };
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [purchase, setPurchase] = useState(0);
  const [credited, setCredited] = useState(0);
  const [pending, setPending] = useState(0);
  const [walletAddress, setwalletAddress] = useState('');
  const [allTokenDetails, setAllTokenDetails] = useState([]);

  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/login");
  }

  const getTokensHoldingsData = async () => {
    const res = await getTokenDetail();
    if (res.status) {
      setIsLoading(false);

      let totalTokensPurchased = 0.0;
      let totalCredit = 0.0;
      let totalPending = 0.0;
      let tokenDetails = [];
      let userwalletAddress = ''
      // Iterate through vesting_details
      res.vesting_details.forEach((vestingDetail) => {
        totalTokensPurchased += vestingDetail.total_no_of_tokens_purchased;
        totalCredit += vestingDetail.total_credit;
        totalPending += vestingDetail.total_pending;
        userwalletAddress = vestingDetail.wallet_address
        tokenDetails = tokenDetails.concat(vestingDetail.token_details);
      });
      tokenDetails.sort((a, b) => new Date(a.tranche_schedule_date) - new Date(b.tranche_schedule_date));
      setAllTokenDetails(tokenDetails);
      setPurchase(totalTokensPurchased);
      setCredited(totalCredit);
      setPending(totalPending);
      setwalletAddress(userwalletAddress)
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getTokensHoldingsData();
  }, []);
  const formattedWalletAddress =
  walletAddress &&
  `${walletAddress.substring(0, 4)}...${walletAddress.substring(walletAddress.length - 3)}`;
  return (
    <ThemeContainer type="private" module="nature" pageTitle="KYC Verification">
      <div className="dcomm-bg-layer min-vh-80">
        <div className="row dashboard-tiles m-0 px-1 py-1 px-md-5 py-md-5 mt-md-5 kyc-verification">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 col-sm-12">
            <label className="crypto-dinar-white mt-5 justify-center mb-3 ">
              <h3>Total Holdings</h3>
            </label>
            <div className="w-100 kyc">
            <div className="row my-4">
              <div className="col-md-5 justify-center crypto-dinar-white">
                Wallet Address
              </div>
              {walletAddress && (
                <div className="col-md-6 justify-center center crypto-dinar-white">
                  <div className="wallet-address">
                    <span style={{ display: 'inline-block' }}>{formattedWalletAddress}</span>
                    <div
                      className="input-group-text bg-gr cursor-pointer copy-icon"
                      style={{ display: 'inline-block', marginLeft: '5px' }}
                      onClick={() => handleCopy(walletAddress)}
                    >
                       <img src={CopyIcon} />
                    </div>
                  </div>
                </div>
              )}
            </div>
              <div className="row my-4">
                <div className="col-md-6 justify-center crypto-dinar-white">Total Token Purchase</div>
                <div className="col-md-6 justify-center center crypto-dinar-white">
                  {purchase || 0}
                </div>
              </div>
              <div className="row my-4">
                <div className="col-md-6 justify-center crypto-dinar-white">Total Token Credited</div>
                <div className="col-md-6 justify-center center crypto-dinar-white">
                  {credited || 0}
                </div>
              </div>
              <div className="row my-4">
                <div className="col-md-6 justify-center crypto-dinar-white">Total Token Pending</div>
                <div className="col-md-6 justify-center center crypto-dinar-white">
                  {pending || 0}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>

        <div className="order-detail">
          <TrancheTableData allTokenDetails={allTokenDetails}/>
        </div>

      </div>
    </ThemeContainer>
  );
};

export default TokenHoldings;
