import React from "react";
import DashboardBanner from "./dashboard-banner";
import DasboardHeading from "./dashboard-heading";
import EnergyDasboardSummary from "./energynft-dashboard-summary";
import NFTFormTab from "./nft-form-tab";
import NFTForms from "./nft-form-tab-content";
import NFTMessage from "./message-share";

const EnergyNFTTabDashboard = ({ naturenft = "yes", page }) => {
  return (
    <div
      className={
        naturenft === "no"
          ? "tab-pane fade show active new-radius pb-4"
          : "tab-pane fade new-radius pb-4"
      }
      id="energynft"
      role="tabpanel"
      aria-labelledby="energynft-tab"
    >
      <DashboardBanner type="energy" />
      <DasboardHeading tabSwitcher="no" page={"energy"} />
      <EnergyDasboardSummary />
      <div className="dashboard-form my-5 mx-1 mx-md-5">
        <div className="row mx-0">
          <div className="col-lg-6 p-0">
            <div className="dashboard-form-inner">
              <NFTFormTab page={page} />
              <NFTForms page={"energy"} />
            </div>
          </div>
          <div className="col-lg-6 p-5 d-flex align-items-center justify-content-center order-first order-md-last">
            {/* <h2 className="formt m-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed
              erat velit. Duis facilisis, tortor id pharetra maximus, diam justo
              suscipit lorem, eget molestie tellus arcu ac tellus. Aenean
              convallis commodo eros, at egestas.
            </h2> */}
            <div className="dashboard-form-inner ">
              <NFTMessage page="energy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyNFTTabDashboard;
