/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

import own1 from "../../assets/images/own1.png";
import own2 from "../../assets/images/own2.png";
import own3 from "../../assets/images/own3.png";
import own4 from "../../assets/images/own4.png";
import own5 from "../../assets/images/own5.png";
import own6 from "../../assets/images/own6.png";
import BigCoin from "../../assets/images/BigCoin.png";

const WhyUs = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  const columns1 = [];
  const columns2 = [];
  let column_data;
  if (bannerSection && bannerSection.banner_image.length > 0) {
    const images = bannerSection.banner_image;
    const data_keys = Object.keys(images);
    data_keys.map((key, i) => {
      if (images[key]["image_type"] == 1 || images[key]["image_type"] == 2 || images[key]["image_type"] == 3) {
        (column_data = {
          heading: images[key]["heading"],
          image_url: images[key]["image_url"],
          text: images[key]["text"],
        }),
          columns1.push(column_data);
      }
      if (images[key]["image_type"] == 4 || images[key]["image_type"] == 5 || images[key]["image_type"] == 6) {
        (column_data = {
          heading: images[key]["heading"],
          image_url: images[key]["image_url"],
          text: images[key]["text"],
        }),
          columns2.push(column_data);
      }
    });
  }

  return (
    <section className="whyown-section">
         <div className="container">
            <div className="flex">
               <div className="col-left">
                  <ul>
                    <li>
                      {columns1 &&
                      columns1.length > 0 &&
                      columns1.map((column1, col1_key) => (                        
                          <div className="box" key={col1_key}>
                            <figure><img src={`${PATH}${column1.image_url}`} alt="" className="" /></figure>
                            {ReactHtmlParser(column1.heading)}   
                            {ReactHtmlParser(column1.text)}   
                          </div>
                      ))}
                    </li>
                    <li>
                      {columns2 &&
                      columns2.length > 0 &&
                      columns2.map((column2, col2_key) => (                        
                        <div className="box"  key={col2_key}>
                          <figure><img src={`${PATH}${column2.image_url}`} alt="" className="" /></figure>
                          {ReactHtmlParser(column2.heading)}   
                          {ReactHtmlParser(column2.text)}   
                        </div>
                      ))}
                    </li>
                  </ul>
                  <a href="#" className="mobile-view">Get Start Now !</a>                
               </div>
               <div className="col-right">
                  {ReactHtmlParser(bannerSection.title)}                    
                  <figure><img src={BigCoin} alt="" /></figure>
               </div>
            </div>
         </div>
      </section>
  );
};

export default WhyUs;
