import React, { useState, useEffect } from "react";
import DashboardBanner from "./dashboard-banner";
import DasboardHeading from "./dashboard-heading";
import EnergyDasboardSummary from "./energynft-dashboard-summary";
import EnergyNFTGraph from "./energy-nft-graph";
import EnergyNFTTable from "./energy-nft-table";
import DasboardOrderHistory from "./dashboard-order-history";
import { useDispatch } from "react-redux";
import { dashboardAPI } from "../../core/services/api/nft";

const EnergyTabDashboard = ({ naturenft = "yes", page }) => {
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState();
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    const res = await dashboardAPI();
    if (res.status) {
      setDashboard(res);
      // dispatch(setDashboardData(res.dashboard_data));
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading == false && (
        <>
          <div
            className={
              naturenft === "no"
                ? "tab-pane fade show active new-radius pb-4"
                : "tab-pane fade new-radius pb-4"
            }
            id="energynft"
            role="tabpanel"
            aria-labelledby="energynft-tab"
          >
            <DashboardBanner type="energy" />
            <DasboardHeading page={page} tabSwitcher="no" />
            <EnergyDasboardSummary />
            <div className="dashboard-graph my-5 mx-1 mx-md-5">
              <div className="tab-content" id="pills-tabContent">
                <EnergyNFTGraph />
                {/* <EnergyNFTTable /> */}
              </div>
            </div>
            <div className="my-5">
              <DasboardOrderHistory data={dashboard.dashboard_data} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EnergyTabDashboard;
