import React from "react";
import RedemptionOrderTable from "./redemption-order-table";
import CryptoHeader from "./crypto-header";

const RedemptionOrders = () => {
  return (
      <div className="order-detail">
        <CryptoHeader
            header="Order Details"
            subHeading="An overview of cryptocurrencies and markets"
          />
          <RedemptionOrderTable />
      </div>
  );
};

export default RedemptionOrders;
