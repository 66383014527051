import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/benefit.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getPageTypeData } from "../../core/services/api/home";
import { BANNER_TYPES } from "../../utils/constants/banner-types";
import BenefitBox from "../../components/shared/benefits/BenefitBox";
import TechnologyUsed from "../../components/shared/benefits/TechnologyUsed";
import WhyUs from "../../components/shared/about/WhyUs";
import Banner from "../../components/shared/benefits/banner";
import { Spinner } from "react-bootstrap";

const Benefits = () => {
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const { headerList } = useSelector((state) => state.pageReducer);
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPageData = async (
    link = "/api/v1/homepage/benefits",
    pageType = "benefits"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      result.banner_section && setBannerSections(result.banner_section);
      setIsLoading(false);
    } else {
      console.log(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
    setIsLoading(true);
    setBanners([]);
    setBannerSections([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  useEffect(() => {    
    navigate("/login");
  }, []);
  
    return (
        <ThemeContainer type="public" pageTitle="Benefits & Technologies">
        {!isLoading ? (
            <>
            <Banner banners={banners} />
            {bannerSections &&
              bannerSections.length > 0 &&
              bannerSections.map((bannerSection, index) => {
                  if (bannerSection.banner_for === "crypto-dinar-benefits")
                  return (
                    <BenefitBox
                      bannerSections={bannerSection}
                      key={index}
                    />
                  ); 
                  if (bannerSection.banner_type === "widget")
                  return (
                    <TechnologyUsed
                      bannerSections={bannerSection}
                      key={index}
                    />
                  );
                  if (bannerSection.banner_for === "smart-contracts-used")
                    return (
                      <WhyUs
                        bannerSections={bannerSection}
                        key={index}
                      />
                    ); 
            })}
            </>
        ) : (
            <div className="Loader">
              <small><Spinner animation="border" /></small>
            </div>
        )}
        </ThemeContainer>
    );
};

export default Benefits;
