import { USER_TYPES } from "../redux-constant-types";

const setUserName = (values) => {
  return {
    type: USER_TYPES.SET_USERNAME,
    payload: {
      username: values.username,
    },
  };
};

const setUserWalletAddress = (values) => {
  return {
    type: USER_TYPES.SET_WALLET_ADDRESS,
    payload: {
      walletAddress: values,
    },
  };
};

const setIsMetamaskInstalled = (values) => {
  return {
    type: USER_TYPES.SET_METAMASK_INSTALLED_STATUS,
    payload: {
      status: values,
    },
  };
};

const setIsWalletConnected = (values) => {
  return {
    type: USER_TYPES.SET_IS_WALLET_CONNECTED,
    payload: {
      status: values,
    },
  };
};

const setUserLoginStatus = (values) => {
  return {
    type: USER_TYPES.SET_IS_LOGGED_IN,
    payload: {
      status: values,
    },
  };
};

const setUserDetails = (values) => {
  return {
    type: USER_TYPES.SET_USER_DETAILS,
    payload: {
      userDetails: {
        userEmail: values.email,
        userName: values.name,
        userId: values.user_id,
        userImage: values.image_url,
      },
    },
  };
};
const setUserLogout = () => {
  return {
    type: USER_TYPES.SET_USER_LOGOUT,
  };
};

export {
  setUserName,
  setUserWalletAddress,
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserLoginStatus,
  setUserDetails,
  setUserLogout,
};
