import React from "react";
import NFTTableData from "../list-data/buytoken-order-table-data";
import { NFTTableFilter } from "./nft-table-filter";

const NatureNFTTable = ({ page }) => {
  return (
    <div className="db-table pb-4">
      <div className="table-head">
        <h2 className="my-4 ms-4">Crypto Dinar</h2>
      </div>
      <div className="table-form  mx-1 mx-md-4 my-3">
        <div className="row">
          <NFTTableFilter page={page} />
        </div>
      </div>
      <div className="row">
        <div className="col col-md-12">
          <div className="table-main mx-1 mx-md-4">
            <NFTTableData page={page} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NatureNFTTable;
