import React, { useRef, useState, useEffect } from "react";
import useForm from "./../../hooks/use-form";
import { copyText } from "../../core/utils/copy";
import NFTFormCrypto from "./nft-form-crypto";
import NFTFormFiat from "./nft-form-fiat";
import { getWalletAddress } from "../../core/utils/token";
import { useSelector } from "react-redux";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { Dropdown } from "react-bootstrap";
import infoIcon from "../../assets/images/information.png";
import useAlert from "./../../hooks/use-alert";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { getRedemptionMaster, postRedemptionOrder } from "../../core/services/api/nft";
import { validateRedemptionOrder } from "../../core/validations/user-validations";
import metamask from "../../core/services/smart-contracts/metamask";
const RedemptionForms = () => {  
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cryptoAssets, setCryptoAssets] = useState([]);
  const [vaults, setVaults] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  
  const wallet = getWalletAddress();
  const [data, setData] = useState();
  const [smartContact, setSmartContract] = useState();

  const getRedemptionData = async () => {
    const res = await getRedemptionMaster();
    if (res.success) {
      if(res.crypto_assets && res.crypto_assets.length === 1){
        setValues((preValues) => ({
          ...preValues,
          crypto_token_id: res.crypto_assets[0].id,
          crypto_token_name: res.crypto_assets[0].name,
          minToken: res.crypto_assets[0].token_quantity,
          weight: res.crypto_assets[0].weight,
          redemption_fee: res.crypto_assets[0].redemption_fee,
          text_message: res.crypto_assets[0].text_message,
        }));
      }
      if(res.vault_details && res.vault_details.length === 1){
        setValues((preValues) => ({
          ...preValues,
          crypto_token_id: res.crypto_assets[0].id,
          crypto_token_name: res.crypto_assets[0].name,
        }));
      }
      setCryptoAssets(res.crypto_assets);
      setVaults(res.vault_details);
    }
  };
  const submitRedemptionOrder = async () => {
    setDisabled(true);
    const data = {
      id: parseFloat(values.crypto_token_id, 10),
      token: parseFloat(values.token_qty, 10),
      address: "0xC8D889EA91055F605eda1e611b8239527c6DD164",
    };
    const resp = await metamask.metaMintToken(data);
    if (resp.transactionHash) {
      const val = {
        crypto_token_id: values.crypto_token_id,
        token_qty: values.token_qty,
        vault_id: values.vault_id,
        transaction_hash: resp.transactionHash,
      };
      const response = await postRedemptionOrder(val);
      if (response.status) {
        showAlert("SUCCESS", "Success", response.message);
        navigate("/orders");
      } else {
        setDisabled(false);
        showAlert("ERROR", "Error", response.message);
      }
    }else{
      setDisabled(false);
    }
  };
  const handleSelectChange = (e) => {
    setValues((preValues) => ({
      ...preValues,
      crypto_token_id: parseInt(e.target.value),
    }));
    //setSelected(e.target.value);
  };
  const handleSelectChange1 = (e) => {
    setValues((preValues) => ({
      ...preValues,
      vault_id: parseInt(e.target.value),
    }));
    //setSelected(e.target.value);
  };
  const handleToken = (e) => {
    setValues((preValues) => ({
      ...preValues,
      totalToken: e.target.value,
    }));
  };
  useEffect(() => {
    getRedemptionData();
    setValues((preValues) => ({
      ...preValues,
      wallet: wallet,
      vault_id: "",
      totalToken: ""
    }));
  }, []);

  const { values, errors, handleSubmit, handleChange, setValues } = useForm(
    submitRedemptionOrder,
    validateRedemptionOrder
  );
  const message = "On successfull transaction, coins will be transfered to this account";
  let minToken = values.minToken;
  let totalToken = values.totalToken;
  let weight;
  let redemption_fee;
  let TotalFee;
  let TotalWeight;
  if(totalToken !== ""){    
    weight = values.weight;
    redemption_fee = values.redemption_fee;
    TotalFee = totalToken*redemption_fee;
    TotalFee = TotalFee/100;
    TotalWeight = totalToken*redemption_fee;
  }else{
    weight = values.weight;
    redemption_fee = values.redemption_fee;
    TotalFee = minToken*redemption_fee;
    TotalFee = TotalFee/100;
    TotalWeight = minToken*redemption_fee;    
  }
  
  return (
    <div className="tab-content" id="nav-tabContent">
      <div
        className="tab-pane fade buy-token-radius show py-4 px-4 active"
        id="crypto-home"
        role="tabpanel"
        aria-labelledby="crypto-home-tab"
      >
          <form className="row g-3 my-3" onSubmit={handleSubmit}>
            <div className="col-12">
              <label htmlFor="inputAddress" className="form-label label-gr">
                Your Wallet Address
                <span className="color-red">*</span>
              </label>
              <Tippy content={message}>
                <img src={infoIcon} alt="" className="info-icon" />
              </Tippy>
              <div className="input-group">
                <input
                  className="form-control bg-gray"
                  id="inputAddress"
                  disabled
                  value={values.wallet === "" || values.wallet === "undefined" || values.wallet === undefined ? "" : wallet}
                />
                <div className="input-group-text bg-gr cursor-pointer" 
                  onClick={() => {
                    copyText(wallet),
                    showAlert(
                      "SUCCESS",
                      "Success",
                      "Wallet Address Copied"
                      );
                  }}
                >
                  <img src={CopyIcon} />
                </div>
              </div>
              {errors.wallet && (
                <p className="text-danger">
                  <small>{errors.wallet}</small>
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label label-gr">
                Token Quantity
                <span className="color-red">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-gray"
                  id="inputEmail4"
                  name="token_qty"
                  onChange={handleChange}
                  value={values.token_qty === undefined ? "" : values.token_qty}
                  onKeyUp={handleToken}
                />
              </div>
              {errors.token_qty && (
                <p className="text-danger">
                  <small>{errors.token_qty}</small>
                </p>
              )}
              <label htmlFor="inputEmail4" className="form-label label-red">
                Minimum token to purchase is {minToken}
              </label>
              <label htmlFor="inputEmail4" className="form-label label-yellow">
                1 Token = {values.weight}mg
              </label>
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword4" className="form-label label-gr">
                Token Name
                <span className="color-red">*</span>
              </label>
              <div className="input-group">
                {cryptoAssets &&
                  cryptoAssets.length === 1 ? (
                  <input
                      type="text"
                      className="form-control bg-gray"
                      id="inputEmail4"
                      readOnly
                      name="crypto_token_id"
                      value={values.crypto_token_name}
                    />
                  ) : (
                    <>
                      <select className="bg-gray nft-select" onChange={handleSelectChange}>                  
                        {cryptoAssets.map((crypto_asset, crypt_index) => (
                          <option value={crypto_asset.id} key={crypt_index}>
                            {crypto_asset.name}
                          </option>
                        ))}
                      </select>
                    </>
                  )
                }
              </div>
              {errors.crypto_token_id && (
                <p className="text-danger">
                  <small>{errors.crypto_token_id}</small>
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputEmail4" className="form-label label-gr">
                Vault
                <span className="color-red">*</span>
              </label>
              <div className="input-group">
                <select className="bg-gray nft-select" onChange={handleSelectChange1} >
                  <option value="">Please select vault</option>
                  {vaults &&
                    vaults.map((val, index) => (
                      <option value={val.id} key={index}>
                        {val.name}
                      </option>
                    ))}
                </select>
              </div>
              {errors.vault_id && (
                <p className="text-danger">
                  <small>{errors.vault_id}</small>
                </p>
              )}
            </div>
            <div className="col-md-6 redemtion-fee-weight">
              <label htmlFor="inputEmail4" className="label-gr">
                &nbsp;
              </label>
              <div className="input-group">
                <p>Redemption fee in token <span> {TotalFee} </span></p>
                <p>Total Weight <span> {TotalWeight}mg </span></p>
              </div>
            </div>
            {errors.cryptoToken && (
              <p className="text-danger">
                <small>{errors.cryptoToken}</small>
              </p>
            )}
            <div className="col-12 text-center confirm-btn">
              <button type="submit" className="btn btn-primary w-60 crypto-dinar-btn" disabled={disabled}>
                Confirm
              </button>
            </div>
          </form>          
      </div>
    </div>
  );
};

export default RedemptionForms;
