import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCrypto } from "../../redux/actions/nft-action";

const OtherNFTProjects = ({ page }) => {
  const dispatch = useDispatch();
  const { masterData, energyMasterData } = useSelector(
    (state) => state.nftReducer
  );
  const data = page == "nature" ? masterData : energyMasterData;
  const handleChange = (e) => {
    const num = parseInt(e.target.value, 10);
    dispatch(setSelectedCrypto(num));
  };
  return (
    <>
      {data !== null && (
        <select
          name="projects"
          id="project1"
          className="me-auto ms-auto me-md-2 ms-md-0 mb-2 mb-md-0"
          onChange={handleChange}
        >
          {data.crypto_tokens.map(({ name, id }, index) => (
            <option value={id} key={index}>
              {name}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default OtherNFTProjects;
