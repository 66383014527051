import {
  _isMetamaskInstalled,
  _connectToMetamask,
  _getActiveAccount,
  _mintToken,
  _metaMintToken,
} from "../data/metamask_service";

// get user connected wallet address
const getUserAddress = async () => {
  const address = await _getActiveAccount();
  return address;
};

// connect user with installed wallet if not connected
const connectWithWallet = async () => {
  const result = await _connectToMetamask();
  return result;
};

// check is metamask wallet installed or not
const checkMetamaskInstallation = async () => {
  const result = await _isMetamaskInstalled();
  return result;
};
// mint token
const mintToken = async (token) => {
  const result = await _mintToken(token);
  return result;
};
const metaMintToken = async (data) => {
  const value = {
    id: data.id,
    token: data.token,
    address: data.address,
  };
  const mintTokens = await _metaMintToken(value);
  return mintTokens;
};

export default {
  getUserAddress,
  connectWithWallet,
  checkMetamaskInstallation,
  mintToken,
  metaMintToken,
};
