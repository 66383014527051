import React from "react";
const CryptoHeader = ({ header, subHeading, page = "" }) => {
  return (
    <>
      <div className="row mb-2">
        <h3
          className={
            page == "KYC"
              ? "crypto-dinar-white"
              : "crypto-dinar-white font-gil-bold"
          }
        >
          {header}
        </h3>
      </div>
    </>
  );
};
export default CryptoHeader;
