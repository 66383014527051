/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { validateQRCode } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import ConfirmPasswordForm from "./confirm-password-form";
import QRCode from "react-qr-code";
import {
  getQrcode,
  validateQrCode,
  validateQrCodeAPI,
} from "../../../core/services/api/nft";
import { useNavigate } from "react-router-dom";
import {
  getAWSSession,
  getAWSToken,
  getEnergyId,
  getUserName,
} from "../../../core/utils/token";
import { copyText } from "../../../core/utils/copy";
import useAlert from "./../../../hooks/use-alert";
import { Row, Col } from "react-bootstrap";
import OtpInput from "react-otp-input";
import CopyIcon from "../../../assets/images/copy-icon.png";

const QrCodeForm = ({ hide, getUserData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState();
  const { showAlert } = useAlert();
  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState(false);
  const validation = () => {
    if (code !== "" || code !== null || code !== undefined || code.length < 6) {
      return true;
    }
    return false;
  };
  const handleSubmitVerify = async () => {
    const validate = validation();
    if (validate) {
      const data = {
        aws_sso_token: getAWSToken(),
        qr_code: code,
        aws_sso_session: getAWSSession(),
        user_name: getUserName(),
        multi_tsp_user_id: getEnergyId(),
      };
      const res = await validateQrCodeAPI(data);
      if (res.status) {
        localStorage.setItem("otp_check", true);
        showAlert("SUCCESS", "Success", res.message);
        hide();
        getUserData();
      } else {
        if(code === ""){
          setErrorMsg(true)          
        }else{
          showAlert("ERROR", "Error", res.message);          
        }
      }
    }
  };

  const { values, setValues } = useForm();
  const handleChange = (code) => setCode(code);
  const getQRCode = async () => {
    const data = {
      aws_sso_token: getAWSToken(),
    };
    const res = await getQrcode(data);
    if (res.success) {
      const base64 = res.qr_code;
      const imgs = base64.split("'");
      setQrCode(imgs[1]);
      setValues((preValues) => ({
        ...preValues,
        secretKey: res.qr_secret,
      }));
    }
  };

  useEffect(() => {
    getQRCode();
  }, []);
  return (
    <>
      {qrCode ? (
        <>
          <div className="text-center ">
            <img
              style={{ height: "150px", width: "150px" }}
              src={`data:image/png;base64,${qrCode}`}
            />
          </div>
          <br />
          <span>Secret Key :</span> {}
          <div className="input-group">
            <input
              className="form-control bg-gray"
              id="inputAddress"
              disabled
              value={values.secretKey}
            />
            <div
              className="input-group-text bg-gr cursor-pointer copy-icon"
              onClick={() => {
                copyText(values.secretKey),
                  showAlert("SUCCESS", "Success", "Secret Key Copied");
              }}
            >
              <img src={CopyIcon} />
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="text-center">
        <div className="my-3">
          <label
            htmlFor="password"
            className="form-label font-14 font-w-500 crypto-dinar-white mt-3"
          >
            Enter OTP
          </label>
        </div>
        <Row className="mb-4">
          <Col md={2}></Col>
          <Col md>
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={6}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "16px",
                color: "rgba(25, 55, 110, 1)",
                fontWeight: "600",
                backgroundColor: "#F5F8FF",
              }}
              focusStyle={{
                outline: "none",
              }}
            />
          </Col>
          {errorMsg && (
              <p className="text-danger">
                <small>Confirmation code must not be empty</small>
              </p>
            )}
        </Row>

        <button
          onClick={handleSubmitVerify}
          className="buy-coin-btn w-100"
        >
          Verify OTP
        </button>
      </div>
    </>
  );
};

export default QrCodeForm;
