import { ALERT_TYPES } from "../redux-constant-types";


const setShowAlertData = (values) => {
    return {
        type: ALERT_TYPES.SET_ALERT_DATA,
        payload: {
            alertType: values.alertType,
            alertTitle: values.alertTitle,
            alertMessage: values.alertMessage,
            status: true
        }
    };
};

const setCloseAlertData = () => {
    return {
        type: ALERT_TYPES.SET_ALERT_DATA,
        payload: {
            alertType: "",
            alertTitle: "",
            alertMessage: "",
            status: false
        }
    };
};

export { setShowAlertData, setCloseAlertData };
