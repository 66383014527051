import React from "react";

const NFTFormFiat = ({ message }) => {
  return (
    <>
      <h2 className="formt">
        {message.fiatPercent}% Discount for a limited period.
      </h2>
      <div
        className="form-discount"
        dangerouslySetInnerHTML={{ __html: message.fiatMessage }}
      />
      </>
  );
};

export default NFTFormFiat;
