import React, { useEffect, useState } from "react";
import { getFooterData } from "../../../core/services/api/home";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

import Footerbg from "../../../assets/images/footer-bg.png";
import Footerlogo from "../../../assets/images/footer-logo.png";

const Footer = () => {

  const [column1, setColumn1] = useState([]);
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);
  const [column4, setColumn4] = useState([]);
  const [column5, setColumn5] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [footerLogo, setFooterLogo] = useState("");
  const [copy_right, setCopyright] = useState("");
  const PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  const fetchFooterData = async () => {
    const result = await getFooterData();
    if (result && result.success_msg === "Successfully") {
      
      setColumn1(result.column1);
      setColumn2(result.column2);
      setColumn3(result.column3);
      setColumn4(result.column4);
      setColumn5(result.column5);
      setCopyright(result.copyright);
      setFooterLogo(result.footer_logo);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);
  let footer_logo;
  if(footerLogo){
    footer_logo = PATH+footerLogo;
  }else{
    footer_logo = Footerlogo;
  }
  return (
    <>
      <footer style={{ backgroundImage: `url(${Footerbg})` }} >
         <div className="container">
            <Link to="/" className="footer-logo">
              <img src={footer_logo} alt="" />
            </Link>            
            <div className="flex">
              {column1 && column1.length > 0 &&
               <div className="column">
                  <h5>{column1[0].main_head}</h5>
                  <ul>                    
                      {column1.map((data, col1) => (
                        <>
                          {data.page_type_name === "link" ? (
                            <li key={col1}><Link to="#">{data.display_name}</Link></li>
                          ):(
                            <li key={col1}><Link to={`/${ data.page_type_name }`}>{data.display_name}</Link></li>
                          )}
                        </>
                      )
                    )}
                  </ul>
               </div>
              }
              {column2 && column2.length > 0 &&
               <div className="column">
                  <h5>{column2[0].main_head}</h5>
                  <ul>                     
                      {column2.map((data, col2) => (
                          <li key={col2}><Link to={`/${ data.page_type_name }`}>{data.display_name}</Link></li>
                        )
                      )}
                  </ul>
               </div>
              }
              {column3 && column3.length > 0 &&
               <div className="column">
                  <h5>{column3[0].main_head}</h5>
                  <ul>
                      {column3.map((data, col3) => (
                        <>
                          {data.page_type_name !== "user" ? (
                            <li key={col3}><Link to={`/${ data.page_type_name }`}>{data.display_name}</Link></li>
                          ):(
                            <li key={col3}><a href={`/page/${ data.page_type_name }`}>{data.display_name}</a></li>
                          )}
                          </>
                        )
                      )}
                  </ul>
               </div>
             }
             {column4 && column4.length > 0 &&
               <div className="column">
                  <h5>{column4[0].main_head}</h5>
                  <ul>
                     {column4.map((data, col4) => (
                          <li key={col4}><a href={`/page/${ data.page_type_name }`}>{data.display_name}</a></li>
                        )
                      )}
                  </ul>
               </div>
             }
             {column5 && column5.length > 0 &&
               <div className="column">
                  <h5>{column5[0].main_head}</h5>
                  <ul>
                      {column5.map((data, col5) => (
                        <>
                          {data.page_type_name === "support" ? (
                            <li key={col5}><a href={`mailto:${ data.display_name }`}>{data.display_name}</a></li>
                          ):(
                            <li key={col5}><a href={`tel:${ data.display_name }`}>{data.display_name}</a></li>
                          )}
                        </>
                        )
                      )}
                  </ul>
               </div>
             }
            </div>
         </div>
      </footer>
      <div className="copy-right">
        {copy_right ? (
         <p>{ReactHtmlParser(copy_right)}</p>
         ) : (
         <p>Copyright © <a href="#">Crypto Dinar</a> | {new Date().getFullYear()}</p>
         )}
      </div>
    </>  
  );
};

export default Footer;
