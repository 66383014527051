import React, { useEffect, useState } from 'react';
import Banner from '../../components/shared/banner';
import ThemeContainer from '../../containers/theme-container';
import { getPageTypeData } from '../../core/services/api/home';

const PrivacyPolicy = () => {
    const [banners, setBanners] = useState([]);

    const fetchPageData = async (pageType = "corporate") => {
        const result = await getPageTypeData(pageType);
        if (result && result.message === "Successful") {
            setBanners(result.banner_ids);
        } else {
            console.log(result);
        }
    };

    useEffect(() => {
        fetchPageData();
    }, []);

    return (
        <ThemeContainer type="public" pageTitle="Privacy policy">
            <Banner
                banners={banners}
            />
            <div className="p-5">
                <div className="card border-radidus-16 p-5">
                    <div classNamed="card-body p-5">
                        <h4 className="font-C5997C font-24 font-w-600">Privacy Policy</h4>
                        <h2 className="font-34 font-w-400">Effective Date: October 31, 2021</h2>
                        <p className="font-656565">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur metus ante, sed aliquet risus eleifend id. Fusce ante dolor, bibendum sed tincidunt non, scelerisque sit amet dui. Phasellus sit amet tincidunt neque. Morbi a urna eu sem finibus rhoncus. Duis non turpis venenatis, consequat urna ut, fringilla ligula. Integer vulputate ut justo a ornare. Fusce odio metus, lacinia nec scelerisque ac, vehicula sit amet risus. Fusce gravida molestie urna, vel suscipit ligula fringilla nec. In dignissim cursus neque sed mattis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras ac suscipit massa, semper hendrerit neque. Vivamus porttitor tempus neque, ac mattis lacus convallis et. Quisque ac suscipit leo.
                        </p>
                        <p className="font-656565">

                            Sed fringilla aliquam ultricies. Curabitur sit amet nulla quam. Nulla ultricies efficitur nisl, nec eleifend quam dapibus sed. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Ut posuere justo eu gravida egestas. Duis fermentum, purus a viverra rhoncus, turpis orci ornare ligula, eu faucibus nisi arcu ac nunc. Maecenas non tincidunt lacus. Integer a pharetra nunc. Vivamus non quam eget nunc rutrum cursus sed sit amet nisi. Pellentesque finibus pretium ante, non fermentum velit vestibulum eu. Pellentesque auctor congue pharetra. Etiam cursus leo eu nibh euismod elementum nec ac diam.
                        </p>
                        <p className="font-656565">

                            Donec enim lacus, vulputate sit amet condimentum at, ornare vitae ex. Donec pharetra ullamcorper viverra. Pellentesque facilisis eleifend justo ut dapibus. Integer nec dui scelerisque magna lacinia placerat id in est. Cras tempus risus ac neque mattis dapibus. Maecenas sit amet dui quam. Maecenas sit amet sem et nisi fermentum sagittis. Praesent sapien neque, rutrum interdum orci ac, iaculis sollicitudin arcu. Aliquam eleifend justo ac est rhoncus gravida.
                        </p>
                        <p className="font-656565">

                            Integer elementum ullamcorper dolor, dignissim pellentesque neque pellentesque vel. Nulla ut lectus ut quam tempor imperdiet. Nullam dictum auctor nulla vel luctus. Vestibulum vel fringilla quam, sit amet commodo ipsum. Pellentesque posuere ligula orci, eleifend feugiat neque consectetur eu. Nulla sed massa elit. Pellentesque vitae viverra leo, non volutpat diam. Integer et augue quis purus venenatis pharetra eu id dui. Donec nec mi eget massa tincidunt laoreet posuere a libero. Nam fermentum nisi a mi maximus mollis.
                        </p>
                        <p className="font-656565">

                            Nullam interdum purus vel porta consequat. Phasellus et euismod neque. Praesent viverra, turpis malesuada iaculis lobortis, tortor dui bibendum tortor, in finibus lorem sapien nec justo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent et diam porta, volutpat ligula in, fringilla odio. Quisque vitae felis lorem. Proin convallis eleifend velit, et posuere felis. Mauris eu dolor consectetur, volutpat diam sit amet, commodo massa. Praesent eleifend blandit tortor, a scelerisque turpis pharetra quis. Praesent porta neque id magna sagittis facilisis. Fusce a volutpat ligula. Donec et ornare mauris. Nunc sit amet felis leo. In sagittis mattis nulla vel suscipit. Vivamus eleifend felis nec fringilla condimentum. Etiam facilisis sodales venenatis.
                        </p>
                        <p className="font-656565">

                            Sed mauris erat, varius vitae elit id, sollicitudin scelerisque eros. Sed rutrum sem quis risus tincidunt, eu imperdiet mi laoreet. Vivamus ac consectetur urna. In hac habitasse platea dictumst. Curabitur ultricies eros sed velit bibendum, id consequat dolor ullamcorper. Donec lacinia urna eget lobortis posuere. Nam sed sem ut ipsum scelerisque elementum non at quam. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae;
                        </p>
                        <p className="font-656565">

                            Sed nec risus sed lorem posuere fermentum nec sed sem. Proin lacinia ultrices tortor. Sed pharetra justo eget diam maximus sodales. Donec at odio sed velit tristique vestibulum. Phasellus est magna, maximus non rutrum sit amet, dapibus ut mi. Phasellus et felis risus. Sed at urna ornare, eleifend lacus in, porttitor elit. Donec placerat nulla nec mauris blandit ornare. Nunc posuere pellentesque magna, vitae efficitur ex laoreet et. Aliquam porta, eros ac auctor ultrices, urna quam commodo metus, et tincidunt urna odio sit amet odio. Fusce egestas ultrices porta. Ut fringilla, leo ac suscipit mattis, eros justo fermentum justo, et imperdiet elit purus id diam. Mauris aliquam tellus in odio tempus blandit.
                        </p>
                        <p className="font-656565">

                            Duis nec dolor sed erat consectetur ultricies. Curabitur ultricies felis in nunc dapibus, sed iaculis turpis varius. Nam id lorem purus. Proin porttitor enim vitae semper venenatis. Morbi rhoncus ligula quam, nec vehicula est pretium ac. Duis vulputate ultricies mauris, non egestas lorem pharetra non. Nunc non varius eros. Integer tincidunt erat non semper maximus. Aliquam non lacus eget nunc lacinia accumsan. Pellentesque porttitor est ut purus bibendum lacinia. Pellentesque venenatis metus vitae molestie dapibus. Morbi posuere enim arcu, ut venenatis lacus dictum in. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer mattis feugiat libero, sed sollicitudin sem volutpat eu.
                        </p>
                        <p className="font-656565">

                            Proin malesuada, tortor id pretium volutpat, neque dolor rhoncus enim, quis volutpat sem felis et sapien. Aenean vitae augue nec ligula ultricies fermentum a vel mauris. Maecenas a est euismod, consequat enim a, fermentum enim. Morbi quis justo ac turpis consectetur dignissim at non arcu. Aliquam ac finibus libero, in pulvinar mauris. Donec tincidunt tincidunt enim at efficitur. Integer porta at ex at blandit. Praesent nisi enim, sodales sed tortor tempor, posuere ullamcorper ante.
                        </p>
                        <p className="font-656565">

                            Cras tempor facilisis turpis, lacinia tristique libero fermentum eu. In condimentum facilisis nisl eu semper. Integer risus nibh, sollicitudin id euismod ut, iaculis sit amet nulla. Aliquam mollis rhoncus nisi vitae fringilla. Aliquam velit tellus, rhoncus id ante vitae, faucibus pellentesque enim. Sed enim dui, venenatis eget dui at, vehicula auctor sapien. Pellentesque volutpat eu risus ut sagittis. In facilisis libero ante, ac euismod quam lacinia nec. Nunc convallis, massa quis elementum convallis, nisl nibh pellentesque augue, sed commodo nunc lectus et purus. Aliquam commodo blandit dui sit amet hendrerit. Donec imperdiet lacinia lectus nec pretium.
                        </p>
                        <p className="font-656565">

                            Vivamus lobortis id tellus ut condimentum. Nam pretium est est, at dapibus est sollicitudin et. Nulla bibendum suscipit eros porttitor feugiat. Nam nec elementum erat. Integer posuere neque nec massa auctor, sit amet cursus dolor lacinia. Nam ipsum elit, dictum non erat a, semper finibus erat. Curabitur leo ligula, congue quis iaculis eget, rutrum vel massa. Morbi tincidunt feugiat rutrum. Praesent pharetra augue nisl, sit amet cursus nisi placerat non. Sed cursus iaculis libero eu vehicula. Aliquam ex lectus, efficitur non libero non, vulputate dignissim magna. Vestibulum at sapien a dolor vulputate mattis non id risus. Pellentesque consectetur lacus id orci sagittis dapibus.
                        </p>
                        <p className="font-656565">

                            Maecenas fermentum at mauris sed fringilla. Proin vehicula posuere quam. Nullam fringilla nisl quis feugiat ornare. Vivamus non orci id quam imperdiet tincidunt. Aenean rutrum turpis at urna convallis porttitor. Integer convallis urna nec mauris commodo, eu dignissim ipsum feugiat. Quisque tincidunt, nisi vitae pharetra commodo, urna quam commodo magna, non vehicula dolor erat ut nisl. Nulla ac magna convallis, tristique elit eget, placerat urna. Vivamus hendrerit tincidunt turpis, interdum molestie tortor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        </p>
                        <p className="font-656565">

                            Donec purus dolor, egestas a dolor sed, lacinia sagittis elit. Donec porttitor lorem at lorem pharetra tempus. Sed metus libero, tempus et venenatis non, lobortis et mauris. Nam vel velit eu leo venenatis maximus. Curabitur pharetra orci lacinia vulputate fermentum. Aliquam pretium at sapien et porttitor. Cras in aliquam mi, ut pretium turpis. Nunc ultrices, lectus sed ultricies ultricies, augue nibh imperdiet magna, eu viverra nisl nisl ut arcu. Phasellus lobortis hendrerit imperdiet. Suspendisse blandit, nulla vitae sodales gravida, dui enim sagittis sem, eget sagittis libero nisi eget eros. Curabitur tincidunt, sapien a commodo molestie, mauris sem congue augue, vehicula egestas tellus nisi ut orci. Suspendisse potenti. Duis a lacus maximus, lacinia quam ut, hendrerit ipsum.
                        </p>
                        <p className="font-656565">

                            Mauris posuere urna enim, a fermentum ante rhoncus suscipit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus convallis dictum. Aliquam erat volutpat. Duis molestie mi tellus, et tincidunt dui finibus vel. Integer a placerat massa. Praesent dapibus sem ac est ultrices, at faucibus odio volutpat.
                        </p>
                        <p className="font-656565">

                            In eget ullamcorper leo. Phasellus quis interdum mauris. Etiam eleifend laoreet nibh quis eleifend. Nulla porta id augue in sollicitudin. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris elit sem, bibendum eu scelerisque vitae, consequat ultricies ante. Praesent convallis nisl nec dui lacinia pretium. Nullam commodo justo odio, sodales dictum urna suscipit et. Ut malesuada vulputate diam sed facilisis.
                        </p>
                        <p className="font-656565">
                            Maecenas fermentum at mauris sed fringilla. Proin vehicula posuere quam. Nullam fringilla nisl quis feugiat ornare. Vivamus non orci id quam imperdiet tincidunt. Aenean rutrum turpis at urna convallis porttitor. Integer convallis urna nec mauris commodo, eu dignissim ipsum feugiat. Quisque tincidunt, nisi vitae pharetra commodo, urna quam commodo magna, non vehicula dolor erat ut nisl. Nulla ac magna convallis, tristique elit eget, placerat urna. Vivamus hendrerit tincidunt turpis, interdum molestie tortor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        </p>
                        <p className="font-656565">

                            Donec purus dolor, egestas a dolor sed, lacinia sagittis elit. Donec porttitor lorem at lorem pharetra tempus. Sed metus libero, tempus et venenatis non, lobortis et mauris. Nam vel velit eu leo venenatis maximus. Curabitur pharetra orci lacinia vulputate fermentum. Aliquam pretium at sapien et porttitor. Cras in aliquam mi, ut pretium turpis. Nunc ultrices, lectus sed ultricies ultricies, augue nibh imperdiet magna, eu viverra nisl nisl ut arcu. Phasellus lobortis hendrerit imperdiet. Suspendisse blandit, nulla vitae sodales gravida, dui enim sagittis sem, eget sagittis libero nisi eget eros. Curabitur tincidunt, sapien a commodo molestie, mauris sem congue augue, vehicula egestas tellus nisi ut orci. Suspendisse potenti. Duis a lacus maximus, lacinia quam ut, hendrerit ipsum.
                        </p>
                        <p className="font-656565">

                            Mauris posuere urna enim, a fermentum ante rhoncus suscipit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus convallis dictum. Aliquam erat volutpat. Duis molestie mi tellus, et tincidunt dui finibus vel. Integer a placerat massa. Praesent dapibus sem ac est ultrices, at faucibus odio volutpat.
                        </p>
                        <p className="font-656565">

                            In eget ullamcorper leo. Phasellus quis interdum mauris. Etiam eleifend laoreet nibh quis eleifend. Nulla porta id augue in sollicitudin. Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris elit sem, bibendum eu scelerisque vitae, consequat ultricies ante. Praesent convallis nisl nec dui lacinia pretium. Nullam commodo justo odio, sodales dictum urna suscipit et. Ut malesuada vulputate diam sed facilisis.
                        </p>
                        <p className="font-656565">

                            Maecenas fermentum at mauris sed fringilla. Proin vehicula posuere quam. Nullam fringilla nisl quis feugiat ornare. Vivamus non orci id quam imperdiet tincidunt. Aenean rutrum turpis at urna convallis porttitor. Integer convallis urna nec mauris commodo, eu dignissim ipsum feugiat. Quisque tincidunt, nisi vitae pharetra commodo, urna quam commodo magna, non vehicula dolor erat ut nisl. Nulla ac magna convallis, tristique elit eget, placerat urna. Vivamus hendrerit tincidunt turpis, interdum molestie tortor. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                        </p>
                        <p className="font-656565">

                            Donec purus dolor, egestas a dolor sed, lacinia sagittis elit. Donec porttitor lorem at lorem pharetra tempus. Sed metus libero, tempus et venenatis non, lobortis et mauris. Nam vel velit eu leo venenatis maximus. Curabitur pharetra orci lacinia vulputate fermentum. Aliquam pretium at sapien et porttitor. Cras in aliquam mi, ut pretium turpis. Nunc ultrices, lectus sed ultricies ultricies, augue nibh imperdiet magna, eu viverra nisl nisl ut arcu. Phasellus lobortis hendrerit imperdiet. Suspendisse blandit, nulla vitae sodales gravida, dui enim sagittis sem, eget sagittis libero nisi eget eros. Curabitur tincidunt, sapien a commodo molestie, mauris sem congue augue, vehicula egestas tellus nisi ut orci. Suspendisse potenti. Duis a lacus maximus, lacinia quam ut, hendrerit ipsum.
                        </p>
                        <p className="font-656565">

                            Mauris posuere urna enim, a fermentum ante rhoncus suscipit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Donec finibus convallis dictum. Aliquam erat volutpat. Duis molestie mi tellus, et tincidunt dui finibus vel. Integer a placerat massa. Praesent dapibus sem ac est ultrices, at faucibus odio volutpat.

                        </p>
                    </div>
                </div>
            </div>
        </ThemeContainer>
    );
};
export default PrivacyPolicy;
