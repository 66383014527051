import React from 'react';
import ImageCard from '../../components/modals/image-card';
import homeSection7Img1 from "../../assets/images/home-section7-img1.svg";
import homeSection7Img2 from "../../assets/images/home-section7-img2.svg";
import homeSection7Img3 from "../../assets/images/home-section7-img3.svg";
import homeSection7Img4 from "../../assets/images/home-section7-img4.svg";
import homeSection7Img5 from "../../assets/images/home-section7-img5.svg";
import homeSection7Img6 from "../../assets/images/home-section7-img6.svg";
import cnbcLogo from "../../assets/images/cnbc-logo.png";
import dubaiTV from "../../assets/images/Dubai_TV_Logo.png";
import dubaiMedia from "../../assets/images/kisspng-dubai-media-incorporated-dubai.png";

const NewsAndMedia = ({ title1, heading1, title2, heading2 }) => {
    return (
        <>
            <div>
                { title1 && <h3 className="font-24 font-w-600 mb-4 font-34A853">{title1}</h3> }
                { heading1 && <h1 className="font-42 font-w-400 mb-4">{heading1}</h1> }
                { title2 && <h2 className="font-38 font-w-600">{title2}</h2> }
                { heading2 && <p className="font-20 font-w-400 font-919191">{heading2}</p> }
            </div>

            <div className="row">
                <div className="col-8">
                    <img src={cnbcLogo} className="position-absolute" style={{ margin: "1% 0% 0% 51%" }} />
                    <ImageCard image={homeSection7Img1}/>
                    <div className="card news-headlines">
                        <div className="card-body font-ffffff">
                            <h3>Headline of the news</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae. Praesent at bibendum tortor. Pellentesque at purus at est varius ullamcorper.</p>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="mb-3">
                        <img src={dubaiMedia} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} />
                        <ImageCard image={homeSection7Img2} />
                    </div>
                    <img src={cnbcLogo} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} />
                    <ImageCard image={homeSection7Img3} />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-4 ">
                    <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} />
                    <ImageCard image={homeSection7Img4} />
                </div>
                <div className="col-4">
                    <img src={dubaiMedia} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} />
                    <ImageCard image={homeSection7Img5} />
                </div>
                <div className="col-4">
                    <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} />
                    <ImageCard image={homeSection7Img6} />
                </div>
            </div>
        </>
    );
};

export default NewsAndMedia;
