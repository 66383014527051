import React from 'react';
import ProjectBanner from "../../assets/images/project-detail/nature-nft-banner.jpg";

const ProjectListBanner = () => {
    return (
        <>
            <section className="head">
                <img id="head1" src={ ProjectBanner } />
            </section>
        </>
    );
};

export default ProjectListBanner;
