import URLS from "../../../configs/api-end-points";
import apiServices from "../data/api_service";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";

const type = PROJECT_TYPES.PROJECT_TYPES_ENERGY;
const dashboardAPI = async () => {
  const url = URLS.DASHBOARD_URL;
  const result = await apiServices.post(url);
  return result;
};
const energyDashboardAPI = async () => {
  const url = URLS.DASHBOARD_URL;
  const result = await apiServices.post(url, {}, type);
  return result;
};

const getMasterData = async () => {
  const url = URLS.ASSET_MASTER_DATA;
  const result = await apiServices.post(url);
  return result;
};
const getEnergyMasterData = async () => {
  const url = URLS.ASSET_MASTER_DATA;
  const result = await apiServices.post(url, {}, type);
  return result;
};

const updateWalletAddress = async (values) => {
  const data = {
    wallet_address: values.walletAddress,
  };
  const url = URLS.UPDATE_WALLET_ADDRESS;
  const result = await apiServices.post(url, data);
  return result;
};
const updateEnergyWalletAddress = async (values) => {
  const data = {
    wallet_address: values.walletAddress,
  };
  const url = URLS.UPDATE_WALLET_ADDRESS;
  const result = await apiServices.post(url, data, type);
  return result;
};
const checkERC20Validation = async (data) => {
  const url = URLS.ERC_20_VALIDATION;
  const result = await apiServices.post(url, data);
  return result;
};
const getPriceToken = async (values) => {
  const url = URLS.GET_TOKEN_PRICE;
  const result = await apiServices.post(url, values);
  return result;
};
const getEnergyPriceToken = async (values) => {
  const url = URLS.GET_TOKEN_PRICE;
  const result = await apiServices.post(url, values, type);
  return result;
};
const getFiatPriceToken = async (values) => {
  const url = URLS.GET_FIAT_TOKEN_PRICE;
  const result = await apiServices.post(url, values);
  return result;
};
const getEnergyFiatPriceToken = async (values) => {
  const url = URLS.GET_FIAT_TOKEN_PRICE;
  const result = await apiServices.post(url, values, type);
  return result;
};
const getGeneratePayment = async (values) => {
  const url = URLS.GENERATE_PAYMENT;
  const result = await apiServices.post(url, values);
  return result;
};
const getEnergyGeneratePayment = async (values) => {
  const url = URLS.GENERATE_PAYMENT;
  const result = await apiServices.post(url, values, type);
  return result;
};
const getAssetRequest = async (values) => {
  const url = URLS.GET_ASSET_REQUEST;
  const result = await apiServices.post(url, values);
  return result;
};
const getEnergyAssetRequest = async (values) => {
  const url = URLS.GET_ASSET_REQUEST;
  const result = await apiServices.post(url, values, type);
  return result;
};
const getAssetRequestDetail = async (values) => {
  const url = URLS.ASSET_REQUEST_DETAIL;
  const result = await apiServices.post(url, values);
  return result;
};
const getEnergyAssetRequestDetail = async (values) => {
  const url = URLS.ASSET_REQUEST_DETAIL;
  const result = await apiServices.post(url, values, type);
  return result;
};
const getQrcode = async (values) => {
  const url = URLS.GET_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};
const createAssetRequest = async (values) => {
  const url = URLS.CREATE_ASSET_REQUEST;
  const result = await apiServices.post(url, values);
  return result;
};
const buyToken = async (values) => {
  const url = URLS.BUY_TOKEN;
  const result = await apiServices.post(url, values);
  return result;
};
const buyHCTToken = async (values) => {
  const url = URLS.BUY_HCT_TOKEN;
  const result = await apiServices.post(url, values);
  return result;
};
const hctRequested = async (values) => {
  const url = URLS.HCT_REQUESTED;
  const result = await apiServices.post(url, values);
  return result;
};
const createEnergyAssetRequest = async (values) => {
  const url = URLS.CREATE_ASSET_REQUEST;
  const result = await apiServices.post(url, values, type);
  return result;
};
const rejectAssetRequest = async (values) => {
  const url = URLS.REJECT_ASSET_REQUEST;
  const result = await apiServices.post(url, values);
  return result;
};
const cryptoConfirmPayment = async (values) => {
  const url = URLS.CRYPTO_CONFIRM_PAYMENT;
  const result = await apiServices.post(url, values);
  return result;
};
const createPayment = async (values) => {
  const url = URLS.CREATE_PAYMENT;
  const result = await apiServices.post(url, values);
  return result;
};

const searchAssetRequest = async (values) => {
  const url = URLS.SEARCH_ASSET_REQUEST;
  const result = await apiServices.post(url, values);
  return result;
};
const searchEnergyAssetRequest = async (values) => {
  const url = URLS.SEARCH_ASSET_REQUEST;
  const result = await apiServices.post(url, values, type);
  return result;
};

const validateQrCodeAPI = async (values) => {
  const url = URLS.VERIFY_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};
const updateProfile = async (values) => {
  const url = URLS.UPDATE_PROFILE;
  const result = await apiServices.post(url, values);
  return result;
};
const getUserDetail = async () => {
  const url = URLS.USER_DETAILS;
  const res = await apiServices.post(url);
  return res;
};
const getTokenDetail = async () => {
  const url = URLS.TOKEN_HOLDING_DETAIL;
  const res = await apiServices.post(url);
  return res;
};
const getMapDetails = async (values) => {
  const url = URLS.MAP_DETAILS;
  const res = await apiServices.post(url, values);
  return res;
};
const postUserUpdate = async (values) => {
  const url = URLS.UPDATE_USER_PROFILE;
  const res = await apiServices.post(url, values);
  return res;
};
const postAddDFender = async (values) => {
  const url = URLS.POST_ADD_DFENDER;
  const res = await apiServices.post(url, values);
  return res;
};
const postUploadUserPhoto = async (values) => {
  const url = URLS.UPLOAD_USER_PHOTO;
  const res = await apiServices.post(url, values);
  return res;
};
const getRedemptionMaster = async (values) => {
  const url = URLS.REDEMPTION_ORDER_MASTER;
  const res = await apiServices.post(url, values);
  return res;
};
const postRedemptionOrder = async (values) => {
  const url = URLS.REDEMPTION_ORDER_CREATE;
  const res = await apiServices.post(url, values);
  return res;
};
const getRedemptionOrderList = async (values) => {
  const url = URLS.REDEMPTION_ORDER_LIST;
  const result = await apiServices.post(url, values);
  return result;
};
const getBuyTokenOrderList = async (values) => {
  const url = URLS.BUY_TOKEN_ORDER_LIST;
  const result = await apiServices.post(url, values);
  return result;
};
const getDFenderOrderList = async () => {
  const url = URLS.DFENDER_ORDER_LIST;
  const result = await apiServices.post(url);
  return result;
};
const disable2Fa = async (values) => {
  const url = URLS.DISABLE_2FA_CODE;
  const result = await apiServices.post(url, values);
  return result;
};
const getReferalCode = async (values) => {
  const url = URLS.REFERAL_CODE;
  const result = await apiServices.post(url, values);
  return result;
};

export {
  getDFenderOrderList,
  postAddDFender,
  dashboardAPI,
  createPayment,
  getMasterData,
  updateWalletAddress,
  searchEnergyAssetRequest,
  getAssetRequestDetail,
  getEnergyAssetRequestDetail,
  getPriceToken,
  getFiatPriceToken,
  checkERC20Validation,
  getGeneratePayment,
  getAssetRequest,
  createAssetRequest,
  rejectAssetRequest,
  cryptoConfirmPayment,
  energyDashboardAPI,
  searchAssetRequest,
  getEnergyMasterData,
  getEnergyPriceToken,
  createEnergyAssetRequest,
  getEnergyFiatPriceToken,
  getEnergyGeneratePayment,
  getEnergyAssetRequest,
  getQrcode,
  validateQrCodeAPI,
  updateProfile,
  getUserDetail,
  getTokenDetail,
  getMapDetails,
  updateEnergyWalletAddress,
  postUserUpdate,
  postUploadUserPhoto,
  getRedemptionMaster,
  postRedemptionOrder,
  getRedemptionOrderList,
  buyToken,
  getBuyTokenOrderList,
  disable2Fa,
  getReferalCode,
  buyHCTToken,
  hctRequested
};
