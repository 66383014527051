import React, { useState, useEffect } from "react";
import { copyText } from "../../core/utils/copy";
import "../../assets/css/tokens.css";
import { useSelector, useDispatch } from "react-redux";
import useForm from "./../../hooks/use-form";
import {
  getEnergyFiatPriceToken,
  getEnergyGeneratePayment,
  getFiatPriceToken,
  getGeneratePayment,
} from "../../core/services/api/nft";

import { validateNatureNFT } from "../../core/validations/user-validations";
import { setAssestRequestId, setPaymentId } from "../../core/utils/token";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import infoIcon from "../../assets/images/information.png";
import CopyIcon from "../../assets/images/copy-icon.svg";
import useAlert from "./../../hooks/use-alert";
import { Dropdown } from "react-bootstrap";

const NFTFormFiat = ({ data, page }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(data.currency[0]);
  const [check, setCheck] = useState(false);

  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const { masterData } = useSelector((state) => state.nftReducer);
  const baseURL =
    page == "nature"
      ? process.env.REACT_APP_API_NATURE_BASE_URL
      : process.env.REACT_APP_API_ENERGY_BASE_URL;
  const generatePayment = async () => {
    const data = {
      fiat_amount: parseFloat(values.cryptoToken, 10),
      token: parseFloat(values.token, 10),
      currency: selected.name,
      currency_id: selected.id,
      crypto_token_id: 1,
    };

    const res =
      page == "nature"
        ? await getGeneratePayment(data)
        : await getEnergyGeneratePayment(data);
    if (res.status) {
      // eslint-disable-next-line multiline-comment-style
      // const values = {
      //   assetRequestId: res.asset_request_id,
      //   paymentId: res.payment_transaction_id,
      // };
      // dispatch(setPaymentId(values));
      setAssestRequestId(res.asset_request_id);
      setPaymentId(res.payment_transaction_id);
      const paymentUrl = res.payment_gateway_url;
      setTimeout(() => {
        window.open(paymentUrl, "_self");
      }, 1000);
    } else {
      showAlert("ERROR", "Error", res.message);
    }
  };
  const getTokenPrice = async (obj) => {
    const datas = {
      currency_id: obj ? obj.id : selected.id,
      crypto_token_id: data.token.id,
      currency: obj ? obj.name : selected.name,

      token:
        values.input == 2 ? 0 : values.token ? parseFloat(values.token, 10) : 0,
      fiat_amount:
        values.input == 1
          ? 0
          : values.cryptoToken
          ? parseFloat(values.cryptoToken, 10)
          : 0,
    };
    if (data.crypto_amount == 0 && data.token == 0) {
      return;
    }
    const result =
      page == "nature"
        ? await getFiatPriceToken(datas)
        : await getEnergyFiatPriceToken(datas);
    if (result.status) {
      setValues((preValues) => ({
        ...preValues,
        token: values.input == 1 ? values.token : result.token.toString(),
        cryptoToken:
          values.input == 2 ? values.cryptoToken : result.amount.toString(),
        usdPrice: result.price,
      }));
    }
    setCheck(true);
  };
  useEffect(() => {
    let a;
    if (check) {
      a = setTimeout(() => {
        getTokenPrice();
      }, masterData.exchange_data[0].tsp_price_valid_in_minutes);
    }
    return () => {
      clearTimeout(a);
    };
  }, [selected]);
  const handleTokenInput = () => {
    setValues((preValues) => ({
      ...preValues,
      cryptoToken: 0,
      input: 1,
    }));
    setTimeout(() => {
      getTokenPrice();
    }, 1000);
  };
  const handleCryptoInput = () => {
    setValues((preValues) => ({
      ...preValues,
      token: 0,
      input: 2,
    }));
    setTimeout(() => {
      getTokenPrice();
    }, 1000);
  };
  const { handleChange, values, setValues, handleSubmit, errors, setErrors } =
    useForm(generatePayment, validateNatureNFT);
  const handleSelectChange = (e) => {
    const obj = JSON.parse(e);
    setSelected(obj);
    getTokenPrice(obj);
  };
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      minimumToken: data.exchangeData.minimum_token
        ? data.exchangeData.minimum_token
        : 1,
    }));

    if (data.token !== "") {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    errors.token && showAlert("ERROR", "Error", errors.token);
  }, [errors]);
  const message =
    "On successfull transaction, coins will be transfered to this account";
  return (
    <div
      className="tab-pane py-4 px-4 fade"
      id="fiat-profile"
      role="tabpanel"
      aria-labelledby="fiat-profile-tab"
    >
      {isLoading == false ? (
        <>
          <form className="row g-3 my-3" onSubmit={handleSubmit}>
            <div className="col-12">
              <label for="inputAddress" className="form-label label-gr">
                Your Wallet Address
                <span className="color-red">*</span>
              </label>
              <Tippy content={message}>
                <img src={infoIcon} alt="" className="info-icon" />
              </Tippy>
              <div className="input-group">
                <input
                  className="form-control bg-gray"
                  id="inputAddress"
                  disabled
                  value={data.walletAddress}
                />
                <div
                  className="input-group-text bg-gr cursor-pointer"
                  onClick={() => {
                    copyText(data.walletAddress),
                      showAlert("SUCCESS", "Success", "Wallet Address Copied");
                  }}
                >
                  <img src={CopyIcon} />
                  {/* <i className="fa fa-clone" aria-hidden="true"></i> */}
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <label for="inputEmail4" className="form-label label-gr">
                Token Quantity
                <span className="color-red">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-gray"
                  id="inputEmail4"
                  name="token"
                  onChange={handleChange}
                  onKeyUp={handleTokenInput}
                  value={values.token}
                />
                {/* {errors.token && (
                  <p className="text-danger">
                    <small>{errors.token}</small>
                  </p>
                )} */}
              </div>
              <label for="inputEmail4" className="form-label label-green">
                USD price: ${values.usdPrice ? values.usdPrice : "0"}
              </label>
              {/* <label for="inputEmail4" className="form-label label-red">
                1 Token = 10mg
              </label> */}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label label-gr">
                Amount
                <span className="color-red">*</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-gray"
                  id="inputEmail4"
                  name="cryptoToken"
                  value={values.cryptoToken}
                  onChange={handleChange}
                  onKeyUp={handleCryptoInput}
                />

                <div
                  className="input-group-text bg-gray "
                  onClick={() => copyText(data.walletAddress)}
                >
                  <Dropdown onSelect={handleSelectChange}>
                    <Dropdown.Toggle size="sm" id="set-dd">
                      <img
                        src={
                          process.env.REACT_APP_API_NATURE_BASE_URL +
                          selected.img_url
                        }
                        height={20}
                      />{" "}
                      {selected.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {data.exchangeData.supported_fiat_currencies.length > 0 &&
                        data.exchangeData.supported_fiat_currencies.map(
                          (val, index) => (
                            <Dropdown.Item
                              eventKey={JSON.stringify(val)}
                              key={index}
                            >
                              {" "}
                              <img
                                src={
                                  process.env.REACT_APP_API_NATURE_BASE_URL +
                                  val.img_url
                                }
                                height={20}
                              />{" "}
                              {val.name}
                            </Dropdown.Item>
                          )
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <label for="inputEmail4" className="form-label label-red">
                Minimum Crypto Dinar to purchase is{" "}
                {data.exchangeData.minimum_token}
              </label>
            </div>
            <div className="col-12 d-flex align-items-center">
              <div className="yellow-warning">
                <div className="row p-2 my-2">
                  <div className="col-sm-1 col-xs-1 p-2 d-flex align-items-start justify-content-end">
                    <i
                      className="fa fa-info-circle warning-text"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="col-sm-11 col-xs-11 pe-2 ps-2 ">
                    <p className="m-0 warning-text ">
                      {data.exchangeData.fiat_screen_promotional_text}
                    </p>
                    <p className="m-0 warning-text">
                      {data.exchangeData.fiat_screen_footer_text
                        ? data.exchangeData.fiat_screen_footer_text
                        : "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illo deserunt sunt voluptatibus ab minus, eos non fugit itaque uas eum nisi magnam id! Rem, molestiae nam dolore delectus deserunt libero!"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button
                type="submit"
                className="btn btn-primary w-60 crypto-dinar-btn"
              >
                Buy Token
              </button>
            </div>
          </form>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default NFTFormFiat;
