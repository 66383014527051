import React from 'react';
import ProjectSocialMedia from './projects-in-socialmedia';

const ProjectListDesc = () => {
    return (
        <>
            <section className="commu py-5">
                <div className="row">
                    <div className="col-12 col-lg-6 ">
                        <div className="col-inner py-5 icon-content ms-lg-auto">
                            <h2 className="head-5">A global community</h2>
                            <p className="icon-content-inner">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lectus elit, sodales ut maximus vel, porttitor vitae diam. Fusce faucibus a libero vitae ornare. Aliquam erat volutpat. <br /><br />Cras dapibus pellentesque consectetur. Nunc sollicitudin egestas vulputate. Vivamus scelerisque, risus eu vulputate vehicula, urna turpis volutpat neque, eu faucibus ligula purus sed mauris. Ut laoreet odio odio, vitae feugiat tellus consectetur in. Mauris blandit eu ante non aliquet.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="icons d-flex justify-content-center align-items-center h-100">
                            <ProjectSocialMedia />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProjectListDesc;
