/* eslint-disable default-param-last */
import { KYC_TYPES } from "../redux-constant-types";

const intitialState = {
  startKYC: false,
  sumSubAccessToken: "",
  userKYCStatus: "", // kyc_status from api
  isUserKYCDone: null, // status from api
  rejectedType: "", // review_reject_type from api
  enableKyc: false, // enable_kyc from api
};

const kycReducer = (state = intitialState, action) => {
  switch (action.type) {
    case KYC_TYPES.SET_START_KYC:
      return {
        ...state,
        startKYC: action.payload.status,
      };
    case KYC_TYPES.SET_SUMSUB_ACCESS_TOKEN:
      return {
        ...state,
        sumSubAccessToken: action.payload.sumSubAccessToken,
      };
    case KYC_TYPES.SET_KYC_STATUS:
      return {
        ...state,
        userKYCStatus: action.payload.userKYCStatus,
        isUserKYCDone: action.payload.isUserKYCDone,
        rejectedType: action.payload.rejectedType,
        enableKyc: action.payload.enableKyc,
      };
    default:
      return state;
  }
};

export default kycReducer;
