/* eslint-disable default-param-last */
import { NFTS } from "../redux-constant-types";

const initialState = {
  masterData: null,
  energyMasterData: null,
  dashboard: null,
  offSet: null,
  energyDashboard: null,
  selectedCrypto: 1,
  assetRequestId: null,
  paymentId: null,
  assetRequest: null,
};

const nftReducer = (state = initialState, action) => {
  switch (action.type) {
    case NFTS.SET_MASTER_DATA:
      return {
        ...state,
        masterData: action.payload.masterData,
      };
    case NFTS.SET_ENERGY_MASTER_DATA:
      return {
        ...state,
        energyMasterData: action.payload.energyMasterData,
      };
    case NFTS.SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: action.payload.dashboard,
        offSet: action.payload.offSet,
      };
    case NFTS.SET_ENERGY_DASHBOARD_DATA:
      return {
        ...state,
        energyDashboard: action.payload.energyDashboard,
      };
    case NFTS.SET_SELECTED_CRYPTO:
      return {
        ...state,
        selectedCrypto: action.payload.selectedCrypto,
      };
    case NFTS.SET_ASSET_REQUEST:
      return {
        ...state,
        assetRequest: action.payload.assetRequest,
      };
    case NFTS.SET_PAYMENT_ID:
      return {
        ...state,
        assetRequestId: action.payload.assetRequestId,
        paymentId: action.payload.paymentId,
      };

    default:
      return state;
  }
};

export default nftReducer;