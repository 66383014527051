/* eslint-disable camelcase */
import URLS from "../../../configs/api-end-points";
import apiServices from "../data/api_service";

const sSOSignUp = async (values) => {
  const data = {
    username: values.username,
    password: values.password,
    name: values.name,
    //phone_number: values.mobile,
  };
  const url = URLS.SSO_SIGNUP;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOForgetPassword = async (values) => {
  const data = {
    username: values.username,
  };
  const url = URLS.SSO_FORGET_PASSWORD;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOConfirmForgetPassword = async (values) => {
  const url = URLS.SSO_CONFIRM_FORGET_PASSWORD;
  const data = {
    username: values.username,
    confirmation_code: values.confirmationCode,
    password: values.password,
  };
  const result = await apiServices.post(url, data);
  return result;
};

const sSOResendCode = async (values) => {
  const data = {
    username: values.username,
  };
  const url = URLS.SSO_RESEND_CODE;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOResendLink = async (values) => {
  const data = {
    username: values.username,
  };
  const url = URLS.SSO_RESEND_CODE;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOConfirmPassword = async (values) => {
  const data = {
    username: values.username,
    confirmation_code: values.confirmationCode,
  };

  const url = URLS.SSO_CONFIRM_PASSWORD;
  const result = await apiServices.post(url, data);
  return result;
};

const login = async (values) => {
  const data = {
    login: values.login,
    password: values.password,
  };
  const url = URLS.AUTH_LOGIN;
  const result = await apiServices.post(url, data);
  return result;
};

const multiLogin = async (values) => {
  const data = {
    login: values.login,
    password: values.password,
  };
  const url = URLS.AUTH_LOGIN;
  const result = await apiServices.post(url, data);
  return result;
};

const getUserDetails = async () => {
  const data = {};
  const url = URLS.USER_DETAILS;
  const result = await apiServices.post(url, data);
  return result;
};

const redemptionOrderGenerateOtp = async (email, redeem_id) => {
  const data = {
    email: email,
    redemption_order: redeem_id,
  };
  const url = URLS.REDEMPTION_ORDER_GENERATE_OTP;
  const result = await apiServices.post(url, data);
  return result;
};
const redemptionOrderSubmitOtp = async (values) => {
  const data = {
    email: values.username,
    redemption_order: values.redeem_id,
    otp: values.confirmationCode,
  };
  const url = URLS.REDEMPTION_ORDER_SUBMIT_OTP;
  const result = await apiServices.post(url, data);
  return result;
};
const redemptionOrderAppoinmentDate = async (values) => {
  let d = values.appointment_date;
  let date    = d.getDate();
  let month   = d.getMonth();
  let year    = d.getFullYear();
  let time    = d.getTime();
  let dateString = year + "-" +(month + 1) + "-" + date;
  //appointment_date = new Date('Y-m-d h:m', appointment_date);
  console.log("appointment_date: ", dateString)
  console.log("time: ", time)
  /*const data = {
    redemption_id: values.redemption_id,
    appointment_date: values.appointment_date,
  };
  const url = URLS.REDEMPTION_ORDER_APPOINTMENT_OTP;
  const result = await apiServices.post(url, data);
  return result;*/
};

export {
  sSOSignUp,
  sSOForgetPassword,
  sSOConfirmForgetPassword,
  sSOResendCode,
  sSOResendLink,
  sSOConfirmPassword,
  login,
  multiLogin,
  getUserDetails,
  redemptionOrderGenerateOtp,
  redemptionOrderSubmitOtp,
  redemptionOrderAppoinmentDate,
};
