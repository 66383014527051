/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import AboutBanner from "../../../assets/images/about-banner.png";

const WhyUs = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL; 
  return (
    <>
      {bannerSections &&
        <section className="smart-section work-section">
          <div className="container">
              <div className="title-head text-center">
                  {ReactHtmlParser(bannerSections.title)}
                  {ReactHtmlParser(bannerSections.banner_text)}
              </div>
              <div className="flex grid-3">
                {bannerSections &&
                  bannerSections.banner_image.map((bannerImage, banner_key) => {
                  return (
                  <div className="col" key={banner_key}>
                      <div className="column">
                          <span>{bannerImage.image_type}</span>
                          {ReactHtmlParser(bannerImage.heading)}
                          {ReactHtmlParser(bannerImage.text)}
                      </div>
                  </div>
                  );
                })}
              </div>
          </div>
        </section>
      }
    </>
  );
};

export default WhyUs;
