import React from "react";
import behanceIcon from "../../assets/images/project-detail/behance.png";
import discordIcon from "../../assets/images/project-detail/discord.png";
import facebookIcon from "../../assets/images/project-detail/facebook.png";
import googleIcon from "../../assets/images/project-detail/google.png";
import redditIcon from "../../assets/images/project-detail/reddit.png";
import twitterIcon from "../../assets/images/project-detail/twitter.png";
import whatsappIcon from "../../assets/images/project-detail/whatsapp.png";

const ProjectSocialMedia = () => {
  return (
    <>
      <div className="row w-100">
        <div className="col icon-col-1">
          <div className="firstcol">
            <img src={behanceIcon} />
            <img src={discordIcon} />
          </div>
        </div>
        <div className="col">
          <img src={facebookIcon} />
          <img src={googleIcon} />
          <img src={redditIcon} />
        </div>
        <div className="col">
          <div className="seccol">
            <img src={whatsappIcon} />
            <img src={twitterIcon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSocialMedia;
