const BANNER_TYPES = {
  IL_CR: "il_cr",
  IR_CL: "ir_cl",
  I_W_C: "i_w_c",
  TEXT: "text",
  IMAGE_1_2_3: "image_1_2_3",
  IMAGE: "image",
  IL_CR_WIDGET: "il_cr_widget",
  IR_CL_WIDGET: "ir_cl_widget",
  IMAGE_CONTENT_WITH_WIDGET: "image_content_with_widget",
  IMAGE_LEFT_RIGHT_WITH_CONTENT: "image_left_right_with_content",
  IL_CR_WIDGET_CONTENT_IMAGE: "il_cr_widget_content_image",
  IR_CL_WIDGET_CONTENT_IMAGE: "ir_cl_widget_content_image",
  IMAGE_2_2: "image_2_2",
  IMAGE_RIGHT_CONTENT_LEFT_WIDGET_ON_CONTENT:
    "ir_cl_with_widget_on_i_c_and_c_with_image",
  INVESTORS: "investors",
  IMAGE_LEFT_CONTENT_RIGHT_WIDGET_ON_CONTENT:
    "il_cr_with_widget_on_i_c_and_c_with_image",
  PROJECT_TOKEN: "token",
  ECOSYSTEM: "Ecosystem",
  OPERATION: "OPERATION",
};

export { BANNER_TYPES };
