import React, { useState, useEffect } from "react";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import leafImg from "../../assets/images/leaf.png";
import ConfirmPasswordForm from "../../components/shared/forms/confirm-password-form";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/DcommLogo.png";

const VerifyOtp = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/login");
  };
  if (isLoggedIn) {
    navigate("/dashboard");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Verify Otp";
  }, []);

  return (
    <div className="login-page">
      <div className="flex-rows">
          <div className="column-img">
            <div className="logo">
                <Link to={'/'}><img src={Logo} title="" alt="" /></Link>
            </div>
          </div>
          <ConfirmPasswordForm />          
      </div>
    </div>
  );
};

export default VerifyOtp;
