import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPageNumber } from "../../redux/actions/pagination-action";
const Pagination = ({ callback, totalCount, totalShow, children }) => {
  const dispatch = useDispatch();
  const { pageNumber } = useSelector((state) => state.paginationReducer);
  const [limit, setLimit] = useState(15);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    let pageLength = totalCount / limit;
    if (totalCount % limit > 0) pageLength += 1;
    const pages = [];
    for (let i = 1; i <= pageLength; i++) {
      pages.push(i);
    }
    setPages(pages);
    let offset = pageNumber * limit;
    offset = totalCount - offset;
    let count = 0;
    if (offset < 0) {
      count = offset;
      offset = 0;
    }
    callback(pageNumber);
  }, [pageNumber, totalCount]);

  const onChangePage = (page) => {
    dispatch(setPageNumber(page));
  };
  return (
    <>
      {children}
      {totalShow > 0 &&
      <div className="row pagination-section mt-lg-n2 pt-lg-2 mx-lg-1 my-4">
        <div className="col-xl-3 col-lg-3">
          <p className="font-14px">
            Showing {totalShow} of {totalCount} records
          </p>
        </div>
        <div className="col-xl-9 col-lg-9">
          <nav className="float-right">
            <ul className="pagination">
              <li
                className={
                  pageNumber === 1 ? "page-item  d-none" : "page-item "
                }
              >
                <span
                  className="page-link arrow text-center"
                  onClick={() => onChangePage(pageNumber - 1)}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
              </li>

              {pages.map((page, index) => {
                return (
                  <li
                    key={index}
                    className={
                      pageNumber === page ? "page-item  active" : "page-item "
                    }
                  >
                    <span
                      className="page-link"
                      onClick={() => onChangePage(page)}
                    >
                      {page}
                    </span>
                  </li>
                );
              })}

              <li
                className={
                  pageNumber === pages.length
                    ? "page-item  d-none"
                    : "page-item "
                }
              >
                <span
                  className="page-link arrow"
                  onClick={() => onChangePage(pageNumber + 1)}
                >
                  <i className="fas fa-chevron-right"></i>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      }
    </>
  );
};

export default Pagination;
