import React, { useEffect } from "react";
import ScanIcon from "../../assets/images/scan-icon.png";
import Modal from "react-bootstrap/Modal";
import WalletScanPopup from "./wallet-scan-popup";
import useForm from "./../../hooks/use-form";
import {
  validateConfirmWallet,
  checkERC20,
} from "../../core/validations/user-validations";
import { getWalletAddress, setWalletAddress } from "../../core/utils/token";
import {
  updateEnergyWalletAddress,
  updateWalletAddress,
} from "../../core/services/api/nft";
import metamaskService from "../../core/services/smart-contracts/metamask";
import {
  setUserWalletAddress,
  setIsWalletConnected,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import useAlert from "./../../hooks/use-alert";
const WalletAddressFormNature = () => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const { walletAddress, isWalletConnected, isMetamaskInstalled, userDetails } =
    useSelector((state) => state.userReducer);
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const handleConnectWithWallet = async () => {
    if (!isMetamaskInstalled) {
      alert("Please Install Metamask to use this service");
      return;
    }
    const result = await metamaskService.connectWithWallet();
    if (result && !result.error) {
      walletUpdate(result.accountAddress);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      alert(result.message);
    }
  };
  const walletUpdate = async (val) => {
    const value = values.wallet ? values.wallet : val;
    const res = await checkERC20(value);
    if (res.status == true) {
      const data = {
        walletAddress: value,
      };
      const result = await updateEnergyWalletAddress(data);
      if (result.status) {
        dispatch(setUserWalletAddress(value));
        dispatch(setIsWalletConnected(true));
        setWalletAddress(value);
        showAlert("SUCCESS", "Success", "Wallet Address Updated");
      }
    } else {
      setErrors((preValues) => ({
        ...preValues,
        confirmWallet: res.message,
      }));
    }
  };
  const getInfo = (data1, data2) => {
    setValues({
      wallet: data1,
      confirmWallet: data2,
    });
    walletUpdate();
  };
  const { values, errors, handleSubmit, handleChange, setErrors, setValues } =
    useForm(walletUpdate, validateConfirmWallet);
  useEffect(() => {
    const address = getWalletAddress();
    setValues({
      wallet: address ? address : walletAddress,
    });
  }, []);

  return (
    <>
      <div
        className="tab-pane fade new-radius pb-4"
        id="energynft"
        role="tabpanel"
        aria-labelledby="energynft-tab"
      >
        <div className="wallet-form-background">
          <div className="wallet-form py-4 px-4">
            <div className="kyc-form-head mx-1 my-4  row">
              <div className="col">
                {" "}
                <h2 className="kyc-pi ">Confirm your Energy wallet address</h2>
              </div>
              {/* {walletAddress && isWalletConnected == true ? (
            ""
          ) : (
            <div className="col">
              <button
                className="sunset-yellow-bg border-0 px-2 py-1 border-radidus-4"
                onClick={handleConnectWithWallet}
              >
                Connect Wallet
              </button>
            </div>
          )} */}
            </div>
            <form className="row g-3 mb-4">
              <div className="col-md-12 my-3">
                <label for="inputEmail4" className="form-label label-gr">
                  Wallet Addrress
                </label>
                <input
                  type="text"
                  className="form-control bg-gr"
                  id="inputEmail4"
                  placeholder="Wallet Addrress"
                  name="wallet"
                  onChange={handleChange}
                  value={values.wallet === undefined ? "" : values.wallet}
                />
                {errors.wallet && (
                  <p className="text-danger">
                    <small>{errors.wallet}</small>
                  </p>
                )}
              </div>
            </form>
            <div className="col-md-12 my-3">
              <label for="inputEmail4" className="form-label label-gr">
                Confirm Wallet Addrress
              </label>
              <input
                type="text"
                className="form-control bg-gr"
                id="inputEmail4"
                placeholder="Confirm Wallet Addrress"
                name="confirmWallet"
                onChange={handleChange}
                value={
                  values.confirmWallet === undefined ? "" : values.confirmWallet
                }
              />
              {errors.confirmWallet && (
                <p className="text-danger">
                  <small>{errors.confirmWallet}</small>
                </p>
              )}
            </div>
            <div className="col-md-12 my-3 d-grid">
              <button
                onClick={handleSubmit}
                className="btn btn-primary mt-4 f-bold kyc-sb no-bdr"
              >
                Update
              </button>
            </div>
            <div className="col-md-12 my-3 d-grid">
              <button
                className=" btn btn-primary mt-4 f-bold kyc-sb-2 no-bdr"
                onClick={handleConnectWithWallet}
              >
                Connect Wallet
              </button>
            </div>
            <div className="col-md-12 my-1 d-grid">
              <a
                className="btn btn-primary mt-4 f-bold ad-sc no-bdr d-flex align-items-center justify-content-center popup-with-zoom-anim"
                onClick={showModal}
              >
                <img src={ScanIcon} alt="" srcset="" className="me-2 mt-1" />{" "}
                Scan your address
              </a>
            </div>
          </div>
        </div>
      </div>

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <WalletScanPopup hide={hideModal} data={getInfo} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletAddressFormNature;
