/* eslint-disable nonblock-statement-body-position */
import React, { useState } from "react";
import { sSOResendLink, sSOSignUp } from "../../../core/services/api/user";
import { validateUserSignup } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserName } from "../../../redux/actions";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import ConfirmPasswordForm from "./confirm-password-form";
import useAlert from "./../../../hooks/use-alert";

const UserRegistrationForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [verifyRegistrationCode, setVerifyRegistrationCode] = useState(false);
  const { showAlert } = useAlert();
  const [showValues, setShowValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });

  const handleSignUpUser = async () => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (values.username !== '' && re.test(values.username)) {
      setShowLoader(true);
      const result = await sSOSignUp(values);
      if (result && result.status) {
        showAlert("SUCCESS", "Success", result.message);
        const data = {
          username: values.username,
        };
        dispatch(setUserName(values));
        //dispatch(setUserDetails(data));
        //setVerifyRegistrationCode(true);
        navigate("/verify-otp");
        setPageSuccess(true);
      } else {
        showAlert("ERROR", "Error", result.message);
        setShowMessage(true);
      }
      setShowLoader(false);
    }else{
      setValidateEmail(true);
    }
  };

  const { setValues, values, errors, handleSubmit, handleChange } = useForm(
    handleSignUpUser,
    validateUserSignup
  );

  if (verifyRegistrationCode)
    return (
      <ConfirmPasswordForm
        setVerifyRegistrationCode={setVerifyRegistrationCode}
        data={values}
      />
    );

  const handleClickShowPassword = (num) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    } else {
      setShowValues({
        ...showValues,
        showPassword2: !showValues.showPassword2,
      });
    }
  };

  const handleMobileChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValues((preValues) => ({
        ...preValues,
        mobile: e.target.value,
      }));
    }
  };

  const handleName = (e) => {
    const re = /^[^*|\":<>[\]{}`\\()'!#%^_+,./~?;@&$]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValues((preValues) => ({
        ...preValues,
        name: e.target.value,
      }));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="log-box">
        <div className="row">
            <div className="col-lg-12">
                <div className="form-group">
                  <input
                    type="text"
                    autoFocus
                    name="name"
                    value={values.name === undefined ? "" : values.name}
                    className="form-control"
                    onChange={handleName}
                    placeholder="Name"
                    maxLength={50}
                  />
                  {errors.name && (
                    <p className="text-danger">
                      <small>{errors.name}</small>
                    </p>
                  )}
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                  <input
                    type="email"
                    name="username"
                    value={values.username === undefined ? "" : values.username}
                    className="form-control"
                    onChange={handleChange}
                    placeholder="Email Address"
                  />
                  {errors.username && (
                    <p className="text-danger">
                      <small>{errors.username}</small>
                    </p>
                  )}
                  {validateEmail && (
                    <p className="text-danger">
                      <small>Invalid email format!</small>
                    </p>
                  )}
                </div>
            </div>
            {/*<div className="col-lg-12">
                <div className="form-group">
                    <input
                      type="text"
                      name="mobile"
                      min="1"
                      value={values.mobile === undefined ? "" : values.mobile}
                      className="form-control"
                      onChange={handleMobileChange}
                      placeholder="Mobile Number"
                      maxLength={13}
                    />
                  {errors.mobile && (
                    <p className="text-danger">
                      <small>{errors.mobile}</small>
                    </p>
                  )}
                </div>
            </div>*/}
            <div className="col-lg-12">
                <div className="form-group">
                    <div className="input-group showhide-pass">
                        <input
                          type={showValues.showPassword1 ? "text" : "password"}
                          className="form-control"
                          name="password"
                          value={values.password === undefined ? "" : values.password}
                          onChange={handleChange}
                          placeholder="Password"
                          maxLength={30}
                        />
                        <span className="input-group-text input-icon" onClick={() => handleClickShowPassword(1)} >
                          {showValues.showPassword1 ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="far fa-eye"></i>
                          )}
                        </span>
                    </div>
                      {errors.password && (
                        <p className="text-danger">
                          <small>{errors.password}</small>
                        </p>
                      )}
                </div>
            </div>
            <div className="col-lg-12">
                <div className="form-group">
                    <div className="input-group showhide-pass">
                      <input
                        type={showValues.showPassword2 ? "text" : "password"}
                        className="form-control sunset-input border-radidus-8 p-lg-3 p-sm-3"
                        name="confirmPassword"
                        value={
                          values.confirmPassword === undefined ? "" : values.confirmPassword
                        }
                        onChange={handleChange}
                        placeholder="Confirm Password"
                        maxLength={30}
                      />
                      <span className="input-group-text input-icon" onClick={() => handleClickShowPassword(2)} >
                        {showValues.showPassword2 ? (
                          <i className="fa fa-eye-slash"></i>
                        ) : (
                          <i className="fa fa-eye"></i>
                        )}
                      </span>
                    </div>
                      {errors.confirmPassword && (
                        <p className="text-danger">
                          <small>{errors.confirmPassword}</small>
                        </p>
                      )}
                </div>
            </div>
        </div>
        <div className="form-group pt-10">
            <button type="submit" className="buy-coin-btn w-100">Register</button>
        </div>
    </div>
    </form>
  );
};

export default UserRegistrationForm;
