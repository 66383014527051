import React, { useState, useEffect } from "react";
import DashboardBanner from "./dashboard-banner";
import DasboardHeading from "./dashboard-heading";
import NatureDasboardSummary from "./naturenft-dashboard-summary";
import NatureNFTGraph from "./nature-nft-graph";
import NatureNFTTable from "./nature-nft-table";
import { getUserId, getAccessToken } from "../../core/utils/token";
import { dashboardAPI } from "../../core/services/api/nft";
import DasboardOrderHistory from "./dashboard-order-history";
import { useDispatch } from "react-redux";
import { setDashboardData } from "../../redux/actions/nft-action";

const NatureTabDashboard = () => {
  const dispatch = useDispatch();
  const [dashboard, setDashboard] = useState();
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    const res = await dashboardAPI();
    if (res.status) {
      setDashboard(res);
      const value = {
        dashboard: res.dashboard_data,
        offSet: res.utc_offset,
      };
      dispatch(setDashboardData(value));
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading == false && (
        <>
          <div
            className="tab-pane fade show active new-radius pb-4 "
            id="naturenft"
            role="tabpanel"
            aria-labelledby="naturenft-tab"
          >
            {/* <DashboardBanner type="nature" /> */}
            {/* <DasboardHeading tabs="no-tab" tabSwitcher="no" /> */}
            <div className="pt-5">
              <NatureDasboardSummary />
            </div>
            <div className="dashboard-graph my-5 mx-1 mx-md-5 ">
              <div className="tab-content" id="pills-tabContent">
                <NatureNFTGraph data={dashboard.dashboard_data} />
                {/* <NatureNFTTable /> */}
              </div>
            </div>
            <div className="my-5">
              <DasboardOrderHistory data={dashboard.dashboard_data} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NatureTabDashboard;
