import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/sidebar.css";
import DashboardActive from "../../assets/images/sidebar/dashboard-active.svg";
import Dashboard from "../../assets/images/sidebar/dashboard.svg";
import Profile from "../../assets/images/sidebar/profileicon.svg";
import ProfileActive from "../../assets/images/sidebar/profileicon.svg";
import Kyc from "../../assets/images/sidebar/KYC1.svg";
import KycActive from "../../assets/images/sidebar/kyc-active.svg";
import Buy from "../../assets/images/sidebar/buy.svg";
import Token from "../../assets/images/sidebar/token.png";
import BuyActive from "../../assets/images/sidebar/buy-active.svg";
import Wallet from "../../assets/images/sidebar/wallet.svg";
import WalletActive from "../../assets/images/sidebar/wallet-active.svg";
import Report from "../../assets/images/sidebar/report.svg";
import ReportActive from "../../assets/images/sidebar/report-active.svg";
import SidebarComapnyLogo from "../../assets/images/sidebar/logo.svg";
import DfenderLogo from "../../assets/images/sidebar/dcom-dfender.jpg";
import THCLogo from "../../assets/images/sidebar/thc.png";
import Default from "../../assets/images/sidebar/kyc-icon.svg";
import Order from "../../assets/images/sidebar/order.svg";
import FAQ from "../../assets/images/sidebar/FAQ_ICON.svg";
import Dfender from "../../assets/images/sidebar/dfender.svg";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

const SideBar = () => {
  const [active, setActive] = useState(window.location.pathname);
  const { enableKyc } = useSelector((state) => state.kycReducer);
  return (
    <div className="side-bar-main text-center h-100 ">
      <div className="card h-100">
        <div className="card-body p-0">
          <nav className="nav flex-column mt-lg-3">
            <Row>
              <Col md={6} className="m-auto ml-3 text-right ">
                <img src={SidebarComapnyLogo} alt="" />
              </Col>
              {/* <Col md={6} className="m-auto">
                <img src={THCLogo} height={70} width={70} alt="" />
              </Col> */}
            </Row>
            <h6 className="side-headers text-white text-start mb-0 mt-4">
              Setup Profile
            </h6>
            <NavLink
              className={`sub-headers nav-link font-5F738C${
                active == "/update/user-profile" ? " active" : ""
              }`}
              activeclassname="active"
              to="/user-profile">
              {active == "/user-profile" || active == "/update/user-profile" ? (
                <img src={ProfileActive} />
              ) : (
                <img src={Profile} />
              )}{" "}
              <small className="font-14">Profile</small>
            </NavLink>

            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/kyc-verification">
              {active == "/kyc-verification" ? (
                <img src={Kyc} />
              ) : (
                <img src={Kyc} />
              )}{" "}
              <small className="font-14">Step 1 - Complete KYC</small>
            </NavLink>

            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/wallet-address">
              {active == "/wallet-address" ? (
                <img src={Wallet} />
              ) : (
                <img src={Wallet} />
              )}{" "}
              <small className="font-14">Step 2 - Setup Wallet</small>
            </NavLink>

            <h6 className="side-headers text-white text-start mb-0 mt-4">
              BUY HCT
            </h6>
            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/club">
              {active == "/club" ? <img src={Buy} /> : <img src={Buy} />}{" "}
              <small className="font-14">Step 3 - Buy HCT</small>
            </NavLink>
            {/* <NavLink
              className="nav-link font-5F738C"
              activeclassname="active"
              to="/dfender">
              {active == "/dfender" ? (
                <img src={Dfender} />
              ) : (
                <img src={Dfender} />
              )}{" "}
              <small className="font-14">Step 4 - Setup DFender</small>
            </NavLink> */}
            {/* <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/orders">
              {active == "/orders" ? <img src={Order} /> : <img src={Order} />}{" "}
              <small className="font-14">View Orders</small>
            </NavLink>
            {/* <NavLink
              className="nav-link font-5F738C"
            </NavLink> */}

            {/* <h6 className="side-headers text-white text-start mb-0 mt-4">Buy 100 Club Ticket</h6>
            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/club">
              {active == "/club" ? (
                <img src={Buy} />
              ) : (
                <img src={Buy} />
              )}{" "}
              <small className="font-14">Buy 100 Club Tickets</small>
            </NavLink> */}
            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/orders">
              {active == "/orders" ? <img src={Order} /> : <img src={Order} />}{" "}
              <small className="font-14">View Orders</small>
            </NavLink>

            {/* <h6 className="side-headers text-white text-start mb-0 mt-4">
              My Holdings
            </h6>
            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/holdings">
              {active == "/holdings" ? (
                <img
                  style={{ background: "white", borderRadius: "100%" }}
                  src={Token}
                />
              ) : (
                <img
                  style={{ background: "white", borderRadius: "100%" }}
                  src={Token}
                />
              )}{" "}
              <small className="font-14">My Holdings</small>
            </NavLink> */}

            <h6 className="side-headers text-white text-start mb-0 mt-4">
              Guide
            </h6>
            <NavLink
              className="sub-headers nav-link font-5F738C"
              activeclassname="active"
              to="/faq">
              {active == "/faq" ? <img src={FAQ} /> : <img src={FAQ} />}{" "}
              <small className="font-14">How to Guide</small>
            </NavLink>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
