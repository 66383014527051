import React from 'react';
import { NavLink } from "react-router-dom";
import sunsetNatureLogo from "../../assets/images/sunset_nature_logo.png";

const LeftMenu = () => {
    return (
        <>
            <div className="col-sm-auto bg-light sticky-top d-none d-lg-block p-0 w-auto" id="navbarSupportedContent">
                <div className="d-flex flex-sm-column flex-column flex-nowrap bg-light align-items-center">
                    <ul
                        className="nav nav-pills nav-flush flex-sm-column flex-column flex-nowrap mb-auto mx-auto text-center align-items-center">
                        <li className="nav-item nav-selected w-100">
                            <NavLink to="/dashboard"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-tachometer"></i>
                                <p className="sidebar-text m-0">Dashboard</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="#"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-user-o"></i>
                                <p className="sidebar-text m-0">Profile</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="#"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-vcard-o"></i>
                                <p className="sidebar-text m-0">KYC Verification</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="#"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-credit-card"></i>
                                <p className="sidebar-text m-0">Wallet</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="/naturenft"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-file-photo-o"></i>
                                <p className="sidebar-text m-0">Nature NFT</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="/energynft"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-file-pdf-o"></i>
                                <p className="sidebar-text m-0">Energy NFT</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="/reports"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-tv"></i>
                                <p className="sidebar-text m-0">Reports</p>
                            </NavLink>
                        </li>
                        <li className="nav-item w-100">
                            <NavLink to="#"
                                className="nav-link d-block p-3 link-dark text-decoration-none text-center sidebar-item"
                                title="">
                                <i className="fa fa-cog"></i>
                                <p className="sidebar-text m-0">Settings</p>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default LeftMenu;
