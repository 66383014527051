/* eslint-disable new-cap */
import React from "react";
import "../../assets/css/count-entry-card.css";
import URLS from "../../configs/api-end-points";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import ReactHtmlParser from "react-html-parser";

const ImageOnly = ({ widgets }) => {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }
  return (
    <div className="card-body text-center">
      <div className="row">
        {widgets &&
          widgets.map((widget, index) => {
            return (
              <>
                {widget.image_url ? (
                  <div className="col-md-4 col-sm-4 " key={index}>
                    <img src={`${PATH}${widget.image_url}`} className="w-100" />
                  </div>
                ) : (
                  <div className="col-4 col-sm-4" key={index}>
                    {ReactHtmlParser(widget.text)}
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export default ImageOnly;
