import React, { useEffect, useState } from "react";
import "../../assets/css/redemption.css";
import { getTokenDetail } from "../../core/services/api/nft";
import LaunchIcon from "@mui/icons-material/Launch";

const TrancheTableData = ({ allTokenDetails }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    const res = await getTokenDetail();

    if (res.status) {
      setIsLoading(false);
      setData(res.vesting_details[0].token_details);
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {allTokenDetails && (
          <div className="table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Transaction Name</th>
                  <th>Date</th>
                  <th>Tokens to Transfer</th>
                  <th>Token Percentage</th>
                  <th>State</th>
                  <th>Blockchain Txn</th>
                </tr>
              </thead>
              <tbody>
                {allTokenDetails && allTokenDetails.length > 0 ? (
                  <>
                    {allTokenDetails.map((val, index) => (
                      <tr key={index}>
                        <td>
                          {val.trans_name}
                        </td>
                        <td>{val.tranche_schedule_date}</td>
                        <td>
                          {val.total_no_of_tokens_to_transfer}
                        </td>
                        <td>
                          {val.token_percentage}
                        </td>
                        <td>
                          {val.state}
                        </td>
                        <td>
                          {
                            val.explorer_url &&
                            <a
                              onClick={() => {
                                window.open(val.explorer_url);
                              }}>
                              <button className="holdingBtn">
                                View
                              </button>
                            </a>
                          }
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="7">No records found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
      )}
    </>
  );
};

export default TrancheTableData;
