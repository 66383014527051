/* eslint-disable new-cap */
/* eslint-disable camelcase */
import React from 'react';
import ImageCard from './image-card';
import ReactHtmlParser from 'react-html-parser';
import URLS from '../../configs/api-end-points';
import { PROJECT_TYPES } from '../../utils/constants/project-types';
import { useLocation } from 'react-router';


const Image123BannerCard = ({ bannerSection, classes }) => {
    const { banner_image, title } = bannerSection;
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <div className={classes}>
            <div>
            {ReactHtmlParser(title)}
            </div>

            <div className="row">
                <div className="col-12 col-sm-12 col-md-8">
                    {/* <img src={cnbcLogo} className="position-absolute" style={{ margin: "1% 0% 0% 51%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[0].image_url}`}/>
                    <div className="card news-headlines">
                        <div className="card-body font-ffffff">
                            {ReactHtmlParser(banner_image[0].text)}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4 row d-none d-md-block">
                    <div className="col-6 col-sm-6 col-md-12 mt-md-2 mb-md-4">
                        {/* <img src={dubaiMedia} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                        <ImageCard image={`${PATH}${banner_image[1].image_url}`} />
                    </div>
                    <div className="col-6 col-sm-6 col-md-12">
                    {/* <img src={cnbcLogo} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[2].image_url}`} />
                    </div>
                </div>
            </div>

            <div className="row mt-3 d-md-none">
                <div className="col-6 col-sm-6 ">
                    {/* <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[1].image_url}`} />
                </div>
                <div className="col-6 col-sm-6">
                    {/* <img src={dubaiMedia} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[4].image_url}`} />
                </div>
                <div className="col-0 col-sm-0 d-none d-sm-none">
                    {/* <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[2].image_url}`} />
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6 col-sm-6 col-md-4">
                    {/* <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[3].image_url}`} />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                    {/* <img src={dubaiMedia} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[4].image_url}`} />
                </div>
                <div className="col-md-4 d-none d-md-block">
                    {/* <img src={dubaiTV} className="position-absolute" style={{ margin: "1% 0% 0% 23%" }} /> */}
                    <ImageCard image={`${PATH}${banner_image[5].image_url}`} />
                </div>
            </div>
                </div>
    );
};

export default Image123BannerCard;
