import React, { useEffect, useState } from "react";
import { getRedemptionOrderList } from "../../core/services/api/nft";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../core/utils/pagination";
import Modal from "react-bootstrap/Modal";
import AssetRequestDetail from "./../shared/asset-request-detail-popup";
import paginationSubmit from "./../../hooks/pagination";
import { extendTime, formatDateTime } from "../../core/utils/time";
import Check from "../../assets/images/check.jpg";
import "../../assets/css/redemption.css";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { MESSAGE_VARIANT } from "../../redux/redux-constant-types";
import useForm from "../../hooks/use-form";
import { redemptionOrderGenerateOtp } from "../../core/services/api/user";
import useAlert from "../../hooks/use-alert";
import { setSuccessMessage, setErrorMessage } from "../../redux/actions";
import VerifyOrderEmail from "../shared/verify-orderemail-popup";
import AppointmentDatePopup from "../shared/appointment-date-popup";
const RedemptionOrderTableData = () => {
  const { selectedCrypto, assetRequest, offSet } = useSelector(
    (state) => state.nftReducer
  );
  const { startDate, endDate, currentPage, pageNumber } = useSelector(
    (state) => state.paginationReducer
  );
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [assetRequestId, setAssetRequestId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isEscrowModal, setEscrowModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [redeemId, setRedeemId] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const { showAlert } = useAlert();
  const getData = async () => {
    const data = {
      page_no: pageNumber,
    };
    const res = await getRedemptionOrderList(data);

    if (res.status) {
      setData(res.order_list);
      setTotalCount(res.total_records);
    }
  };
  const showModal = (e, redeem_id, order_id) => {
    e.preventDefault();
    setAssetRequestId(e);
    setOrderNumber(order_id);
    setRedeemId(redeem_id);
    let email = localStorage.getItem("user_email");
    getRedemptionOrderGenerateOtp(email, redeem_id);
  };
  const getRedemptionOrderGenerateOtp = async (email, redeem_id) => {
    const result = await redemptionOrderGenerateOtp(email, redeem_id);
    if (result && result.success) {
      dispatch(setSuccessMessage(result.message));
      setShowMessage(true);
      setIsOpen(true);
    } else {
      dispatch(setErrorMessage(result.message));
      setShowMessage(true);
      showAlert("ERROR", "Error", result.message);
    }
  };
  const showEscrowModal = async (e, redeem_id, order_id) => {
    e.preventDefault();
    setOrderNumber(order_id);
    setRedeemId(redeem_id);
    setEscrowModal(true);
  };
  const dateOffSet = (val) => {
    if (offSet !== undefined) {
      const extendedTime = extendTime(val, offSet);
      const formatedDate = formatDateTime(extendedTime);
      return formatedDate;
    }
  };
  const hideModal = () => {
    setValues((preValues) => ({
      ...preValues,
      confirmationCode: "",
    }));
    setIsOpen(false);
  };
  const hideEscrowModal = () => {
    setEscrowModal(false);
  };
  const shortVault = (vault_name) => {
    if (vault_name.length > 20) {
      return vault_name.substring(0, 20) + "...";
    } else {
      return vault_name;
    }
  };
  const handleChange = (code) => {
    setValues((preValues) => ({
      ...preValues,
      confirmationCode: code,
    }));
  };
  const { values, setValues, errors, handleSubmit } = useForm();
  useEffect(() => {
    if (assetRequest === null) {
      getData();
    } else {
      setData(assetRequest.asset_request_list);
      setTotalCount(assetRequest.total_records);
    }
  }, [selectedCrypto, assetRequest, currentPage]);
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      username: localStorage.getItem("user_email"),
    }));
  }, []);

  return (
    <>
      {data && offSet !== undefined && (
        <Pagination
          callback={(e) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            assetRequest === null
              ? getData()
              : paginationSubmit(
                  startDate,
                  endDate,
                  currentPage,
                  pageNumber,
                  selectedCrypto,
                  dispatch
                )
          }
          totalCount={totalCount}
          totalShow={data.length}>
          <div className="table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Order No.</th>
                  <th>Qty.</th>
                  <th>Weight</th>
                  <th>Vault</th>
                  <th>Appointment Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  <>
                    {data.map((val, index) => (
                      <tr key={index}>
                        <td>
                          {/*<img src={Check} alt="" />*/}
                          {val.order_id}
                          <span>{val.order_date}</span>
                        </td>
                        <td>{val.quantity}</td>
                        <td>
                          {val.weight}
                          <span>{val.uom_name}</span>
                        </td>
                        <td title={val.vault_name}>
                          {shortVault(val.vault_name)}
                        </td>
                        <td>
                          {val.appointment_date !== "False"
                            ? val.appointment_date
                            : ""}
                        </td>
                        <td>
                          <Link
                            to="#"
                            onClick={(e) => e.preventDefault()}
                            className={`btan ${val.status.toLowerCase()}`}>
                            {val.status}
                          </Link>
                        </td>
                        <td>
                          {val.status === "confirmed" && (
                            <Link
                              to="#"
                              className="btan action"
                              onClick={(e) =>
                                showModal(e, val.id, val.order_id)
                              }>
                              Verify
                            </Link>
                          )}
                          {val.status === "escrow" && (
                            <Link
                              to="#"
                              className="btan action"
                              onClick={(e) =>
                                showEscrowModal(e, val.id, val.order_id)
                              }>
                              Appoint Date
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="7">No records found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Pagination>
      )}
      <VerifyOrderEmail
        data={values}
        isOpen={isOpen}
        orderNumber={orderNumber}
        showMessage={showMessage}
        message={message}
        messageType={messageType}
        hide={hideModal}
        redeemId={redeemId}
      />
      <AppointmentDatePopup
        data={values}
        isEscrowModal={isEscrowModal}
        orderNumber={orderNumber}
        showMessage={showMessage}
        message={message}
        messageType={messageType}
        hide={hideEscrowModal}
        redeemId={redeemId}
      />
    </>
  );
};

export default RedemptionOrderTableData;
