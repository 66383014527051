/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import "../../assets/css/banner.css";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import ImageLeftContentRightBannerCard from "../modals/image-left-content-right-banner-card";
import Bgimg from "../../assets/images/banner.png";

const Banner = ({ banners }) => {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }

  return (
    <>
      {banners && banners.length > 0 ? (
        <>
          {banners[0].page ? (
            <>
              <ImageLeftContentRightBannerCard
                bannerSection={banners[0]}
                classes="mt-5"
              />
            </>
          ) : (
            <>

              <section className="banner-section" style={{ backgroundImage: `url(${Bgimg})` }}>
                 <div className="container">
                    <div className="flex">
                       <div className="col-left">
                          {ReactHtmlParser(banners[0].title)}
                          {ReactHtmlParser(banners[0].banner_text)}                                             
                       </div>
                       <div className="col-right">
                        <img
                          src={
                            banners && banners.length > 0
                              ? `${PATH}${banners[0].image_url}`
                              : ""
                          }
                        />
                       </div>
                    </div>
                 </div>
              </section>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Banner;
