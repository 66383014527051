import React, { useEffect, useState } from "react";
import "../../assets/css/header.css";
import { NavLink } from "react-router-dom";
import CryptoDinarLogo from "../../assets/images/cryptoDinarLogo.svg";
import Profile from "./profile";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporatePageData,
  getPageTypeData,
} from "../../core/services/api/home";
import { setHeaderListData } from "../../redux/actions/page-action";

const Header = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const { headerList } = useSelector((state) => state.pageReducer);
  const [isFetching, setIsFetching] = useState(true);
  const [showMenuDropDown, setShowMenuDropDown] = useState(false);

  const fetchPageData = async (
    link = "/api/v1/homepage/cryptodinar",
    pageType = "corporate"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      setTimeout(() => {
        dispatch(setHeaderListData(result.header_list));
        setIsFetching(false);
      });
    } else {
      console.log(result);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);
  return (
    <>
      <div className="header-main">
        <nav className="navbar navbar-expand-md navbar-light bg-light">
          <div
            className="container-fluid "
            style={{
              justifyContent: "unset",
            }}
          >
            <button
              className="navbar-toggler ms-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowMenuDropDown(!showMenuDropDown)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <NavLink to="/">
              {isLoggedIn ? "" : <img src={CryptoDinarLogo} />}
            </NavLink>

            {isLoggedIn ? (
              <Profile />
            ) : (
              <>
                <div
                  className="d-none d-md-block collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  {isFetching ? (
                    <></>
                  ) : (
                    <ul className="navbar-nav font-16 font-w-500">
                      {headerList &&
                        headerList.map((header, index) => {
                          return (
                            <li className="nav-item">
                              <NavLink
                                className="nav-link"
                                activeclassname="active"
                                to={`/${header.page_type_name}`}
                              >
                                {header.name}
                              </NavLink>
                            </li>
                          );
                        })}
                      <li className="nav-item">
                        <NavLink className="nav-link p-0" to="/login">
                          <button className="sunset-yellow-bg border-0 px-4 py-2 border-radidus-4">
                            Login
                          </button>
                        </NavLink>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            )}
          </div>
        </nav>
        <ul
          className={
            showMenuDropDown
              ? "d-block d-md-none dropdown-menu"
              : "dropdown-menu d-none"
          }
        >
          {headerList &&
            headerList.map((header, index) => {
              return (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeclassname="active"
                    to={`/${header.page_type_name}`}
                    onClick={() => setShowMenuDropDown(false)}
                  >
                    {header.name}
                  </NavLink>
                </li>
              );
            })}
          <li className="nav-item">
            <NavLink
              className="nav-link dropdown-item"
              activeclassname="active"
              to="/login"
            >
              Login
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link dropdown-item"
              activeclassname="active"
              to="/my-cart"
            >
              My Cart
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default React.memo(Header);
