import React, { useState, useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import "../../assets/css/login.css";
import UserRegistrationForm from "../../components/shared/forms/user-registration-form";
import leafImg from "../../assets/images/leaf.png";
import ConfirmPasswordForm from "../../components/shared/forms/confirm-password-form";
import { useNavigate, Link } from "react-router-dom";
import loginSide from "../../assets/images/login-side.png";
import Logo from "../../assets/images/DcommLogo.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  if (check === false) {
    navigate("/login");
  }
  const handleClose = () => {
    navigate("/login");
  };
  useEffect(() => {
     document.title = "Confirm OTP";
  }, []);
  return (
    <>
      <div className="login-page">
        <div className="flex-rows">
            <div className="column-img">
              <div className="logo">
                  <Link to={'/'}><img src={Logo} title="" alt="" /></Link>
              </div>
            </div>
            <ConfirmPasswordForm setVerifyRegistrationCode={setCheck}  />          
        </div>
      </div>
    </>
  );
};

export default SignUp;
