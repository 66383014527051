import React, { useState, useEffect } from "react";
import {
  multiLogin,
  sSOConfirmPassword,
  sSOResendCode,
} from "../../../core/services/api/user";
import { validateConfirmPassword } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import useAlert from "./../../../hooks/use-alert";
import loginSubmit from "./../../../core/utils/login_submit";
import EmailVerification from "../../../assets/images/emilConfirmIcon.svg";
import OtpInput from "react-otp-input";
import LockIcon from "../../../assets/images/icon-lock.png";
import BackIcon from "../../../assets/images/icon-back.png";

const ConfirmPasswordForm = ({ setVerifyRegistrationCode, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { username } = useSelector((state) => state.userReducer);
  const { setUserDetails } = useSelector((state) => state.userReducer);
  const [showMessage, setShowMessage] = useState(true);
  const { userEmail } = useSelector((state) => state.userReducer.userDetails);
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const { showAlert } = useAlert();

  const handleConfirmPasswordSubmit = async () => {
    
    const result = await sSOConfirmPassword(values);
    if (result && result.status) {
      /*const values = {
        login: data.username,
        password: data.password,
      };*/
      /*console.log("Values 123", values);
      const res = await multiLogin(values);*/
      //if (res && res.status) {
        
        //const resp = loginSubmit(res, dispatch, showAlert);
        localStorage.clear();
        showAlert("SUCCESS", "Success", "Otp Verification Successful, Please login now.");
        //navigate(resp);
        navigate("/login");
      //}
    } else {
      showAlert("ERROR", "Error", result.message);
    }
  };
  const handleChange = (code) => {
    setValues((preValues) => ({
      ...preValues,
      confirmationCode: code,
    }));
  };
  const handleResendCodeSubmit = async () => {
    
    const result = await sSOResendCode(values);
    if (result.status) {
      showAlert("SUCCESS", "Success", result.message);
    } else {
      showAlert("ERROR", "Error", result.message);
    }
  };

  const redirect = () => {
    navigate("/login");
  }

  useEffect(() => {
    const userName = localStorage.getItem("Userename");
    if (userName && username === "") {
      setValues((preValues) => ({
        ...preValues,
        username:userName,
      }));
    }else{
      setValues({
        username,
      });
      localStorage.setItem("Userename", username);
    }
  }, []);

  const { values, setValues, errors, handleSubmit } = useForm(
    handleConfirmPasswordSubmit,
    validateConfirmPassword
  );

  return (
    <>
      <div className="column-text dcomm-login-form-layer">
        <div className="text-left pb-40">
            <button type="button" className="btn-icon" onClick={redirect}><img src={BackIcon} title="Back" className="back-icon" /></button>
        </div>
        <div className="text-center">
            <h3 className="crypto-dinar-white font-gil-bold">{setVerifyRegistrationCode ? "Confirm Otp" : "Verify Otp"}</h3>
            <div className="icon-box">
                <p className="text-white">Please enter the 6 Digit Code sent to {values.username}</p>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
          {showMessage && message && (
            <p
              className={
                messageType === MESSAGE_VARIANT.ERROR
                  ? "text-danger"
                  : "text-success"
              }
            >
              <small>{message}</small>
            </p>
          )}
          <div className="log-box">
            <div className="form-group" style={{ textAlign: "center" }}>
              <input
                disabled={true}
                type="text"
                className="form-control"
                name="username"
                style={{ width: "60%", margin: "0 auto"}}
                value={values.username === undefined ? "" : values.username}
              />
              {errors.username && (
                <p className="text-danger">
                  <small>{errors.username}</small>
                </p>
              )}
            </div>
          </div>
          <div className="log-box otp-diff-inputs">
              <div className="otp-inputs">
                  <OtpInput
                    value={
                      values.confirmationCode === undefined
                        ? ""
                        : values.confirmationCode
                    }
                    onChange={handleChange}
                    numInputs={6}
                    name="confirmationCode"
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      // border: "1px solid black",
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "16px",
                      color: "rgba(25, 55, 110, 1)",
                      fontWeight: "600",
                      backgroundColor: "#F5F8FF",
                    }}
                    focusStyle={{
                      // border: "1px solid black",
                      outline: "none",
                    }}
                  />
              </div>
              {errors.confirmationCode && (
                <p className="text-danger text-center">
                  <small>{errors.confirmationCode}</small>
                </p>
              )}
              <div className="form-group pt-10">
                  <button type="submit" className="buy-coin-btn w-100">Verify</button>
              </div>
              <div className="text-center pt-70">
                  <a href="#" className="btn-link" onClick={() => handleResendCodeSubmit()}>Resend Code</a>
              </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ConfirmPasswordForm;
