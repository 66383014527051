import React from "react";
import { useNavigate } from "react-router";

const AddDFender = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="row add_dfender">
        <button onClick={() => navigate("/add-dfender")}>Add DFender</button>
      </div>
    </>
  );
};
export default AddDFender;
