/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

const BuildingSection = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  return (
    <>
      {bannerSections &&
        <section className="building-secion">
            <div className="container">
                <div className="building-inner">
                    <div className="flex">
                        <div className="col-left">
                            {ReactHtmlParser(bannerSections.title)}
                            <div className="text-right">
                                {ReactHtmlParser(bannerSections.banner_text)}
                            </div>    
                        </div>
                        <div className="col-right">
                            <div className="mob-img">
                                <img src={PATH+bannerSections.image_url} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      }
    </>
  );
};

export default BuildingSection;
