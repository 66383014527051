import React from "react";

const Operation = ({ bannerSection }) => {
  return (
    <>
      <div className="container mt-5">
        <div className="row ">
          <div className="col-md-1 py-3 font-w-600">
            {bannerSection.widget[0].name}
          </div>
          {bannerSection &&
            bannerSection.widget.map((data, index) => (
              <>
                {index === 0 ? (
                  ""
                ) : (
                  <div className="col-md-2 mx-2 operation-tabs text-center">
                    <div key={index} className="py-3 ">
                      {data.name}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
        <div className="row mt-1">
          <div className="col-md-1 py-3 font-w-600">
            {bannerSection.widget[0].value}
          </div>
          {bannerSection &&
            bannerSection.widget.map((data, index) => (
              <>
                {index === 0 ? (
                  ""
                ) : (
                  <div className="col-md-2 mx-2 operation-tabs2 text-center">
                    <div key={index} className="py-3 ">
                      {data.value}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    </>
  );
};
export default Operation;
