/* eslint-disable new-cap */
import React from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

const InvestorCard = ({ investor }) => {
  return (
    <div className="card border-radidus-8 mt-4 mt-sm-4 mt-md-4 mt-lg-4 ">
      <div className="card-body p-0">
        <div className="investor-image">
          {investor.image_url && (
            <img
              src={`${URLS.SERVER_IMAGE_FOLDER_URL}${investor.image_url}`}
              className="w-100"
            />
          )}
        </div>
        <div className="investor-detail-section sunset-C5997C-bg font-ffffff p-4 p-sm-4 p-md-3 p-lg-4">
          {/* <p className="font-18 font-w-600">{investor.name}</p> */}
          {/* <p className="font-14 font-w-500">{investor.sequence}</p> */}
          {/* <p className="font-14 font-w-400">Vice President</p> */}
          <p className="font-12 font-w-400 opacity-90">
            {ReactHtmlParser(investor.short_discription)}{" "}
          </p>
          {/* <p className="font-14 font-w-500">www.mahindra.com</p> */}
        </div>
      </div>
    </div>
  );
};

export default InvestorCard;
