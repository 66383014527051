/* eslint-disable new-cap */
import React, { useState } from "react";
import URLS from "../../configs/api-end-points";
import CountEntryCard from "./count-entry-card";
import ReactHtmlParser from "react-html-parser";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import { useLocation } from "react-router";
import IconCardBg from "./icon-card-bg";
import IconCard from "./icon-card";

const ImageRightContentLeftWidgetContentImage = ({
  bannerSection,
  classes,
}) => {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }

  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={classes}>
      <div className="card border-radidus-16">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
              {ReactHtmlParser(bannerSection.banner_text)}

              {bannerSection &&
                bannerSection.banner_image &&
                bannerSection.banner_image.length > 0 && (
                  <div className="row mt-sm-5">
                    {bannerSection.banner_image.map((image, index) => {
                      return (
                        <>
                          <div
                            className="col-6 col-sm-4 col-md-5 col-lg-4 col-xl-3 mb-3 mb-sm-3 mb-xl-5"
                            onClick={() => setActiveIndex(index)}
                          >
                            <IconCard
                              isActive={activeIndex === index}
                              icon={image.image_url}
                              iconText={ReactHtmlParser(image.text)}
                            />
                          </div>
                          <div className="col-0 col-sm-0 col-xl-1 col-md-0 d-none d-xl-block"></div>
                        </>
                      );
                    })}
                  </div>
                )}
              <div className="row float-end margin-top">
                <button className="discover-button button-brown">
                  Discover more
                </button>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {bannerSection && bannerSection.widget && (
                <div className="position-absolute w-100 row">
                  <div className="col-12 col-sm-12 col-md-6 mt-3 mx-2">
                    <CountEntryCard
                      widgets={bannerSection.widget}
                      titleColor="font-00A14B"
                    />
                  </div>
                </div>
              )}
              <img
                src={`${PATH}${bannerSection.image_url}`}
                className="w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageRightContentLeftWidgetContentImage;
