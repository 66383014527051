import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import LeftMenu from "../../components/shared/left-menu";
import NatureEnergyTabs from "../../components/shared/nature-and-energy-tabs";
import DasboardOrderHistory from "../../components/shared/dashboard-order-history";
import NatureTabReports from "../../components/shared/nature-tab-content-reports";
import EnergyTabReport from "../../components/shared/energy-tab-content-reports";
import { getAccessToken } from "../../core/utils/token";
import { ACCESS_TOKEN_TYPES } from "../../utils/constants/localstorage-types";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KYC_STATUS } from "../../utils/constants/kyc-types";
import useAlert from "../../hooks/use-alert";

const DFender = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const { isUserKYCDone, userKYCStatus } = useSelector(
    (state) => state.kycReducer
  );
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const check = getAccessToken(
    ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]
  );
  const energyTab = check === "null" ? "no" : "yes";
  if (!isLoggedIn) {
    navigate("/login");
  }
  if (isUserKYCDone == false && userKYCStatus != "approved") {
    showAlert(
      "ERROR",
      "Error",
      userKYCStatus === KYC_STATUS.IN_REVIEW
        ? "Your KYC is under process. Please wait until it is completed."
        : "KYC must be done for DFender."
    );
    navigate("/kyc-verification");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="DFender">
      <div className="dcomm-bg-layer min-vh-80">
        <div className="dashboard-tiles m-0 my-5 px-4">
          <div className="dashboard-pills">
            <div className="tab-content" id="myTabContent">
              {isUserKYCDone == false && userKYCStatus != "approved" ? (
                ""
              ) : (
                <NatureTabReports page={"dfender"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default DFender;
