import React, { useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import LeftMenu from "../../components/shared/left-menu";
import NatureEnergyTabs from "../../components/shared/nature-and-energy-tabs";
import NatureTabDashboard from "../../components/shared/nature-tab-content-dashboard";
import EnergyTabDashboard from "../../components/shared/energy-tab-content-dashboard";
import { getAccessToken } from "../../core/utils/token";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import { ACCESS_TOKEN_TYPES } from "../../utils/constants/localstorage-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const check = getAccessToken(
    ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]
  );
  const energyTab = check === "null" ? "no" : "yes";
  if (!isLoggedIn) {
    navigate("/login");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="Dashboard">
      <div className="col-sm p-3 min-vh-100 d-flex flex-wrap">
        <div className="dashboard-tiles m-0 m-md-5">
          <div className="dashboard-pills">
            {/* <NatureEnergyTabs enegrynft={energyTab} /> */}

            <div className="tab-content" id="myTabContent">
              <NatureTabDashboard />
              {/* <EnergyTabDashboard page={"energy"} /> */}
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default Dashboard;
