import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import "../../assets/css/redemption.css";
import RedemptionOrders from "../../components/shared/redemption-orders";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  if (!isLoggedIn) {
    navigate("/login");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="Orders">
      <div className="col-sm p-3 min-vh-100 d-flex flex-wrap">
        <div className="dashboard-tiles m-0">
          <div className="dashboard-pills">
            <div className="tab-content" id="myTabContent">
              <RedemptionOrders />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default Orders;
