import URLS from "../../../configs/api-end-points";
import apiServices from "../data/api_service";

const getKYCAccessToken = async () => {
    const data = {
    };
    const url = URLS.SUMSUB_ACCESS_TOKEN;
    const result = await apiServices.post(url, data);
    return result;
  };

  const getUsetKYCStatus = async () => {
    const data = {
    };
    const url = URLS.KYC_STATUS;
    const result = await apiServices.post(url, data);
    return result;
  };

  export { getKYCAccessToken, getUsetKYCStatus };
