/* eslint-disable new-cap */
import React from 'react';
import URLS from '../../configs/api-end-points';
import CountEntryCard from '../modals/count-entry-card';
import ReactHtmlParser from 'react-html-parser';
import { PROJECT_TYPES } from '../../utils/constants/project-types';
import { useLocation } from 'react-router';
import IconCardBg from '../modals/icon-card-bg';
import IconCard from '../modals/icon-card';

const Image22 = ({ bannerSection, classes }) => {
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <div className={classes}>
            <div className="card border-radidus-16">
                <div className="card-body p-0">
                    {
                        bannerSection && <div className="row">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                {
                                    bannerSection.banner_image && bannerSection.banner_image.length > 0 && <div className="row mx-0 h-100">
                                    {
                                        bannerSection.banner_image.map((bannerImage, index) => {
                                            return <div className="col-3 col-sm-3 col-md-6 col-lg-6 p-0">
                                                <img src={`${PATH}${bannerImage.image_url}`} className="w-100 h-100" />
                                            </div>;
                                        })
                                    }
                                </div>
                                }
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                                {ReactHtmlParser(bannerSection.banner_text)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Image22;
