const KYC_STATUS = {
  INIT: "init",
  WAITING: "waiting",
  IN_REVIEW: "inreview",
  REJECTED: "rejected",
  RETRY: "RETRY",
  FINAL: "FINAL",
  PENDING: "pending",
  APPROVE: "approved",
};

export { KYC_STATUS };
