import URLS from "../../../configs/api-end-points";
import { getAccessToken, getUserId, getLoginTime } from "../../utils/token";

import {
  PROJECT_TYPES,
  PROJECT_TYPES_URL,
} from "../../../utils/constants/project-types";
import {
  ACCESS_TOKEN_TYPES,
  USER_ID_TYPES,
} from "../../../utils/constants/localstorage-types";

const getHeaders = () => {
  const headers = {};
  const apiKey = "auth_key";
  headers[apiKey] = "C9941611DF64B";
  return headers;
};

const post = async (
  url,
  data = {},
  type = PROJECT_TYPES.PROJECT_TYPES_NATURE
) => {
  const userId = getUserId(USER_ID_TYPES[type]);
  const accessToken = getAccessToken(ACCESS_TOKEN_TYPES[type]);
  const loginTime = getLoginTime();
  if (Date.now() - loginTime > 86400000) {
    localStorage.clear();
  }
  if (accessToken !== null);
  data = Object.assign(data, {
    user_id: parseInt(userId, 10),
    access_token: accessToken,
  });

  let response = {};
  const URL = `${URLS[PROJECT_TYPES_URL[type]]}${url}`;
  await fetch(`${URL}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: getHeaders(),
  })
    .then(async (result) => {
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};

const get = async (url, type = PROJECT_TYPES.PROJECT_TYPES_NATURE) => {
  let response = {};
  const loginTime = getLoginTime();
  if (Date.now() - loginTime > 86400000) {
    localStorage.clear();
  }
  const URL = `${URLS[PROJECT_TYPES_URL[type]]}${url}`;
  await fetch(`${URL}`, {
    method: "GET",
  })
    .then(async (result) => {
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};

export default { get, post };
