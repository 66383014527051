import React from "react";
import RedemptionOrderTableData from "../list-data/redemption-order-table-data";

const RedemptionOrderTable = () => {
  return (
      <RedemptionOrderTableData />
  );
};

export default RedemptionOrderTable;
