/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

const FeeStruture = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  let headings = [...new Set(bannerSection.widget.map(item => item.head))];
  return (
    <>
      {bannerSection &&
        <section className="operation-section">
            <div className="container">
                <ul className="opration-tab">
                    <li>Operations</li>
                    {headings &&
                    headings.map((heading, list_key) => {
                        return (
                        <li key={list_key} className={list_key === 0 ? "active" : ""} data-id={heading.replace(/\W/g, '-').toLowerCase()}><Link to="#" onClick={e => e.preventDefault()}>{heading}</Link></li>
                        );
                    })}
                </ul>
                <div className="opration-content">
                    {headings &&
                    headings.map((heading, list_key1) => {
                      return (
                         <div key={list_key1} className="content-inner" id={heading.replace(/\W/g, '-').toLowerCase()}>
                            <ul>
                              <li>Fee*</li>
                              {bannerSection.widget.map((banner, index_key) => {
                                return (
                                  <>
                                    {heading === banner.head &&
                                      <li key={index_key}><Link to="#" onClick={e => e.preventDefault()}>{ReactHtmlParser(banner.value)}</Link></li>
                                    }
                                  </>
                                );
                              })}
                            </ul>
                         </div>
                      );
                    })}
                </div>
            </div>
        </section>
      }
    </>
  );
};

export default FeeStruture;
