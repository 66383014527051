/* eslint-disable new-cap */
import React from "react";
import InvestorCard from "./investor-card";
import ReactHtmlParser from "react-html-parser";
import PartnerCard from "./partner-card";

const ImagePartner = ({ banner, classes }) => {
  return (
    <>
      <div className={classes}>
        <div className="row text-center">
          <div className="col-md-12">
            <div className="font-36 font-w-600">
              {ReactHtmlParser(banner.title)}
            </div>
          </div>
        </div>
        <div className="row justify-content-around">
          {banner.banner_image &&
            banner.banner_image.map((info, index) => (
              <div key={index} className="col-md-4 col-sm-6 col-lg-2 ">
                <PartnerCard partner={info} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default ImagePartner;
