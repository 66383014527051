import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import URLS from "../../configs/api-end-points";
import ThemeContainer from "../../containers/theme-container";
import ProjectSlider from "../../components/shared/project-slider";
import "../../assets/css/project-details.css";
import "../../assets/css/dashboard-style.css";
import ProjectForms from "../../components/shared/project-account-forms";
import ProjectDetailSummary from "../../components/shared/project-detail-summary";
import aboutImg from "../../assets/images/project-detail/mask_group.jpg";
import ProjectDetailAbout from "../../components/shared/project-detail-about";
import { getProjectDetail } from "../../core/services/api/project";
import ProjectMapContainer from "../../components/shared/project-map-container";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  const fetchProjectData = async (projectId) => {
    const result = await getProjectDetail(projectId);
  };

  useEffect(() => {
    fetchProjectData(projectId);
  });

  return (
    <ThemeContainer type="public" module="project">
      <div className="main-content container-fluid header-margin">
        <div className="row m-5">
          <ProjectSlider />
          <div
            className="col-md-5"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
          >
            <div style={{ paddingTop: "16px" }}>
              <div className="row">
                <div className="col-md-10">
                  <h1 style={{ color: "#2EDC6D" }}>Brazil Fonest NFT</h1>
                </div>
                <div className="col-md-2">
                  <ProjectMapContainer />
                </div>
              </div>
              <p>
                Please fill in this form to create an accountPlease fill in this
                form to create an accountPlease fill in{" "}
              </p>
            </div>
            <ProjectForms />
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade show active new-radius py-4 m-5"
        id="naturenft"
        role="tabpanel"
        aria-labelledby="naturenft-tab"
      >
        <div className="dasboard-numbers my-4 mx-1 mx-md-5">
          <ProjectDetailSummary />
        </div>
        <div className="detail-about mt-5 mx-1 mx-md-5">
          <div className="row">
            <div className="col-lg-6 pt-5">
              <img src={aboutImg} className="det-vid-img" alt="" />
            </div>
            <div className="col-lg-6">
              <ProjectDetailAbout />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default ProjectDetail;
