import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import RedemptionDashboard from "../../components/shared/redemption-dashboard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Redemption = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  if (!isLoggedIn) {
    navigate("/login");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="Redemption Fee">
      <div className="col-sm min-vh-100 d-flex flex-wrap mt-md-5">
        <div className="dashboard-tiles m-0 ">
          <div className="dashboard-pills">
            <div className="tab-content" id="myTabContent">
              <RedemptionDashboard />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default Redemption;
