import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/home.css";
import homeSection5Img from "../../assets/images/home-page-section5-img.svg";
import homeSection4Img1 from "../../assets/images/home-page-section4-img1.svg";
import homeSection4Img2 from "../../assets/images/home-page-section4-img2.svg";
import homeSection4Img3 from "../../assets/images/home-page-section4-img3.svg";
import homeSection4Img4 from "../../assets/images/home-page-section4-img4.svg";
import NftToken from "../../components/shared/nft-token";
import homePageEnergyImage from "../../assets/images/home-page-energy-image.svg";
import homePageNatureImage from "../../assets/images/home-page-nature-image.svg";
import CountEntryCard from "../../components/modals/count-entry-card";
import FactsAndFigures from "../../components/shared/facts-and-figures";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import image from "../../assets/images/sunset_banner_energy.png";
import Banner from "../../components/shared/banner";
import NewsAndMedia from "../../components/shared/news-and-media";
import GlobalCommunity from "../../components/shared/global-community";
import SupplierSection from "../../components/shared/supplier-section";

const Investors = () => {
  return (
    <ThemeContainer type="public" pageTitle="Investors">
      <Banner
        bannerImage={image}
        image1={eclipseImage2}
        image2={eclipseImage}
        bannerText="The Sunset Capital only supplier of 100% Green & Renewable electricity Token provider."
      />
      <div className="home-main">
        <div className="section-1 row sunset-F2F2F2-bg p-2 p-sm-3 p-md-5 p-lg-5 p-xl-5">
          <div className="col-12">
            <div className="fact-and-figures m-auto ml-5">
              <FactsAndFigures />
            </div>
          </div>
        </div>

        <div className="section-5 row sunset-F2F2F2-bg">
          <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
            <h3 className="font-36 font-w-600 mb-4">
              Superpowers for Smart Investors !!
            </h3>
            <p className="font-18 font-w-400 font-656565 mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              tristique diam lacus, nec ullamcorper massa faucibus vitae.
              Praesent at bibendum tortor. Pellentesque at purus at est varius
              ullamcorper. Aliquam arcu purus, euismod non turpis vitae,
              venenatis tincidunt metus. Investor Docs
            </p>
            <button className="btn sunset-yellow-bg font-20 font-w-500 font-272E35">
              Investor Docs
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <img src={homeSection5Img} className="w-100" />
          </div>
        </div>

        <div className="section-6 row sunset-ffffff-bg">
          <SupplierSection />
        </div>

        <div className="section-7 sunset-F2F2F2-bg">
          <NewsAndMedia
            title1=""
            heading1=""
            title2="News and Media"
            heading2="Sunset Capital in News & Media"
          />
        </div>

        <div className="section-8 sunset-ffffff-bg">
          <GlobalCommunity title1="" heading1="A global community" />
        </div>
      </div>
    </ThemeContainer>
  );
};

export default Investors;
