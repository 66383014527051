import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import NatureEnergyTabs from "../../components/shared/nature-and-energy-tabs";
import NatureNFTTabDashboard from "../../components/shared/naturenft-tab-content-dashboard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/use-alert";
import { KYC_STATUS } from "../../utils/constants/kyc-types";

const NatureNft = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const { isUserKYCDone, userKYCStatus } = useSelector(
    (state) => state.kycReducer
  );
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  if (!isLoggedIn) {
    navigate("/login");
  }
  if (isUserKYCDone == false && userKYCStatus != "approved") {
    showAlert(
      "ERROR",
      "Error",
      userKYCStatus === KYC_STATUS.IN_REVIEW
        ? "Your KYC is under process. Please wait until it is completed."
        : "Please complete your KYC first."
    );
    navigate("/kyc-verification");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="Buy Coin">
      <div className="dcomm-bg-layer">
        <div className="dashboard-tiles m-0 m-md-5 py-md-5">
          <div className="dashboard-pills">
            <div className="tab-content" id="myTabContent">
              <NatureNFTTabDashboard enegrynft="no" page={"nature"} />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default NatureNft;
