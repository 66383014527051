import ClubForms from "./addClubform";
import CryptoHeader from "./crypto-header";
import NFTMessage from "./message-share";

const   ClubNFTTabDashboard = ({ enegrynft = "yes", page }) => {
  return (
    <div>
      <div className="dashboard-form mx-1 mx-md-5">
        <div className="row">
          <NFTMessage page="buyToken" />
        </div>
        <div className="row mx-0">
          <div className="col-lg-3 p-0"></div>
          <div className="col-lg-6 p-0">
            <div className="dashboard-form-inner ">
              <ClubForms page={"nature"} />
            </div>
          </div>
          <div className="col-lg-3 p-0"></div>
        </div>
      </div>
    </div>
  );
};

export default ClubNFTTabDashboard;