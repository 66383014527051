/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Media = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  var media = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    autoplay:true,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                adaptiveHeight: true,
            },
        },
    ],
  };

  return (
    <section className="media-section">
       <div className="container">
          <div className="title-head text-center">
             {ReactHtmlParser(bannerSection.title)}
          </div>
          <Slider {...media} className="media-slider">
          {bannerSection &&
            bannerSection.banner_image.map((bannerImage, banner_key) => {
              return (
             <div key={banner_key}>
                <div className="slider-inner">
                    <div className="video"><img src={PATH+bannerImage.image_url} alt="" /></div>
                    <div className="slider-text">
                      {ReactHtmlParser(bannerImage.heading)}
                      {ReactHtmlParser(bannerImage.text)}
                    </div>
                </div>
             </div>
             );
          })}
          </Slider>
       </div>
    </section>
  );
};

export default Media;
