import React from "react";
import GraphTableTabs from "./graph-table-btton";
import OtherNFTProjects from "./other-nft-projects";
import { useSelector } from "react-redux";
const NatureDasboardSummary = () => {
  const { dashboard } = useSelector((state) => state.nftReducer);

  return (
    <>
      {dashboard !== undefined && dashboard !== null && (
        <>
          <div className="dasboard-numbers my-4 mx-1 mx-md-5">
            <div className="row p-3">
              <div className="col-lg-4 my-3">
                <div className="number-inner text-center">
                  <h2 className="number-head">
                    USD {dashboard.snt_market_cap.toLocaleString()}
                  </h2>
                  <p className="number-text m-0">Crypto Dinar Market Cap</p>
                </div>
              </div>
              <div className="col-lg-4 my-3">
                <div className="number-inner text-center">
                  <h2 className="number-head">
                    {dashboard.snt_total_circulating_supply.toLocaleString()}
                  </h2>
                  <p className="number-text m-0">
                    Crypto Dinar Total Circulating Supply
                  </p>
                </div>
              </div>
              <div className="col-lg-4 my-3">
                <div className="number-inner text-center">
                  <h2 className="number-head">$1.00</h2>
                  <p className="number-text m-0">Crypto Dinar Price</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NatureDasboardSummary;
