import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../assets/css/alert.css";

const BootstrapToast = () => {
  const { alertType, alertTitle, alertMessage, showAlert } = useSelector(
    (state) => state.alertReducer
  );
  const alertMapping = {
    SUCCESS: "success-alert",
    ERROR: "error-alert",
  };
  return (
    <>
      {showAlert && (
        <div
          className={`alert ${alertMapping[alertType]} position-fixed top-5 end-0 p-3 w-30`}
          style={{ zIndex: "1070" }}>
          <div
            id="liveToast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true">
            <div className="toast-body">
              {alertTitle && (
                <h6 className="font-16 font-w-400">{alertTitle}</h6>
              )}
              {alertMessage && (
                <p className="font-909090 font-14 font-w-400">{alertMessage}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BootstrapToast;
