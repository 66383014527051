import React, { useEffect, useState } from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/home.css";
import { useParams, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import URLS from "../../configs/api-end-points";
import BgImg from "../../assets/images/banner.png";
import { Spinner } from "react-bootstrap";

const CmsPage = () => {
  const { pageId } = useParams();
  const apiUrl = URLS.NATURE_BASE_URL;
  const [description, setDescrption] = useState("");
  const [bannerContent, setBannerContent] = useState("");
  const pageTitle = pageId.replace(/^./, (str) => str.toUpperCase());
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCmsData = (pageId) => {
    if (pageId !== "") {
      fetch(apiUrl + "/api/v1/cms/pages/" + pageId)
        .then((response) => response.json())
        .then((data) => { 
          if (data.status) {
            setDescrption(data.cms_page_description);
            setBannerContent(data.banner_content);
            setIsLoading(false);
          } else {
            console.log(data);
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    fetchCmsData(pageId);
  }, []);

  useEffect(() => {    
    navigate("/login");
  }, []);

  return (
    <ThemeContainer type="public" pageTitle={pageTitle}>
      {!isLoading ? (
        <>
          <section className="contact-banner" style={{ backgroundImage: `url(${BgImg})` }}>
            <div className="container">
                <div className="text-center benner-ext">
                    {ReactHtmlParser(bannerContent)}
                </div>
            </div>
          </section>
          <section className="detal-section">
            <div className="container">
              {ReactHtmlParser(description.description_content)}
            </div>
          </section>
        </>
      ) : (
        <div className="Loader">
          <small><Spinner animation="border" /></small>
        </div>
      )}
    </ThemeContainer>
  );
};

export default CmsPage;
