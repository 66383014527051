import { PAGE_TYPES } from "../redux-constant-types";


const setHeaderListData = (headerList) => {
    return {
        type: PAGE_TYPES.SET_HEADER_LIST,
        payload: {
            headerList
        }
    };
};

export { setHeaderListData };
