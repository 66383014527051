import React from "react";
import kucoinImg from "../../assets/images/project-detail/kucoin.png";
import bitmartImg from "../../assets/images/project-detail/bitmart.png";
import binanceImg from "../../assets/images/project-detail/binance.png";

const ProjectDetailAbout = () => {
  return (
    <>
      <div className="det-about">
        <h6 className="det-h6">About</h6>
        <h3 className="det-h3 mb-3">Brazil Forest Project</h3>
        <p className="det-p mt-4">
          The aim of this project is to implement an NFT marketplace for
          maintenance of the sustainable assets - Mangroves Natural Reserve.
          Sunset Digital owns 1,100,000.00 m2 of Mangroves Natural Reserve and
          wants to tokenize it with 1,100,000 Sunset sustainable tokens.
        </p>

        <p className="det-p mt-3">
          Sunset Digital owns 1,100,000.00 m2 of Mangroves Natural Reserve and
          wants to tokenize it with 1,100,000 Sunset sustainable tokens.
        </p>

        <h6 className="det-pr my-4">Project Specs:</h6>
        <p className="det-p mt-3">
          Sunset owns 1,100,000.00 m2 of Mangroves Natural Reserve of Brazil
          Forest; 1,100,000 Sunset sustainable tokens <br /> Listed on 3
          exchanges;
        </p>
        <div className="row det-img mt-5">
          <div className="col-lg-4 text-center">
            <img src={kucoinImg} className="det-img-inner" alt="" srcSet="" />
          </div>
          <div className="col-lg-4 text-center">
            <img src={bitmartImg} className="det-img-inner" alt="" srcSet="" />
          </div>
          <div className="col-lg-4 text-center">
            <img src={binanceImg} className="det-img-inner" alt="" srcSet="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDetailAbout;
