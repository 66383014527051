import React, { useState, useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import UploadImageForm from "../../components/shared/upload-image";
import { getUserDetail } from "../../core/services/api/nft";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const KYCVerification = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  if (!isLoggedIn) {
    navigate("/login");
  }

  const getUserData = async () => {
    const res = await getUserDetail();
    if (res.success) {
      setData(res);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    getUserData();
  }, []);
  return (
      <ThemeContainer
        type="private"
        module="nature"
        pageTitle="Update Profile"
      >
        {isLoading ? (
          <div className="Loader">
            <small><Spinner animation="border" /></small>
          </div>
        ):null}
        {data && data !== null && (
          <div className="dcomm-bg-layer min-vh-90">
              <div className="row dashboard-tiles m-0 px-1 py-1 px-md-5 py-md-2">
                <UploadImageForm data={data} />
              </div>
          </div>
        )}
      </ThemeContainer>
  );
};

export default KYCVerification;
