import React, { useState, useEffect } from "react";
import { redemptionOrderSubmitOtp } from "../../core/services/api/user";
import "../../assets/css/token-popup.css";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import useAlert from "./../../hooks/use-alert";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { MESSAGE_VARIANT } from "../../redux/redux-constant-types";
import useForm from "../../hooks/use-form";
import {
  setSuccessMessage,
  setErrorMessage,
} from "../../redux/actions";

const VerifyOrderEmail = ({ data, isOpen, orderNumber, showMessage, message, messageType, hide, redeemId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [transId, setTransId] = useState("");  
  const handleChange = (code) => {
    setValues((preValues) => ({
      ...preValues,
      confirmationCode: code,
    }));
  };
  const handleOtpSubmit = async () => {
    const result = await redemptionOrderSubmitOtp(values);
    if (result && result.success) {
        showAlert("SUCCESS", "Success", result.message);
        hide(true);
    } else {
      showAlert("ERROR", "Error", result.message);
    }
  };
  const { values, setValues, errors, handleSubmit } = useForm(
    handleOtpSubmit
  );
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      username: localStorage.getItem('user_email'),
      redeem_id: redeemId,
    }));
  }, [redeemId]);
  return (
    <>      
      <div className={`pop_up${ isOpen ? " active" : "" }`}>
        <div className="pop_box pop_ptp">
            <span className="icon close" onClick={hide}><i className="fa fa-times" aria-hidden="true"></i></span>
            <span className="icon"><i className="fa fa-envelope" aria-hidden="true"></i></span>
            <p><b>Order#:</b> {orderNumber}</p>
            <p>Please enter the 6 Digit Code sent to your Email & Mobile</p>
            <form onSubmit={handleSubmit}>
              {showMessage && message && (
                <p
                className={
                  messageType === MESSAGE_VARIANT.ERROR
                  ? "text-danger"
                  : "text-success"
                }
                >
                <small>{message}</small>
                </p>
                )}
              {/*<div className="verification-code mobile-otp">
                  <label>Mobile</label>
                  <OtpInput
                    numInputs={6}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "16px",
                      color: "rgba(25, 55, 110, 1)",
                      fontWeight: "600",
                      backgroundColor: "#F5F8FF",
                    }}
                    focusStyle={{
                      outline: "none",
                    }}
                  />
              </div>*/}
              <div className="verification-code email-otp">
                  <label>OTP</label>
                  <OtpInput
                    numInputs={6}
                    value={
                      values.confirmationCode === undefined
                        ? ""
                        : values.confirmationCode
                    }
                    onChange={handleChange}
                    name="confirmationCode"
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      borderRadius: "8px",
                      width: "54px",
                      height: "54px",
                      fontSize: "16px",
                      color: "rgba(25, 55, 110, 1)",
                      fontWeight: "600",
                      backgroundColor: "#F5F8FF",
                    }}
                    focusStyle={{
                      outline: "none",
                    }}
                  />
              </div>
              <div className="verify-btn">
                  <input type="submit" value="Verify" className="btn-darkblue" />
              </div>
            </form>
        </div>
      </div>
    </>
  );
};

export default VerifyOrderEmail;
