import React, { useState, useEffect } from "react";
import {
  cryptoConfirmPayment,
  getAssetRequestDetail,
  getEnergyAssetRequestDetail,
  rejectAssetRequest,
} from "../../core/services/api/nft";
import "../../assets/css/token-popup.css";

const AssetRequestDetail = ({ data, hide, page }) => {
  const [detail, setDetail] = useState();
  const getData = async () => {
    const values = {
      asset_request_id: data,
    };

    const res =
      page == "nature"
        ? await getAssetRequestDetail(values)
        : await getEnergyAssetRequestDetail(values);
    if (res.amount !== undefined) {
      setDetail(res);
    }
  };
  const baseURL =
    page == "nature"
      ? process.env.REACT_APP_API_NATURE_BASE_URL
      : process.env.REACT_APP_API_ENERGY_BASE_URL;
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {detail && (
        <div className="container">
          <div className="row ">
            <div className="col header ">Asset Request Detail</div>
          </div>
          <table className="table" id="dtHorizontalExample">
            <thead className="table-ch">
              <tr className="">
                <th scope="col">Name</th>
                <th scope="col">Token </th>
                <th scope="col">Project</th>
                <th scope="col">Crypto/Fiat</th>
                <th scope="col">Quantity</th>
                <th scope="col">Amount</th>
                <th scope="col">Transaction Fee</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody className="cursor-pointer">
              <tr>
                <th scope="row">{detail.name}</th>
                <td>{detail.token_name}</td>
                <td>{detail.token_project}</td>
                <td>
                  <img src={baseURL + detail.image} height="20" />
                  {detail.crypto_type}
                </td>
                <td>{detail.quantity}</td>
                <td>{detail.amount}</td>
                <td>{detail.transaction_fee}</td>
                <td>{detail.state}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default AssetRequestDetail;
