import React, { Fragment, useEffect } from "react";
import Footer from "../components/shared/footer/footer";
import Header from "../components/shared/header/header";
import SideBar from "../components/shared/side-bar";

const ThemeContainer = ({
  type = "public",
  children,
  module = "land",
  pageTitle = "Home page",
}) => {
  useEffect(() => {
    document.title = pageTitle;
  }, []);
  return (
    <Fragment>
      <div className={pageTitle.replace(/\s+/g, '-').toLowerCase()}>
        <Header page_type={type}/>
        {type === "public-no-footer" && (
          <Fragment>
            <div className="">{children}</div>
          </Fragment>
        )}
        {type === "public" && (
          <Fragment>
            <div className="">{children}</div>
            <Footer />
          </Fragment>
        )}
        {type === "private" && (
          <Fragment>
            <div className="main-content position-relative d-block">
              <div className="row">
                <div className="col-lg-2 col-md-3 col-sm-2 z-index-20 side-bar-main">
                  <SideBar />
                </div>
                <div className="col-lg-10 col-md-9 col-sm-12 mt-md-5">
                  {children}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ThemeContainer;
