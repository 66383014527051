import React, { useState } from "react";
import ThemeContainer from "../../containers/theme-container";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import "../../assets/css/login.css";
import leaf from "../../assets/images/leaf.png";

import QrCodeForm from "../../components/shared/forms/qrcode-form";
import { useNavigate } from "react-router-dom";

const QRCode = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/dashboard");
  };
  return (
    <ThemeContainer type="public-no-footer" pageTitle="QR Code">
      <div className="row sunset-ffffff-bg mb-lg-5 signup-main">
        <div className="col-lg-6 signup-section1">
          <img src={eclipseImage2} className="login-flip-image login-image2" />
          <img src={eclipseImage} className="login-flip-image login-image1" />
        </div>
        <div className="col-lg-5 col-sm-10 offset-1 signup-section-2 mb-lg-5">
          <div className="card border-radidus-24 ps-lg-5 pe-lg-5 pb-lg-3 mb-lg-5">
            <div className="card-body ps-lg-5 pe-lg-5 ps-sm-5 p-sm-5 pb-lg-3">
              {/* <button
                type="button"
                className="btn-close font-8 font-600"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleRedirect}
              ></button> */}
              <h1 className="font-30 font-w-500 font-292930 mt-lg-5 text-center">
                Google Authenticator
              </h1>
              <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
                Scan the Code Below
              </p>
              <QrCodeForm />
            </div>
          </div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default QRCode;
