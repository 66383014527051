import { KYC_TYPES } from "../redux-constant-types";

const setStartKYC = (status) => {
  return {
    type: KYC_TYPES.SET_START_KYC,
    payload: {
      status,
    },
  };
};

const setSumSubAccessToken = (sumSubAccessToken) => {
  return {
    type: KYC_TYPES.SET_SUMSUB_ACCESS_TOKEN,
    payload: {
      sumSubAccessToken,
    },
  };
};

const checkUserKYCStatus = (callback) => {
  return async (dispatch) => {
    const result = await callback();
    if (result) {
      dispatch({
        type: KYC_TYPES.SET_KYC_STATUS,
        payload: {
          isUserKYCDone: result.isUserKYCDone,
          userKYCStatus: result.kyc_status,
          rejectedType: result.review_reject_type,
          enableKyc: result.enable_kyc,
        },
      });
    } else {
      dispatch({
        type: KYC_TYPES.SET_KYC_STATUS,
        payload: {
          userKYCStatus: "",
          isUserKYCDone: false,
          rejectedType: "",
          enableKyc: false,
        },
      });
    }
  };
};

export { setStartKYC, setSumSubAccessToken, checkUserKYCStatus };
