/* eslint-disable default-param-last */
import { PAGE_TYPES } from "../redux-constant-types";

const intitialState = {
    headerList: []
};

const pageReducer = (state = intitialState, action) => {
    switch (action.type) {
        case PAGE_TYPES.SET_HEADER_LIST: return {
            ...state,
            headerList: action.payload.headerList
        };
        default: return state;
    }
};

export default pageReducer;
