import React, { useState, useEffect } from "react";
import { redemptionOrderAppoinmentDate } from "../../core/services/api/user";
import "../../assets/css/token-popup.css";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import useAlert from "./../../hooks/use-alert";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { MESSAGE_VARIANT } from "../../redux/redux-constant-types";
import useForm from "../../hooks/use-form";
import {
  setSuccessMessage,
  setErrorMessage,
} from "../../redux/actions";

const AppointmentDatePopup = ({ data, isEscrowModal, orderNumber, showMessage, message, messageType, hide, redeemId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const handleDateSubmit = async () => {    
    if(values.appointment_date === ""){
      showAlert("ERROR", "Error", "Please select a date first.");
      return;
    }
    const result = await redemptionOrderAppoinmentDate(values);
    if (result && result.success) {
        showAlert("SUCCESS", "Success", result.message);
        hide(true);
    } else {
      showAlert("ERROR", "Error", result.message);
    }
  };
  const { values, setValues, errors, handleSubmit, handleChange } = useForm(
    handleDateSubmit
  );
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      redemption_id: redeemId,
    }));
  }, [redeemId]);
  return (
    <>      
      
      <div className={`pop_up${ isEscrowModal ? " active" : "" }`}>
        <div className="pop_box pop_ptp">
            <span className="icon close" onClick={hide}><i className="fa fa-times" aria-hidden="true"></i></span>
            <span className="icon"><i className="fa fa-calendar" aria-hidden="true"></i></span>
            <p><b>Order#:</b> {orderNumber}</p>
            <p>Please select a date for appointment.</p>
            <form onSubmit={handleSubmit}>
              {showMessage && message && (
                <p
                className={
                  messageType === MESSAGE_VARIANT.ERROR
                  ? "text-danger"
                  : "text-success"
                }
                >
                <small>{message}</small>
                </p>
                )}
              <div className="email-otp">
                  <label>Date</label>
                  <input 
                    type="datetime-local" 
                    required="required"
                    min={new Date()} 
                    className="form-control bg-gray" 
                    name="appointment_date" 
                    value={values.appointment_date === undefined ? "" : values.appointment_date}
                    onChange={handleChange}
                  />
              </div>
              <div className="verify-btn">
                  <input type="submit" value="Submit" className="btn-darkblue" />
              </div>
            </form>
        </div>
      </div>
    </>
  );
};

export default AppointmentDatePopup;
