import React from 'react';
import { useLocation } from 'react-router';
import URLS from '../../configs/api-end-points';
import { PROJECT_TYPES } from '../../utils/constants/project-types';

const IconCard = ({ isActive, icon, iconText }) => {
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <div className={ isActive ? "card border-radidus-8 text-center" : "text-center"}>
            <div className="card-body">
                { icon && <img src={`${PATH}${icon}`} className="m-0"/> }
                { iconText && <p className="font-10 mt-4">{iconText}</p> }
            </div>
        </div>
    );
};

export default IconCard;
