import React from "react";
import { NavLink } from "react-router-dom";

const ProjectCard = ({ image, projectName, projectDescription, id }) => {
  return (
    <div className="card border-radidus-24">
      <NavLink to={`/project/${id}`}>
        <div className="card-body p-0">
          <img src={image} className="w-100 border-radidus-24" />
          <div
            className="card sunset-F3F5F6-bg border-radidus-12"
            style={{ marginTop: "-16%" }}
          >
            <div className="card-body p-lg-3">
              <div className="row">
                <div className="col-lg-8">
                  <h5 className="font-w-500 font-30 mb-2 font-34A853">
                    {projectName}
                  </h5>
                </div>
                <div className="col-lg-2">
                  <button className="border-0 w-auto float-end bg-transparent">
                    1 SNT
                  </button>
                </div>
                <div className="col-lg-2">
                  <button className="font-909090 border-2-909090 w-auto border-radidus-4">
                    $ 1.41
                  </button>
                </div>
              </div>
              <p>{projectDescription}</p>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ProjectCard;
