import React from "react";
import GraphTableTabs from "./graph-table-btton";
import OtherNFTProjects from "./other-nft-projects";

const DasboardHeading = ({
  headingType = "dashboard",
  tabSwitcher = "yes",
  page = "nature",
}) => {
  return (
    <>
      <div
        className={
          headingType === "dashboard"
            ? "dashboard-heading d-flex justify-content-between align-items-center flex-wrap my-5 mx-1 mx-md-5"
            : "dashboard-heading d-flex justify-content-between align-items-center flex-wrap mx-1 mx-md-5"
        }
      >
        <div
          className={
            headingType === "dashboard"
              ? "text-left db-head mx-auto mx-md-0 123"
              : "text-left db-head mx-auto mx-md-0 mt-2 mt-md-5"
          }
        >
          {headingType === "dashboard" ? <h1>Current Totals</h1> : <br />}
        </div>
        <div className="text-right db-tab d-flex align-items-center flex-wrap">
          <OtherNFTProjects page={page} />
          {tabSwitcher == "yes" ? (
            <div className="db-panel me-auto ms-auto me-md-0 ms-md-3 my-2 my-md-0">
              <GraphTableTabs />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default DasboardHeading;
