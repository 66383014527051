import React from "react";
import { getUserName } from "../../../core/utils/token";
import { KYC_STATUS } from "../../../utils/constants/kyc-types";
import CryptoHeader from "./../crypto-header";
const KYCHeader = ({ userKYCStatus, isUserKYCDone }) => {
  const userName = getUserName();
  return (
    <>
      <CryptoHeader
        header={`Hi ${userName}`}
        subHeading={
          isUserKYCDone
            ? "Your KYC is completed"
            : "Let's complete your KYC Process"
        }
        page="KYC"
      />

      <div className="row">
        <div className="col-md-12">
          <div className="kyc-status">
            <div className="kyc-heading crypto-dinar-white font-w-600 font-16">
              KYC Status
            </div>
            {isUserKYCDone && userKYCStatus == KYC_STATUS.APPROVE ? (
              <>
                <div className="kyc-status-style">
                  {/*<span className="completed-notification"></span>*/}
                  <span className="font-16 crypto-dinar-white ">
                    <div className="green-dot" />
                    Completed
                  </span>
                </div>
              </>
            ) : userKYCStatus ===
              (KYC_STATUS.INIT ||
                KYC_STATUS.WAITING ||
                KYC_STATUS.IN_REVIEW) ? (
              <>
                {/*<span className="pending-notification"></span>*/}
                <div className="kyc-status-style">
                  <span className="font-16 crypto-dinar-white ">
                    <div className="yellow-dot" />
                    In Process
                  </span>
                </div>
              </>
            ) : userKYCStatus === KYC_STATUS.REJECTED ? (
              <>
                <div className="kyc-status-style">
                  {/*<span className="notstated-notification"></span>*/}
                  <span className="font-16 crypto-dinar-white ">
                    <div className="red-dot" />
                    Rejected
                  </span>
                </div>
              </>
            ) : userKYCStatus === false ? (
              <>
                {/*<span className="pending-notification"></span>*/}

                <div className="kyc-status-style">
                  <span className="font-16 crypto-dinar-white ">
                    <div className="yellow-dot" />
                    Pending
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="kyc-status-style">
                  {/*<span className="pending-notification"></span>*/}
                  <span className="font-16 crypto-dinar-white kyc-status-style">
                    <div className="yellow-dot" />
                    In Process
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default KYCHeader;
