import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentCurrency } from "../../redux/actions/pagination-action";
const NFTFormTab = ({ page }) => {
  const { masterData, energyMasterData, selectedCrypto } = useSelector(
    (state) => state.nftReducer
  );
  const [token, setToken] = useState();
  const dispatch = useDispatch();
  const setFiatToken = () => {
    if (masterData !== null && energyMasterData !== null) {
      const currentPage = page == "nature" ? masterData : energyMasterData;
      if (
        currentPage.exchange_data[selectedCrypto - 1].supported_fiat_currencies
          .length > 0
      ) {
        setToken(true);
      } else {
        setToken(false);
      }
    }
  };
  const changeCurrency = (e) => {
    dispatch(setCurrentCurrency(e));
  };
  useEffect(() => {
    setFiatToken();
  }, [masterData, energyMasterData]);

  return (
    <nav>
      <div className="nav nav-tabs" id="nav-tab" role="tablist">
        <button
          className="nav-link w-50 db-form-tab active"
          id="crypto-home-tab"
          data-bs-toggle="tab"
          data-bs-target="#crypto-home"
          type="button"
          role="tab"
          aria-controls="crypto-home"
          aria-selected="true"
          onClick={() => changeCurrency("crypto")}
        >
          Cryptocurrencies
        </button>
        {token === true ? (
          <button
            className="nav-link w-50 db-form-tab"
            id="fiat-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#fiat-profile"
            type="button"
            role="tabpanel"
            aria-controls="fiat-profile"
            aria-selected="false"
            onClick={() => changeCurrency("fiat")}
          >
            Fiat
          </button>
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};

export default NFTFormTab;
