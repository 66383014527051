import React from "react";
import {
  searchAssetRequest,
  searchEnergyAssetRequest,
} from "../core/services/api/nft";
import { setAssetRequest } from "../redux/actions/nft-action";

const paginationSubmit = async (
  start,
  end,
  currentPage,
  pageNumber,
  selectedCrypto,
  dispatch
) => {
  const value = {
    filed_from: start,
    filed_to: end,
    crypto_token_id: selectedCrypto,
    page_no: pageNumber,
  };
  const res =
    currentPage == "nature"
      ? await searchAssetRequest(value)
      : await searchEnergyAssetRequest(value);
  dispatch(setAssetRequest(res));
};

export default paginationSubmit;
