import React from "react";
import QrScan from "react-qr-scanner";
const WalletScanPopup = (props) => {
  const handleError = (err) => {
    console.error(err);
  };
  const handleScan = (data) => {
    if (data) {
      props.data(data.text, data.text);
      props.hide();
    }
  };
  return (
    <>
      <div id="" className="scan-window">
        <h1 className="kyc-pi">Hold the QR code steady to scan</h1>
        <QrScan
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ height: "100%", width: "100%" }}
        />
      </div>
    </>
  );
};

export default WalletScanPopup;
