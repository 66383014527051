import React, { useEffect, useState } from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import { Spinner } from "react-bootstrap";
import AddDFenderForm from "../../components/shared/addDFenderForm";
import { getDFenderAsset } from "../../core/services/api/home";
import { useSelector } from "react-redux";
import useAlert from "../../hooks/use-alert";
import { useNavigate } from "react-router";
import { KYC_STATUS } from "../../utils/constants/kyc-types";

const AddDFenderPage = () => {
  const [dfenderData, setDFenderData] = useState();
  const { isUserKYCDone, userKYCStatus } = useSelector(
    (state) => state.kycReducer
  );
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const getDfenderData = async () => {
    const res = await getDFenderAsset();
    if (res.success) {
      setDFenderData(res);
    }
  };
  if (isUserKYCDone == false && userKYCStatus != "approved") {
    showAlert(
      "ERROR",
      "Error",
      userKYCStatus === KYC_STATUS.IN_REVIEW
        ? "Your KYC is under process. Please wait until it is completed."
        : "Please complete your KYC first."
    );
    navigate("/kyc-verification");
  }

  useEffect(() => {
    getDfenderData();
  }, []);
  return (
    <ThemeContainer type="private" module="nature" pageTitle="DFender">
      {/* {isLoading ? (
        <div className="Loader">
          <small>
            <Spinner animation="border" />
          </small>
        </div>
      ) : null} */}

      <div className="dcomm-bg-layer min-vh-90">
        <div className="dfender-text">
          {dfenderData && dfenderData.defender_heading}
        </div>
        <div className="row dashboard-tiles m-0 px-1 py-1 px-md-5 py-md-2">
          <AddDFenderForm data={dfenderData} />
        </div>
      </div>
    </ThemeContainer>
  );
};

export default AddDFenderPage;
