import React from "react";
const UserProfileCard = ({ data }) => {
  let address = data.address ? data.address : "";
  let state = data.state ? data.state : "";
  let country = data.country ? data.country : "";
  let zip = data.zip ? data.zip : "";
  if (address) {
    address = address;
  }
  if (state) {
    state = ", "+state;
  }
  if (country) {
    country = ", "+country;
  }
  if (zip) {
    zip = ", "+zip;
  }
  let fullAddress = address+state+country+zip;
  return (
    <>
      
        <div className="row">
          {/*<div className="row">
            <div className="col-md-3 crypto-dinar-white">Phone</div>
            <div className="col-md-8 crypto-dinar-white">
              {data.phone ? data.phone : ""}
            </div>
          </div>*/}

          <div className="row my-4">
            <div className="col-md-3 crypto-dinar-white">Address</div>
            <div className="col-md-8 crypto-dinar-white">
              {fullAddress}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 crypto-dinar-white">Mobile</div>
            <div className="col-md-8 crypto-dinar-white">{data.mobile ? data.mobile : ""}</div>
          </div>
          <div className="row my-4">
            <div className="col-md-3 crypto-dinar-white">Email</div>
            <div className="col-md-8 crypto-dinar-white">{data.email ? data.email : ""}</div>
          </div>
        </div>
    </>
  );
};
export default UserProfileCard;
