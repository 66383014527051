const URLS = {
  SERVER_IMAGE_FOLDER_URL: process.env.REACT_APP_SERVER_IMAGE_FOLDER_URL,
  SERVER_ENERGY_IMAGE_FOLDER_URL:
    process.env.REACT_APP_SERVER_ENERGY_IMAGE_FOLDER_URL,
  NATURE_BASE_URL: process.env.REACT_APP_API_NATURE_BASE_URL,
  ENERGY_BASE_URL: process.env.REACT_APP_API_ENERGY_BASE_URL,
  SSO_SIGNUP: "/api/v1/sso/signup",
  SSO_FORGET_PASSWORD: "/api/v1/sso/forget-password",
  SSO_CONFIRM_FORGET_PASSWORD: "/api/v1/sso/confirm-forget-password",
  SSO_RESEND_CODE: "/api/v1/sso/resend-code",
  SSO_CONFIRM_PASSWORD: "/api/v1/sso/confirm-password",
  AUTH_LOGIN: "/api/v1/auth/login",
  AUTH_MULTI_LOGIN: "/api/v1/multi/auth/login",
  USER_DETAILS: "/api/v1/get/user/detail",
  DASHBOARD_URL: "/api/v1/get/dashboard_data",
  ASSET_MASTER_DATA: "/api/v1/asset_request/master",
  UPDATE_WALLET_ADDRESS: "/api/v1/update/wallet_address",
  ERC_20_VALIDATION: "/api/v1/erc20/token/validation",
  HOMEPAGE: "/api/v1/homepage",
  HOMEPAGE_CORPORATE: "/api/v1/homepage/corporate",
  GET_TOKEN_PRICE: "/api/v1/get/token/price",
  GET_FIAT_TOKEN_PRICE: "/api/v1/get/fiat/token/price",
  GENERATE_PAYMENT: "/api/v1/gateway/generate/payment-url",
  SUMSUB_ACCESS_TOKEN: "/api/v1/auth/sumsub_access_token",
  GET_ASSET_REQUEST: "/api/v1/get/asset_request_list",
  CREATE_PAYMENT: "/api/v1/gateway/create/payment",
  REJECT_ASSET_REQUEST: "/api/v1/get/reject_asset_request",
  CREATE_ASSET_REQUEST: "/api/v1/create/asset_request",
  BUY_TOKEN: "/api/v1/buy_token",
  BUY_HCT_TOKEN: "/api/v1/hct_token",
  HCT_REQUESTED: "/api/v1/hct/quant/store",
  CRYPTO_CONFIRM_PAYMENT: "/api/v1/create/confirmpayment",
  POST_TOKEN_LIST: "/api/v1/token/list",
  POST_TOKEN_DETAIL: "/api/v1/token/detail",
  KYC_STATUS: "/api/v1/dcom/kyc/status ",
  FOOTER: "/api/v1/footer",
  CONTACT_US: "/api/v1/contact/us",
  SEARCH_ASSET_REQUEST: "/api/v1/search/asset_request",
  ASSET_REQUEST_DETAIL: "/api/v1/get/asset_request_detail",
  GET_QRCODE: "/api/v1/get/mfa/qrcode",
  VERIFY_QRCODE: "/api/v1/validate/mfa/code",
  DISABLE_2FA_CODE: "/api/v1/disable/mfa/code",
  SUBSCRIBE_NEWSLETTER: "/api/v1/create/newsletter/subscribe",
  ACTIVATE_SUBSCRIBE_NEWSLETTER: "/api/v1/activate/newsletter/subscribe",
  UPDATE_PROFILE: "/api/v1/user/detail/update",
  MAP_DETAILS: "/api/v1/token/map/detail",
  UPDATE_USER_PROFILE: "/api/v1/user/detail/update",
  POST_ADD_DFENDER: "/api/v1/dcom/dfender",
  UPLOAD_USER_PHOTO: "/api/v1/image/upload",
  REDEMPTION_ORDER_MASTER: "/api/v1/redemptionorder/master",
  REDEMPTION_ORDER_CREATE: "/api/v1/redemptionorder/create",
  REDEMPTION_ORDER_LIST: "/api/v1/redemptionorder/order/list",
  BUY_TOKEN_ORDER_LIST: "/api/v1/order/list",
  DFENDER_ORDER_LIST: "/api/v1/dcom/getall/dfender",
  REDEMPTION_ORDER_GENERATE_OTP: "/api/v1/redemption_order/generate/otp",
  REDEMPTION_ORDER_SUBMIT_OTP: "/api/v1/redemption_order/submit/otp",
  REDEMPTION_ORDER_APPOINTMENT_OTP: "/api/v1/submit/appointment/date",
  DFENDER_ASSET: "/api/v1/defender/asset",
  REFERAL_CODE: "/api/v1/create/referal",
  TOKEN_HOLDING_DETAIL: "/api/v1/vesting_details",
};

export default URLS;
