import React, { useState, useEffect } from "react";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import ConfirmForgetPasswordForm from "../../components/shared/forms/confirm-forget-password-form";
import leafImg from "../../assets/images/leaf.png";
import ConfirmPasswordForm from "../../components/shared/forms/confirm-password-form";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../../assets/images/DcommLogo.png";
import { Col, Row } from "react-bootstrap";
import Dfender from "../../assets/images/sidebar/thc.png";

const ResetPassword = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/login");
  };
  if (isLoggedIn) {
    navigate("/dashboard");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Reset Password";
  }, []);
  return (
    <div className="login-page">
      <div className="flex-rows">
        <div className="column-img">
          <Row>
            <Col md={2}>
              <div className="logo">
                <Link to={"/"}>
                  <img src={Logo} title="" alt="" />
                </Link>
              </div>
            </Col>
            {/* <Col md={3}>
              {" "}
              <div className="logo2">
                <Link to={"/"}>
                  <img src={Dfender} title="" alt="" height={110} width={120} />
                </Link>
              </div>
            </Col> */}
          </Row>

          {/*<div className="img-box">
                    <h2>Very good works are waiting for you <br />Sign In</h2>
                    <img src={Leader} className="img1" title="" alt="" />
                </div>*/}
        </div>
        <ConfirmForgetPasswordForm />
      </div>
    </div>
  );
};

export default ResetPassword;
