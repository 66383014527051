/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import "../../../assets/css/banner.css";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";
import Bgimg from "../../../assets/images/banner.png";

const BlogList = ({ bannerSections }) => {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }

  return (
    <>
      {bannerSections &&
        <section className="blog">
          <div className="container">
              <div className="flex">
                {bannerSections &&
                  bannerSections.banner_image.map((blog, blog_key) => {
                    return (
                    <div className="box">
                        <figure>
                            <img src={PATH+blog.image_url} alt="" />
                            <div className="date">
                                <p>25</p>
                                <span>MAR</span>
                            </div>
                        </figure>
                        <div className="blog-text">
                            <a href="#" className="blog-btn blue-btn">Master News</a>
                            <a href="#" className="blog-title">{ReactHtmlParser(blog.heading)}</a>
                            <div className="text-right">    
                                <a href="#" className="read-btn">Read more.....</a>
                            </div>
                        </div>
                    </div>
                  );
                })}
              </div>
          </div>
        </section>
      }
    </>
  );
};

export default BlogList;
