import React, { useState } from "react";
import TableFilterProjects from "./table-filter-projects";
import {
  searchAssetRequest,
  searchEnergyAssetRequest,
} from "../../core/services/api/nft";
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetRequest,
  setSelectedCrypto,
} from "../../redux/actions/nft-action";
import paginationSubmit from "../../hooks/pagination";
import {
  setStartDate,
  setEndDate,
} from "../../redux/actions/pagination-action";
const NFTTableFilter = ({ page }) => {
  const { selectedCrypto } = useSelector((state) => state.nftReducer);
  const { currentPage, pageNumber } = useSelector(
    (state) => state.paginationReducer
  );
  const dispatch = useDispatch();
  const [data, setData] = useState({ start: "", end: "" });
  const handleChange = (event) => {
    setData((preValues) => ({
      ...preValues,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name == "start") {
      dispatch(setStartDate(event.target.value));
    } else {
      dispatch(setEndDate(event.target.value));
    }
  };

  const handleCancel = () => {
    setData({
      start: "",
      end: "",
    });
    dispatch(setAssetRequest(null));
    dispatch(setSelectedCrypto(1));
  };

  return (
    <>
      <div className="col-lg-3">
        <label className="tb-label" htmlFor="specificSizeInputName">
          Start Date
        </label>
        <input
          type="date"
          className="form-control tb-form"
          id="specificSizeInputName"
          placeholder="e.g 09-09-2021"
          onFocus="(this.type='date')"
          name="start"
          value={data.start}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="col-lg-3">
        <label className="tb-label" htmlFor="specificSizeInputGroupUsername">
          End Date
        </label>
        <div className="input-group">
          <input
            type="date"
            className="form-control tb-form"
            id="specificSizeInputGroupUsername"
            placeholder="e.g 09-09-2021"
            name="end"
            value={data.end}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="col-auto d-flex align-items-end mt-3">
        <button
          onClick={() =>
            // eslint-disable-next-line implicit-arrow-linebreak
            paginationSubmit(
              data.start,
              data.end,
              currentPage,
              pageNumber,
              selectedCrypto,
              dispatch
            )
          }
          className="btn tb-submit crypto-dinar-blue-bg text-white"
        >
          Submit
        </button>
      </div>
      <div className="col-auto d-flex align-items-end mt-3">
        <button onClick={handleCancel} className="btn tb-clear">
          Clear
        </button>
      </div>
    </>
  );
};
export { NFTTableFilter };
