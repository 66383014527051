import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import userProfile from "../../assets/icons/user-profile.png";
import userProfile from "../../assets/images/sidebar/profileicon.svg";
import userProfileIcon from "../../assets/images/profile-icon.png";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import {
  setStartKYC,
  setSuccessMessage,
  setErrorMessage,
} from "../../redux/actions";
import {
  clearLocalStorage,
  getAWSToken,
  getTFA,
  getUserName,
} from "../../core/utils/token";
import NotificationIcon from "../../assets/images/notification-icon.svg";
import { KYC_STATUS } from "../../utils/constants/kyc-types";
import { setUserLogout } from "../../redux/actions/user-action";
import ReferalCodePopUp from "./referal-code";
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { userDetails } = useSelector((state) => state.userReducer);
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const { isUserKYCDone, userKYCStatus, rejectedType } = useSelector(
    (state) => state.kycReducer
  );
  const name = getUserName();
  const [userImage, setUserImage] = useState("");
  const [path, setPath] = useState("");

  const startKYC = () => {
    dispatch(setStartKYC(true));
  };
  const handleLogout = () => {
    clearLocalStorage();
    dispatch(setUserLogout());
    navigate("/login");
  };

  const handleRedirect = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    setPath(window.location.pathname);
    if (userDetails.userImage) {
      setUserImage(userDetails.userImage);
    } else {
      setUserImage(userProfile);
    }
  }, [userDetails]);
  const showModal = () => {
    console.log("CALLED");
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent">
        <ul className="navbar-nav font-16 font-w-500 loggedin-header">
          {path === "/user-profile" && (
            <li className="nav-item">
              <div className="header-dropdown">
                <button onClick={showModal}>Generate Referral Code</button>
              </div>
            </li>
          )}
          <li className="nav-item">
            <Dropdown>
              <Dropdown.Toggle className="header-dropdown">
                {/*<img src={userProfileIcon} className="profile-icon"/>{" "}*/}
                <small className="font-14 font-w-400">{name}</small>{" "}
                <img src={userImage} className="header-user-image" />{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>
                  <i className="fa fa-sign-out" aria-hidden="true"></i> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
      <Modal
        show={isOpen}
        onHide={hideModal}
        centered
        className="referal-modal">
        <Modal.Body>
          <ReferalCodePopUp hide={hideModal} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
