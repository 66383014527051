/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import "../../assets/css/banner.css";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import ImageLeftContentRightBannerCard from "../modals/image-left-content-right-banner-card";
import Bgimg from "../../assets/images/banner.png";

const Faq = ({ faqs, faq_title }) => {
  const location  = useLocation();
  let PATH        = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }

  let category_list = [...new Set(faqs.map(item => item.category))];

  return (
    <>
      {faqs && faqs.length > 0 ? (
        <>
          <section className="faq-section">
             <div className="container">
                <div className="title-head text-center">
                   {ReactHtmlParser(faq_title)}
                </div>
                <div className="flex">
                   <div className="col-left">
                      <ul className="tab">
                          {category_list &&
                            category_list.map((categoryList, list_key) => {
                            return (
                              <li key={list_key} className={list_key === 0 ? "active" : ""} deta-id={categoryList.replace(/\s+/g, '-').toLowerCase()}>{categoryList}</li>
                              );
                          })}
                      </ul>
                   </div>
                   <div className="col-right">
                      <div className="acc-wrapper">
                        {category_list &&
                          category_list.map((categoryList, list_key1) => {
                            return (
                            <div key={list_key1} className="accordion" id={categoryList.replace(/\s+/g, '-').toLowerCase()}>
                              {faqs.map((faq, faq_key) => {
                                return (
                                <>
                                {categoryList === faq.category &&
                                  <div className={"acc-inner "+ (faq_key === 0 ? "active" : "")} key={faq_key}>
                                     <div className="acc-title">
                                        <h3>{ReactHtmlParser(faq.question)}</h3>
                                     </div>
                                     <div className="acc-content">
                                        {ReactHtmlParser(faq.answer)}
                                     </div>
                                  </div>
                                }
                                </>
                                );
                                })}
                            </div>
                            );
                        })}
                      </div>
                   </div>
                </div>
             </div>
          </section>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Faq;
