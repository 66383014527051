/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import MaleWorkingLaptop from "../../../assets/images/male-working-laptop.png";
const WhitePaper = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  return (
    <>
      {bannerSections &&
        <section className="whitepaper">
            <div className="container">
                <div className="text-center">
                    {ReactHtmlParser(bannerSections.title)}
                </div>
                <div className="flex align-center">
                    <div className="col-left">
                        {ReactHtmlParser(bannerSections.banner_text)}
                    </div>
                    <div className="col-right">
                        <img src={PATH+bannerSections.image_url} alt="" />
                    </div>
                </div>
            </div>
        </section>
      }
    </>
  );
};

export default WhitePaper;
