import React from 'react';
import { useLocation } from 'react-router';
import URLS from '../../configs/api-end-points';
import { PROJECT_TYPES } from '../../utils/constants/project-types';

const IconCardBg = ({ isActive, icon, iconText, mainClassses }) => {
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <>
        <div className={ mainClassses }>
            <div className="card-body mt-lg-3 mb-lg-3">
                <img src={`${PATH}${icon}`} className={isActive ? "sunset-39E38833-bg border-radidus-50 p-lg-3" : "sunset-FFEE50-bg border-radidus-50 p-lg-3"} />
                <p className="font-18 mt-2 font-656565">{iconText}</p>
            </div>
        </div>
        </>
    );
};

export default IconCardBg;
