import React, { useEffect } from "react";
import RedemptionTabForms from "./redemption-tab-form";
import CryptoHeader from "./crypto-header";

const RedemptionDashboard = () => {
  return (
    <div>
      <div className="dashboard-form my-5 mx-1 mx-md-5">
        <div className="row">
          <CryptoHeader
            header="Redemption Fee"
            subHeading="An overview of cryptocurrencies and markets"
          />
        </div>
        <div className="row mx-0">
          <RedemptionTabForms />
        </div>
      </div>
    </div>
  );
};

export default RedemptionDashboard;
