/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import Eco1 from "../../../assets/images/eco1.png";
import Eco2 from "../../../assets/images/eco2.png";
import Eco3 from "../../../assets/images/eco3.png";

const Ecosystem = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL; 
  return (
    <>
      {bannerSection &&
        <section className="eco-section">
          <div className="container">
              <div className="vertical-heading">
                  {ReactHtmlParser(bannerSection.title)}
              </div>
              <div className="eco-inner">
              {bannerSection &&
                bannerSection.banner_image.map((bannerImage, banner_key) => {
                  return (
                  <div className="flex" key={banner_key}>
                      <div className="col-img">
                          <img src={PATH+bannerImage.image_url} alt="" />
                      </div>
                      <div className="col-text">                          
                        {ReactHtmlParser(bannerImage.heading)}
                        {ReactHtmlParser(bannerImage.text)}
                      </div>
                  </div>
                  );
                })}
              </div>
          </div>
      </section>
      }
    </>
  );
};

export default Ecosystem;
