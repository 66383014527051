import React from "react";
import ThemeContainer from "../../containers/theme-container";
import "../../assets/css/dashboard-style.css";
import "../../assets/css/hover.css";
import LeftMenu from "../../components/shared/left-menu";
import KYCVerificationForm from "../../components/shared/kyc-verification-form";
import { getUserName } from "../../core/utils/token";
import CryptoHeader from "../../components/shared/crypto-header";
import { KYC_STATUS } from "../../utils/constants/kyc-types";
import { useSelector } from "react-redux";
import KYCHeader from "../../components/shared/kyc/kyc-header";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
const KYCVerification = () => {
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const userName = getUserName();
  const { isUserKYCDone, userKYCStatus } = useSelector(
    (state) => state.kycReducer
  );
  if (!isLoggedIn) {
    navigate("/login");
  }
  return (
    <ThemeContainer type="private" module="nature" pageTitle="KYC Verification">
      <div className="dcomm-bg-layer min-vh-80">
        <div className="row dashboard-tiles m-0 px-1 py-1 px-md-5 py-md-5 mt-md-5 kyc-verification">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 col-sm-12">
            <label className="crypto-dinar-white mt-5 justify-center mb-3 ">
              <h3>KYC must be done for THCNFT.</h3>
            </label>
            <div className="w-100 kyc">
              {isUserKYCDone ||
              userKYCStatus ||
              !isUserKYCDone ||
              !userKYCStatus ? (
                <>
                  <KYCHeader
                    userKYCStatus={userKYCStatus}
                    isUserKYCDone={isUserKYCDone}
                  />

                  <KYCVerificationForm />
                </>
              ) : (
                <div className="text-center">
                  <small>
                    <Spinner animation="border" />
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </ThemeContainer>
  );
};

export default KYCVerification;
