import React, { useState, useEffect } from "react";
import { getFiatPriceToken, getPriceToken } from "../../core/services/api/nft";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const CalculatorForm = ({ crypto, assetMaster }) => {
  const { isLoggedIn }    = useSelector((state) => state.userReducer);
  const [values, setValues] = useState({
    token: null,
    cryptoToken: null,
  });
  const [selected, setSelected] = useState(
    crypto === "fiat"
      ? assetMaster.exchange_data[0].supported_fiat_currencies[0]
      : assetMaster.currency[0]
  );
  const getTokenPrice = async (obj) => {
    const datas =
      crypto === "crypto"
        ? {
            crypto_currency_id: obj ? obj.id : selected.id,
            crypto_asset_id: assetMaster.crypto_asset[0].id,
            crypto_name: obj ? obj.name : selected.name,
            token:
              values.input == 2
                ? 0
                : values.token
                ? parseFloat(values.token, 10)
                : 0,
            crypto_amount:
              values.input == 1
                ? 0
                : values.cryptoToken
                ? parseFloat(values.cryptoToken, 10)
                : 0,
          }
        : {
            currency_id: obj ? obj.id : selected.id,
            crypto_asset_id: assetMaster.crypto_asset[0].id,
            currency: obj ? obj.name : selected.name,
            token:
              values.input == 2
                ? 0
                : values.token
                ? parseInt(values.token, 10)
                : 0,
            fiat_amount:
              values.input == 1
                ? 0
                : values.cryptoToken
                ? parseInt(values.cryptoToken, 10)
                : 0,
          };
    if (datas.crypto_amount == 0 && datas.token == 0) {
      return;
    }
    const result =
      crypto === "crypto"
        ? await getPriceToken(datas)
        : await getFiatPriceToken(datas);
    if (result.status) {
      setValues((preValues) => ({
        ...preValues,
        token: values.input == 1 ? values.token : result.token.toString(),
        cryptoToken:
          values.input == 2
            ? values.cryptoToken
            : crypto === "crypto"
            ? result.crypto_amount.toString()
            : result.amount.toString(),
        usdPrice: result.usd_price,
      }));
    }
    /*
     * setTimeout(() => {
     *   getTokenPrice();
     * }, masterData.exchange_data[0].tsp_price_valid_in_minutes);
     */
  };
  const handleChange = (event) => {
    setValues((preValues) => ({
      ...preValues,
      [event.target.name]: event.target.value,
    }));
  };
  const handleTokenInput = () => {
    setValues((preValues) => ({
      ...preValues,
      cryptoToken: 0,
      input: 1,
    }));

    getTokenPrice();
  };
  const handleCryptoInput = () => {
    setValues((preValues) => ({
      ...preValues,
      token: 0,
      input: 2,
    }));
    getTokenPrice();
  };
  const handleSelectChange = (e) => {
    const obj = JSON.parse(e.target.value);
    setSelected(obj);
    getTokenPrice(obj);
  };
  useEffect(() => {
    setSelected(
      crypto === "fiat"
        ? assetMaster.exchange_data[0].supported_fiat_currencies[0]
        : assetMaster.currency[0]
    );
    setValues({
      token: 0,
      cryptoToken: 0,
    });
  }, [crypto]);
  return (
    <>
      {values && assetMaster && (
        <>            
           <div className="field-row">
              <p>You Have</p>
              <input
                type="text"
                className="form-control crypto-dinar-blue bg-gray "
                id="inputEmail4"
                name="token"
                onChange={handleChange}
                onKeyUp={handleTokenInput}
                value={values.token}
              />
              <label>1 Crypto Dinar =  $64 USD</label>                        
           </div>
           <div className="field-row">
              <p>You Get</p>
              <div className="curr-type">
                 {crypto === "fiat" ? (
                  <select onChange={handleSelectChange}>
                    {assetMaster.exchange_data[0].supported_fiat_currencies
                      .length > 0 &&
                      assetMaster.exchange_data[0].supported_fiat_currencies.map(
                        (val, index) => (
                          <option value={JSON.stringify(val)} key={index}>
                            {val.name}
                          </option>
                        )
                      )}
                  </select>
                ) : (
                  <select onChange={handleSelectChange}>
                    {assetMaster.currency &&
                      assetMaster.currency.map((val, index) => (
                        <option value={JSON.stringify(val)} key={index}>
                          {val.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              <input
                type="text"
                className="form-control crypto-dinar-blue bg-gray "
                id="inputEmail4"
                name="cryptoToken"
                value={values.cryptoToken}
                onChange={handleChange}
                onKeyUp={handleCryptoInput}
              />
              <label>1 USD =  0.64 Crypto Dinar</label>                        
           </div>
           <div className="buynow">
              {isLoggedIn ? (
                <Link to="/buyToken" className="btn btn-yellow">Buy Now</Link>
              ) : (
                <Link to="/login" className="btn btn-yellow">Login</Link>
              )}
           </div>             
           
        </>
      )}
    </>
  );
};
export default CalculatorForm;
