import React from "react";

const ProjectCryptoForm = () => {
  return (
    <>
      <div
        className="tab-pane fade show px-4 active"
        id="crypto-home"
        role="tabpanel"
        aria-labelledby="crypto-home-tab"
      >
        <form className="row g-3 my-3">
          <div className="row">
            <div className="col-md-8">
              <label for="inputEmail4" className="form-label label-gr">
                Get
              </label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control bg-gray"
                  id="inputEmail4"
                />
              </div>
              <label for="inputEmail4" className="form-label label-green">
                USD price: $0
              </label>
            </div>
            <div className="col-md-4">
              <label for="inputPassword4" className="form-label label-gr">
                Token
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control bg-gray"
                  id="inputPassword4"
                />
                <div className="input-group-text bg-gr">
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <label for="inputEmail4" className="form-label label-gr">
                Pay
              </label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control bg-gray"
                  id="inputEmail4"
                />
                <div className="input-group-text bg-gr">
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </div>
              </div>
              <label for="inputEmail4" className="form-label label-red">
                Minimum Sunset Energy NFT to purchase is 1.63672
              </label>
            </div>
            <div className="col-md-4">
              <label for="inputPassword4" className="form-label label-gr">
                Currency
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control bg-gray"
                  id="inputPassword4"
                />
                <div className="input-group-text bg-gr">
                  <i className="fa fa-circle" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-grid">
            <button
              type="submit"
              style={{ width: "100%" }}
              className="btn btn-primary green-btn"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectCryptoForm;
