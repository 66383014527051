import React, { useState, useEffect } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { getKYCAccessToken } from "../../../core/services/api/kyc";
import { useDispatch } from "react-redux";
import { setStartKYC } from "../../../redux/actions";

const SubsubKYC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [sumSubAccessToken, setSumSubAccessToken] = useState("");

  const fetchToken = async () => {
    const result = await getKYCAccessToken();
    if (result && result.status)
      setSumSubAccessToken(result.sumsub_access_token);
    setIsLoading(false);
  };

  const expirationHandler = () => {
    alert("Session expired, try again!");
    dispatch(setStartKYC(false));
  };

  const errorHandler = () => {
    alert("Error occured, try again!");
    dispatch(setStartKYC(false));
  };

  const messageHandler = (message) => {
    //console.log(message);
    if (message === "idCheck.onApplicantSubmitted") {
      alert("Documents submitted for KYC");
      dispatch(setStartKYC(false));
    }
  };

  const loadBlockpassWidget = () => {
    const blockpass = new window.BlockpassKYCConnect(
      "dcomm_kyc_e44b9", // service client_id from the admin console
      {
        refId: "001", // assign the local user_id of the connected user
      }
    );

    blockpass.startKYCConnect();

    blockpass.on("KYCConnectSuccess", () => {
      //add code that will trigger when data have been sent.
    });
  };
  useEffect(() => {
    fetchToken();
    loadBlockpassWidget();
  }, []);

  if (isLoading) return <h3>Loading...</h3>;
  return <h1>Loading ...</h1>;
  //   return <SumsubWebSdk
  //   accessToken={sumSubAccessToken}
  //   expirationHandler={expirationHandler}
  //   onMessage={messageHandler}
  //   onError={errorHandler}
  // />;
};

export default SubsubKYC;
