/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import MaleWorkingLaptop from "../../../assets/images/male-working-laptop.png";
const TechnologyUsed = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  let headings = [...new Set(bannerSections.widget.map(item => item.head))];
  return (
    <>
      {bannerSections &&
        <section className="technologi-section">
          <div className="container">
              <div className="title-head text-center">
                  {ReactHtmlParser(bannerSections.title)}
              </div>
              <div className="techno-tab">
                  <ul>
                      {headings &&
                        headings.map((heading, list_key) => {
                        return (
                          <li key={list_key} className={list_key === 0 ? "active" : ""} deta-id={heading.replace(/\W/g, '-').toLowerCase()}>
                            <div className="wrapper">
                                {heading === "Polygon" ? (
                                    <p>Blockchain Protocol is Used</p>
                                  ) : (
                                    <p>Token Standard</p>
                                  )}
                                <span>{heading}</span> 
                            </div>
                          </li>
                          );
                      })}
                  </ul>
              </div>
              <div className="techno-content">
                {headings &&
                    headings.map((heading, list_key1) => {
                      return (
                  <div key={list_key1} className="techno-wrapper" id={heading.replace(/\W/g, '-').toLowerCase()}>
                      <h3>Benifits of {heading}</h3>
                      <ul>
                          {bannerSections.widget.map((banner, index_key) => {
                            return (
                              <>
                                {heading === banner.head &&
                                  <li key={index_key}>{ReactHtmlParser(banner.value)}</li>
                                }
                              </>
                            );
                          })}
                      </ul>
                  </div>
                  );
                })}
              </div>
          </div>
      </section>
      }
    </>
  );
};

export default TechnologyUsed;
