import React from 'react';
import GraphTableTabs from './graph-table-btton';
import OtherNFTProjects from './other-nft-projects';

const EnergyDasboardSummary = () => {
    return (
        <div className="dasboard-numbers my-4 mx-1 mx-md-5">
            <div className="row p-3">
                <div className="col-lg-4 col-md-4 my-3 my-md-0">
                    <div className="number-inner text-center">
                        <h2 className="number-head">USD 205,223,343</h2>
                        <p className="number-text m-0">SNT Market Cap</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 my-3 my-md-0">
                    <div className="number-inner text-center">
                        <h2 className="number-head">1002,205,223,343</h2>
                        <p className="number-text m-0">SNT Total Circulating Supply</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 my-3 my-md-0">
                    <div className="number-inner text-center">
                        <h2 className="number-head">$1.00</h2>
                        <p className="number-text m-0">SNT Price</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnergyDasboardSummary;
