import React, { useState, useEffect } from "react";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import SignRegisterTabs from "../../components/shared/signup-and-register";
import UserRegistrationForm from "./../../components/shared/forms/user-registration-form";
import loginSide from "../../assets/images/login-side.png";
import Logo from "../../assets/images/DcommLogo.png";
import Women from "../../assets/images/smiling-modern-woman.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Dfender from "../../assets/images/sidebar/thc.png";

const SignUp = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  if (isLoggedIn) {
    navigate("/dashboard");
  }
  useEffect(() => {
    document.title = "Signup";
    localStorage.clear();
  }, []);
  return (
    <>
      <div className="login-page">
        <div className="flex-rows">
          <div className="column-img">
            <Row>
              <Col md={2}>
                <div className="logo">
                  <Link to={"/"}>
                    <img src={Logo} title="" alt="" />
                  </Link>
                </div>
              </Col>
              {/* <Col md={3}>
                {" "}
                <div className="logo2">
                  <Link to={"/"}>
                    <img src={Dfender} title="" alt="" height={110} width={120} />
                  </Link>
                </div>
              </Col> */}
            </Row>

            {/*<div className="img-box">
                    <h2>Very good works are waiting for you <br />Sign In</h2>
                    <img src={Leader} className="img1" title="" alt="" />
                </div>*/}
          </div>
          <div className="column-text dcomm-login-form-layer">
            <div className="log-menu text-right">
              <ul>
                <li>
                  <NavLink to={"/login"}>Sign In</NavLink>
                </li>
                <li>
                  <NavLink to={"/signup"} className="active">
                    Register
                  </NavLink>
                </li>
              </ul>
            </div>

            <UserRegistrationForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
