import React, { useState } from "react";
import "../../assets/css/header.css";
import useForm from "../../hooks/use-form";
import { validateAddDFender } from "../../core/validations/user-validations";
import { postAddDFender } from "../../core/services/api/nft";
import useAlert from "../../hooks/use-alert";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";

const AddDFenderForm = ({ data }) => {
  const { showAlert } = useAlert();
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();

  const handleAddDFender = async () => {
    const res = await postAddDFender({
      wallet_id: values.walletId,
      node_id: values.nodeId,
      referral_code: values.refCode ? values.refCode : "",
      discord_username: values.discordUsername ? values.discordUsername : "",
    });
    if (res.success) {
      showAlert("SUCCESS", "Success", res.message);
      navigate("/dfender");
    } else {
      showAlert("ERROR", "Error", res.message);
    }
  };

  const { values, errors, handleSubmit, handleChange } = useForm(
    handleAddDFender,
    validateAddDFender
  );
  const getHeaders = () => {
    const headers = {};
    const apiKey = "Content-Type";
    headers[apiKey] = "application/json";
    const apiKey2 = "Authorization";
    headers[apiKey2] =
      "HMAC-SHA256 5c95784d5b7ff71f538b9827d8f2c12f40ea9e995c1be4d19f206eb1d2c1f3a1";
    return headers;
  };
  const handleChangeWalletId = async (id) => {
    const url = process.env.REACT_APP_DCOM_WALLET_API;
    const data = {
      nodeId: id.target.value,
    };
    let response = {};
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: getHeaders(),
    })
      .then(async (result) => {
        response = await result.json();
      })
      .catch((error) => {
        console.log(error);
      });
    let e = {
      target: {
        name: "walletId",
        value: "",
      },
    };
    if (response.walletId === undefined) {
      showAlert("ERROR", "Error", response.message);
      setCheck(false);
    } else {
      showAlert("SUCCESS", "Success", "Valid Node ID");
      setCheck(true);
      e.target.value = response.walletId[0];
    }
    handleChange(e);
  };
  return (
    <>
      {data && (
        <>
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <label className="crypto-dinar-white justify-center">
              Node must be active on Dcomm Blockchain before submitting the
              DFender setup screen.
              <a
                className="cursor-pointer"
                onClick={() => {
                  window.open(
                    "https://explorer.dcomm.network/?network=mainnet"
                  );
                }}>
                <LaunchIcon
                  sx={{ color: "#b352ff", marginLeft: "10px" }}
                  fontSize="small"
                  className="ml-3"
                />
              </a>
            </label>
            <div className="w-100 kyc my-4">
              <div className="kyc-form">
                <div className="kyc-form-head mx-1">
                  <h2 className="crypto-dinar-white font-30">DFender</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-9 mb-3">
                      <div className="input-group">
                        <input
                          type="text"
                          name="nodeId"
                          value={
                            values.nodeId === undefined ? "" : values.nodeId
                          }
                          className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                          onChange={(e) => {
                            handleChange(e);
                            handleChangeWalletId(e);
                          }}
                          placeholder="Node Id"
                          maxLength={40}
                        />
                        {errors.nodeId && (
                          <p className="text-danger">
                            <small>{errors.nodeId}</small>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <button
                        type="submit"
                        className={`${
                          check ? "buy-coin-btn-2" : "buy-coin-btn-disable-2"
                        } w-100`}
                        disabled={check ? false : true}>
                        Submit
                      </button>
                    </div>
                    {/* <div className="col-md-3 wallet ">
                      <a
                        onClick={() => {
                          window.open("https://app.zeeve.io/");
                        }}
                        target="_blank">
                        <button
                          type="button"
                          className="font-919191 button-tab-active w-100  no-bdr h-40 create-new cursor-pointer">
                          Setup Node
                        </button>
                      </a>
                    </div> */}
                    {/* <div className="col-md-12 mb-3">
                      <div className="input-group">
                        <input
                          disabled
                          type="text"
                          name="walletId"
                          value={
                            values.walletId === undefined ? "" : values.walletId
                          }
                          className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                          onChange={handleChange}
                          placeholder="Wallet Id"
                          maxLength={50}
                        />
                        {errors.walletId && (
                          <p className="text-danger">
                            <small>{errors.walletId}</small>
                          </p>
                        )}
                      </div>
                    </div> */}

                    <div className="col-md-12 mb-3">
                      <div className="input-group">
                        <input
                          type="text"
                          name="refCode"
                          value={
                            values.refCode === undefined ? "" : values.refCode
                          }
                          className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                          onChange={handleChange}
                          placeholder="Referral Code (Optional)"
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="input-group">
                        <input
                          type="text"
                          name="discordUsername"
                          value={
                            values.discordUsername === undefined
                              ? ""
                              : values.discordUsername
                          }
                          className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                          onChange={handleChange}
                          placeholder="Discord Username (Optional)"
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className={`${
                          check ? "buy-coin-btn" : "buy-coin-btn-disable"
                        } w-100`}
                        disabled={check ? false : true}>
                        Submit
                      </button>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </>
      )}
    </>
  );
};

export default AddDFenderForm;
