/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
const TableComparison = ({ bannerSection }) => {
  let headings = [...new Set(bannerSection.widget.map(item => item.head))];
  let data        = bannerSection.widget;
  let tableArray  = [];
  let tmpArray    = [];
  let head        = "";
  let oldHead     = "";
  let i = 0;
  let j = 0;
  data.forEach(function (item, index) {
      head = item.head;
      if( oldHead == "" ){
        oldHead = item.head;
      }
      if(typeof tableArray[i] === 'undefined'){
        tableArray[i] = [];
      }
      if(head == oldHead ) {
        tableArray[i][j] = item.value;
        i++;
      } else {
        i=0;       
        j++; 
        tableArray[i][j] = item.value;
        i++; 
      }

      oldHead = head;
  });
  
  const executeHtml = (tableArray, headings) => {
    let length  = tableArray.length;
    let length1 = headings.length;  
    const row = [];
    for ( var i = 0; i < length; i++ ) {
      if(tableArray[i].length){   
        row.push(<tr>{secondPart(length1, i)}</tr>);
      }
    }
    return row;
  };
  const secondPart = (length1, i) => {
    const row = [];
    for ( var j = 0; j < length1; j++ ) {
      row.push(<td>{tableArray[i][j]}</td>);
    }
    return row;
  };

  return (
    <section className="comparison-section">
       <div className="container">
          <div className="title-head text-center">
             {ReactHtmlParser(bannerSection.title)}
          </div>
          <div className="comparison-deta">
             <table style={{ width: "100%" }} cellPadding="0" cellSpacing="0" border="0">
                <thead>
                   <tr>
                      {headings &&
                        headings.map((heading, list_key) => {
                        return (
                          <th key={list_key}>{heading}</th>
                          );
                      })}
                   </tr>
                </thead>
                <tbody>
                  {executeHtml(tableArray, headings)}
                </tbody>                
             </table>
          </div>
          <p>{ReactHtmlParser(bannerSection.banner_text)}</p>
       </div>
    </section>
  );
};

export default TableComparison;
