/* eslint-disable new-cap */
import React from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

const PartnerCard = ({ partner }) => {
  return (
    <>
      {partner && (
        <>
          <div className="mt-4 text-center">
            {partner.image_url && (
              <img
                src={`${URLS.SERVER_IMAGE_FOLDER_URL}${partner.image_url}`}
                height="200px"
              />
            )}
          </div>
          <br />
          <div className="font-12 font-w-400 opacity-90 text-center">
            {ReactHtmlParser(partner.text)}{" "}
          </div>
        </>
      )}
    </>
  );
};

export default PartnerCard;
