import React, { useState } from "react";
import "../../assets/css/header.css";
import useForm from "../../hooks/use-form";
import { validateAddDFender } from "../../core/validations/user-validations";
import { postAddDFender } from "../../core/services/api/nft";
import useAlert from "../../hooks/use-alert";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";

const FaqPageForm = ({ data }) => {
  return (
    <>
      {data && (
        <>
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="kyc">
              <div className="kyc-form">
                <div className="kyc-form-head mx-1">
                  <h2 className="crypto-dinar-white font-30">
                    {" "}
                    {data.faq_heading}
                  </h2>
                </div>
                <div className="row">
                  {data &&
                    data.faq_items &&
                    data.faq_items.map((info) => (
                      <div className="col-md-12 mb-3">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "white" }} />
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography>{info.heading}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              {info.description}
                              <a
                                onClick={() => {
                                  window.open(info.ref_link);
                                }}>
                                <LaunchIcon
                                  sx={{ color: "#b352ff", marginLeft: "10px" }}
                                  fontSize="small"
                                  className="ml-3"
                                />
                              </a>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2"></div>
        </>
      )}
    </>
  );
};

export default FaqPageForm;
