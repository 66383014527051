import React from "react";
import graph from "../../assets/images/graph2.JPG";

const DasboardOrderHistory = ({ data }) => {
  return (
    <>
      <div className="crypto-dinar-blue font-30 mx-0 mb-2 mb-md-1 mt-4 mt-md-1 text-left">
        <h1 style={{ marginLeft: "70px" }}>Order History</h1>
      </div>
      <div className="order-history mx-0 my-3 p-4">
        <div className="dasboard-numbers my-4 mx-1 mx-md-5">
          <div className="row p-3">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
              <div className="number-inner text-center">
                <h2 className="order-head">{data.snt_tokens}</h2>
                <p className="order-text m-0 crypto-dinar-blue">SNT Tokens</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
              <div className="number-inner text-center">
                <h2 className="order-head">$ {data.total_investments}</h2>
                <p className="order-text m-0 crypto-dinar-blue">
                  Total Investments
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
              <div className="number-inner text-center">
                <h2 className="order-head">$ {data.current_valuation}</h2>
                <p className="order-text m-0 crypto-dinar-blue">
                  Total Valuation
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 my-3 my-md-0">
              <div className="number-inner text-center">
                <h2 className="order-head-pl crypto-dinar-blue">
                  $ {data.profit_or_loss}
                </h2>
                <p className="order-text-pl m-0 ">Profit/Loss</p>
              </div>
            </div>
          </div>
        </div>
        <div className="orderhistory-graph my-4 mx-1 mx-md-5">
          <div className="pt-4 ps-4">
            <h4 className="">Monthly Investment</h4>
          </div>
          <img
            src={graph}
            alt=""
            srcset=""
            className="orderhistory-graph-img my-5 p-2"
          />
        </div>
      </div>
    </>
  );
};

export default DasboardOrderHistory;
