import React, { useEffect, useState } from 'react';
import ContactUsForm from '../../components/shared/forms/contact-us-form';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/contact-us.css";
import Bgimg from "../../assets/images/banner.png";
import GoogleMap from '../../components/modals/google-map';
import ReactHtmlParser from "react-html-parser";
import URLS from "../../configs/api-end-points";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
    const apiUrl = URLS.NATURE_BASE_URL;
    const [bannerContent, setBannerContent] = useState("");
    const [bannerImage, setBannerImage] = useState("");
    const [info, setInfo] = useState("");
    const [address, setAddress] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const fetchPageData = () => {
        fetch(apiUrl + "/api/v1/cms/pages/contact")
        .then((response) => response.json())
        .then((data) => { 
            console.log("Data", data)
          if (data.status) {
            setBannerContent(data.banner_content);
            setBannerImage(data.banner_image);
            setAddress(data.banner_images[0]);
            setInfo(data.banner_images[1]);
            setIsLoading(false);
          } else {
            console.log(data);
            setIsLoading(false);
          }
        });
      };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPageData();
    }, []);

    useEffect(() => {    
        navigate("/login");
    }, []);

    return (
        <ThemeContainer type="public" pageTitle="Contact us">
        {!isLoading ? (
          <>
            <section className="contact-banner" style={{ backgroundImage: `url(${Bgimg})` }}>
                <div className="container">
                    <div className="text-center benner-ext">
                        {ReactHtmlParser(bannerContent)}
                    </div>
                </div>
            </section>
            <ContactUsForm />
            <section className="contact-info">
                <div className="container">
                    <div className="flex">
                        <div className="col-left">
                            <div className="office">
                                {ReactHtmlParser(address.text)}
                            </div>
                            <div className="contact">
                                {ReactHtmlParser(info.text)}
                            </div>
                        </div>
                        <div className="col-right google-map">
                            {/*<GoogleMap styles={{ width: "40%", height: "20%" }}/>*/}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14009.38399003884!2d77.35567353198255!3d28.61939051876582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5672e20faab%3A0x14d3701f987bf606!2sStellar%20IT%20Park!5e0!3m2!1sen!2sin!4v1650450542446!5m2!1sen!2sin" style={{ border:0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
        ) : (
            <div className="Loader">
                <small><Spinner animation="border" /></small>
            </div>
        )}
        </ThemeContainer>
    );
};
export default ContactUs;
