/* eslint-disable new-cap */
import React from "react";
import URLS from "../../configs/api-end-points";
import CountEntryCard from "./count-entry-card";
import ReactHtmlParser from "react-html-parser";
import { PROJECT_TYPES } from "../../utils/constants/project-types";
import { useLocation } from "react-router";

function ImageLeftContentRightBannerCard({ bannerSection, classes }) {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }
  console.log(bannerSection);
  return (
    <div className={classes}>
      <div className="card border-radidus-16">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              {bannerSection &&
                bannerSection.widget &&
                bannerSection.widget.length > 0 && (
                  <div className="position-absolute w-100 row">
                    <div className="col-0 col-md-1"></div>
                    <div className="col-12 col-sm-12 col-md-10 pt-3 pt-md-4 px-4 px-md-0">
                      <CountEntryCard
                        widgets={bannerSection.widget}
                        titleColor="font-00A14B"
                      />
                    </div>
                    <div className="col-0 col-md-1"></div>
                  </div>
                )}
              <img
                src={`${PATH}${bannerSection.image_url}`}
                className="w-100 h-100"
              />
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
              {ReactHtmlParser(bannerSection.banner_text)}
              {/* {bannerSection.banner_text} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageLeftContentRightBannerCard;
