import React, { useState, useEffect } from "react";
import NFTMessageCrypto from "./message-crypto";
import NFTMessageFiat from "./message-fait";
import { useSelector } from "react-redux";
import CryptoCard from "../../assets/images/cryptoCard.svg";
import CryptoLogo from "../../assets/images/crypto-card-logo.svg";
import CardOverlay from "./addDFender";
const RedemptionMessage = ({ message }) => {
  return (
    <>
      {message !== {} && (
        <>
          <div className="row px-4">
            <div
              className="form-discount"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RedemptionMessage;
