import React, { useState, useEffect } from "react";
import {
  sSOConfirmForgetPassword,
  sSOResendCode,
} from "../../../core/services/api/user";
import { validateConfirmForgetPassword } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { useSelector } from "react-redux";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import EmailConfirmIcon from "../../../assets/images/forgotPasswordIcon.svg";
import useAlert from "./../../../hooks/use-alert";
import { useNavigate } from "react-router-dom";
import LockIcon from "../../../assets/images/icon-lock.png";
import BackIcon from "../../../assets/images/icon-back.png";
import OtpInput from "react-otp-input";

const ConfirmForgetPasswordForm = ({ setVerifyConfirmationCode }) => {
  const [showMessage, setShowMessage] = useState(true);
  const { username } = useSelector((state) => state.userReducer);
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [showValues, setShowValues] = useState({showPassword: false});
  const handleConfirmForgetPasswordSubmit = async () => {
    const result = await sSOConfirmForgetPassword(values);
    if (result && result.status) {
      localStorage.clear();
      showAlert("SUCCESS", "Success", result.message);
      //setVerifyConfirmationCode(false);
      navigate("/login");
    } else {
      showAlert("ERROR", "ERROR", result.message);
      setShowMessage(true);
    }
  };

  const handleResendCodeSubmit = async () => {
    const result = await sSOResendCode(values);
    if (result.status) {
      showAlert("SUCCESS", "Success", "OTP has been sent to your account");
    }
  };

  const redirect = () => {
    navigate("/login");
  }

  useEffect(() => {
    const userName = localStorage.getItem("Userename");
    if (userName && username === "") {
      setValues((preValues) => ({
        ...preValues,
        username:userName,
      }));
    }else{
      setValues({
        username,
      });
      localStorage.setItem("Userename", username);
    }
  }, []);

  const { values, setValues, errors, handleSubmit, handleChange } = useForm(
    handleConfirmForgetPasswordSubmit,
    validateConfirmForgetPassword
  );

  const handleOTPChange = (code) =>
  setValues((preValues) => ({
    ...preValues,
    confirmationCode: code,
  }));

  const handleClickShowPassword = (num) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword: !showValues.showPassword,
      });
    }
  };

  return (
    <>
        <div className="column-text dcomm-login-form-layer">
            <div className="text-left pb-40">
                <button type="button" className="btn-icon" onClick={redirect}><img src={BackIcon} title="Back" className="back-icon" /></button>
            </div>
            <div className="text-center">
                <h3 className="crypto-dinar-white font-gil-bold">Reset Password</h3>
                <div className="icon-box">
                    {/*<span className="icon all-center">
                        <img src={LockIcon} title="" alt="" />
                    </span>*/}
                    <p className="text-white">Your New Password must be different form previously used Password</p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
              {showMessage && message && (
                <p
                  className={
                    messageType === MESSAGE_VARIANT.ERROR
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  <small>{message}</small>
                </p>
              )}
            <div className="log-box">
                <div className="form-group">
                  <label htmlFor="username" className="form-label font-14 font-w-500 crypto-dinar-white">Email </label>
                    <input
                      disabled={true}
                      type="email"
                      className="form-control"
                      name="username"
                      placeholder="Email"
                      value={values.username === undefined ? "" : values.username}
                    />
                    {errors.username && (
                      <p className="text-danger">
                        <small>{errors.username}</small>
                      </p>
                    )}
                </div>
                <div className="form-group otp-diff-inputs">
                    <label htmlFor="username" className="form-label font-14 font-w-500 crypto-dinar-white">Confirmation Code </label>
                    <OtpInput
                      value={values.confirmationCode === undefined ? "" : values.confirmationCode}
                      onChange={handleOTPChange}
                      numInputs={6}
                      name="confirmationCode"
                      placeholder="Confirmation Code"
                      separator={<span style={{ width: "8px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                    />
                    {errors.confirmationCode && (
                      <p className="text-danger">
                        <small>{errors.confirmationCode}</small>
                      </p>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="username" className="form-label font-14 font-w-500 crypto-dinar-white">Password</label>
                  <div className="input-group showhide-pass">
                    <input
                      type={showValues.showPassword ? "text" : "password"}
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      value={values.password === undefined ? "" : values.password}
                      onChange={handleChange}
                    />
                    <span className="input-group-text input-icon" onClick={() => handleClickShowPassword(1)} >
                      {showValues.showPassword ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </span>
                  </div>
                    {errors.password && (
                      <p className="text-danger">
                        <small>{errors.password}</small>
                      </p>
                    )}
                </div>
                <div className="form-group pt-10">
                    <button type="submit" className="buy-coin-btn w-100">Save</button>
                </div>
                <div className="text-center pt-70">
                    <a href="#" className="btn-link" onClick={() => handleResendCodeSubmit()}>Resend Code</a>
                </div>
            </div>
          </form>
        </div>
    </>
  );
};

export default ConfirmForgetPasswordForm;
