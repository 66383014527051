import React from 'react';
import "../../assets/css/social-icon-card.css";

const SocialIconCard = ({ icon }) => {
    return (
        <div className="social-icon-card-main w-fit-content">
            <div className="card sunset-F2F2F2-bg border-radidus-8">
                <div className="card-body">
                    <img src={icon} />
                </div>
            </div>
        </div>
    );
};

export default SocialIconCard;
