import React from "react";
import graph from "../../assets/images/graph.JPG";
import { getCashLimit, getCryptoLimit } from "../../core/utils/token";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const NatureNFTGraph = (props) => {
  const { dashboard } = useSelector((state) => state.nftReducer);
  const data = props.data == undefined ? dashboard : props.data;
  const cashDecimal = getCashLimit();
  const cryptoDecimal = getCryptoLimit();
  const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"];
  function abbreviateNumber(number) {
    // what tier? (determines SI symbol)
    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    // scale the number
    const scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }
  const graphGrid = (coinIssued, name) => {
    return {
      labels: [name],
      datasets: [
        {
          label: [name],
          data: [coinIssued],
          backgroundColor: "rgba(25, 55, 110, 1)",
          borderRadius: 10,
          barThickness: 100,
        },
      ],
    };
  };
  const options = {
    // maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: function (value, index, values) {
            return abbreviateNumber(value);
          },
        },
      },
      x: {
        display: false,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      label: {
        display: false,
      },
      tooltip: {
        bodyAlign: "right",
        callbacks: {
          title: function (item, everything) {
            return;
          },
          label: function (item) {
            const name = item.label;
            let number = item.raw;
            number = number.toLocaleString("en-US", {
              minimumFractionDigits: 5,
            });
            const label = `${name} :  ${number}`;
            return label;
          },
        },
      },
    },

    responsive: true,
  };
  return (
    <>
      {data !== undefined && data !== null && (
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
        >
          <div className="db-graph">
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <div className="me-0 me-md-2 db-graph-inner">
                  <h4 className="mt-4 ms-4">Sunset SNT</h4>
                  <p className="text-center mt-4 mb-0 graph-text">
                    SNT{" "}
                    {data.sunset_snt.toLocaleString("en-US", {
                      minimumFractionDigits: cryptoDecimal,
                      maximumFractionDigits: cryptoDecimal,
                    })}
                  </p>
                  <div className="text-center w-100">
                    <Bar
                      data={graphGrid(
                        data.sunset_snt,
                        "Total Circulating Supply"
                      )}
                      options={options}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0">
                <div className="me-0 me-md-2 db-graph-inner">
                  <h4 className="mt-4 ms-4">Sunset USD</h4>
                  <p className="text-center mt-4 mb-0 graph-text">
                    USD{" "}
                    {data.sunset_usd.toLocaleString("en-US", {
                      minimumFractionDigits: cashDecimal,
                      maximumFractionDigits: cashDecimal,
                    })}
                  </p>
                  <div className="text-center w-100">
                    <Bar
                      data={graphGrid(data.sunset_usd, "Total Market Cap")}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NatureNFTGraph;
