import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/about.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getPageTypeData } from "../../core/services/api/home";
import { BANNER_TYPES } from "../../utils/constants/banner-types";
import Banner from "../../components/shared/blog-news/BlogBanner";
import BlogList from "../../components/shared/blog-news/BlogList";
import { Spinner } from "react-bootstrap";

const Blog = () => {
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const { headerList } = useSelector((state) => state.pageReducer);
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPageData = async (
    link = "/api/v1/homepage/blogs",
    pageType = "blog"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      result.banner_section && setBannerSections(result.banner_section);
      setIsLoading(false);
    } else {
      console.log(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
    setIsLoading(true);
    setBanners([]);
    setBannerSections([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  useEffect(() => {    
    navigate("/login");
  }, []);
  
    return (
        <ThemeContainer type="public" pageTitle="Blogs">
        {!isLoading ? (
            <>
            <Banner banners={banners} />
            <BlogList bannerSections={bannerSections[0]} />
            </>
        ) : (
            <div className="Loader">
              <small><Spinner animation="border" /></small>
            </div>
        )}
        </ThemeContainer>
    );
};

export default Blog;
