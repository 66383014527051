import React, { useState } from "react";
import { setSelectedCrypto } from "../../redux/actions/nft-action";
import { useDispatch, useSelector } from "react-redux";

const TableFilterProjects = ({ page }) => {
  const dispatch = useDispatch();
  const { masterData, energyMasterData } = useSelector(
    (state) => state.nftReducer
  );
  const data = page == "nature" ? masterData : energyMasterData;
  const handleChange = (e) => {
    const num = parseInt(e.target.value, 10);
    dispatch(setSelectedCrypto(num));
  };

  return (
    <>
      {data !== null && (
        <select
          name="projects"
          className="form-select tb-form"
          id="specificSizeSelect"
          onChange={handleChange}
        >
          {data.crypto_tokens.map(({ name, id }, index) => (
            <option value={id} key={index}>
              {name}
            </option>
          ))}
        </select>
      )}
    </>
  );
};

export default TableFilterProjects;
