import React from "react";
import URLS from "../../configs/api-end-points";
import leftImg from "../../assets/images/project-detail/brazil-forest-nft-project-image.png";

const ProjectsSection = ({ projects }) => {
  const PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  return (
    <>
      <section className="project m-2 m-md-5 py-5">
        <div className="headline mx-1 mx-md-5">
          <h1 className="headline1 px-1 px-md-4">Projects</h1>
          <h1 className="headline2 px-1 px-md-4">Sunset Nature NFT</h1>
        </div>

        <div className="container mt-5">
          <div className="row">
            {projects.map((val, index) => (
              <div className="col-lg-6 col-md-12 py-3 px-3" key={index}>
                <div className="card" style={{ border: "none" }}>
                  <a href={`/project/${val.token_id}`}>
                    <img
                      src={val.image ? `${PATH}${val.image}` : `${leftImg}`}
                      className="ld-img"
                      alt="..."
                    />
                  </a>
                  <div className="textover">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <a href={`/project/${val.token_id}`}>
                          <h1>{val.name}</h1>
                        </a>
                      </div>
                      <div>
                        <p className="mb-0">
                          <span className="snt">{val.category}</span>
                          {val.price_in_usd !== "" && (
                            <span className="dol">{val.price_in_usd}</span>
                          )}
                        </p>
                      </div>
                    </div>
                    <p className="card-text">{val.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectsSection;
