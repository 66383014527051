import React, { useEffect, useState } from "react";
import "../../assets/css/header.css";
import useForm from "./../../hooks/use-form";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { validateUserUpdate } from "../../core/validations/user-validations";
import { postUserUpdate } from "../../core/services/api/nft";
import useAlert from "./../../hooks/use-alert";
import { useNavigate } from "react-router-dom";
const UpdateImageForm = ({ data }) => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const selectCountry = (val) => {
    var el = document.getElementById('countries');
    var text = el.options[el.selectedIndex].innerHTML;
    setValues((preValues) => ({
      ...preValues,
      country: text,
      country_code: val,
    }));
  };
  const selectRegion = (val) => {
    var el = document.getElementById('states');
    var text = el.options[el.selectedIndex].innerHTML;
    setValues((preValues) => ({
      ...preValues,
      state: text,
      state_code: val,
    }));
  };
  const updateUser = async () => {
    const res = await postUserUpdate(values);
    if (res.success) {
      showAlert("SUCCESS", "Success", res.message);
      navigate("/user-profile");
    } else {
      showAlert("ERROR", "Error", res.message);
    }
  };
  const { values, errors, handleSubmit, handleChange, setValues } = useForm(
    updateUser,
    validateUserUpdate
  );
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      name: data.name,
      //phone: data.phone,
      mobile: data.mobile,
      address: data.address,
      state: data.state,
      state_code: data.state_code,
      country: data.country,
      country_code: data.country_code,
      zip: data.zip,
      email: data.email,
    }));
  }, []);

  const handleMobileChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValues((preValues) => ({
        ...preValues,
        mobile: e.target.value,
      }));
    }
  };
  const handleZipChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValues((preValues) => ({
        ...preValues,
        zip: e.target.value,
      }));
    }
  };
  
  return (
    <>
      <div className="col-md-2"></div>
      <div className="col-md-8">
        <div className="w-100 kyc my-4">
          <div className="kyc-form">
            <div className="kyc-form-head mx-1">
              <h2 className="crypto-dinar-white font-30">Update Profile</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-group">
                      <input
                        type="text"
                        name="name"
                        value={values.name === undefined ? "" : values.name}
                        className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                        onChange={handleChange}
                        placeholder="First Name"
                        maxLength={50}
                      />
                      {errors.name && (
                        <p className="text-danger">
                          <small>{errors.name}</small>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="email"
                    name="email"
                    readOnly
                    value={values.email === undefined ? "" : values.email}
                    className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="text"
                    name="mobile"
                    maxLength={13}
                    value={values.mobile === undefined ? "" : values.mobile}
                    className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                    onChange={handleMobileChange}
                    placeholder="Mobile Number"
                  />
                </div>
                {errors.mobile && (
                  <p className="text-danger">
                    <small>{errors.mobile}</small>
                  </p>
                )}
              </div>
              {/*<div className="mb-3">
                <div className="input-group">
                  <input
                    type="text"
                    name="phone"
                    maxLength={13}
                    value={values.phone === undefined ? "" : values.phone}
                    className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                    onChange={handlePhoneChange}
                    placeholder="Phone Number"
                  />
                </div>
                {errors.phone && (
                  <p className="text-danger">
                    <small>{errors.phone}</small>
                  </p>
                )}
              </div>*/}
              <div className="mb-3">
                <div className="input-group">
                  <input
                    type="text"
                    name="address"
                    value={values.address === undefined ? "" : values.address}
                    className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                    onChange={handleChange}
                    placeholder="Address"
                    maxLength={100}
                  />
                </div>
                {errors.address && (
                  <p className="text-danger">
                    <small>{errors.address}</small>
                  </p>
                )}
              </div>
              <div className="mb-3 row">
                <div className="col-lg-4 mb-3">
                  <CountryDropdown
                    defaultOptionLabel="Select Country First."
                    id="countries"
                    value={values.country_code}
                    valueType="short"
                    onChange={selectCountry}
                    className="bg-gray border-radidus-8 w-100"
                  />
                  {errors.country_code && (
                    <p className="text-danger">
                      <small>{errors.country_code}</small>
                    </p>
                  )}
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-lg-4 mb-3">
                  <RegionDropdown
                    id="states"
                    blankOptionLabel="First Select Country."
                    defaultOptionLabel="Now Select State."
                    country={values.country_code}
                    countryValueType="short"
                    valueType="short"
                    value={values.state_code}
                    onChange={selectRegion}
                    className="bg-gray border-radidus-8 w-100"
                  />
                  {errors.state_code && (
                    <p className="text-danger">
                      <small>{errors.state_code}</small>
                    </p>
                  )}
                </div>
                <div className="col-lg-4 mb-3">
                  <input
                    type="text"
                    name="zip"
                    disabled={!!(values.country === "" || values.state === "")}
                    value={values.zip === undefined ? "" : values.zip}
                    className="form-control bg-gray border-radidus-8 p-lg-3 p-sm-3"
                    onChange={handleZipChange}
                    placeholder="Zip Code"
                    maxLength={6}
                  />
                  {errors.zip && (
                    <p className="text-danger">
                      <small>{errors.zip}</small>
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="buy-coin-btn w-100"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-2"></div>
    </>
  );
};

export default UpdateImageForm;
