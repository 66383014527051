const ACCESS_TOKEN_TYPES = {
    nature: "access_token",
    energy: "energy_access_token"
};

const USER_ID_TYPES = {
    nature: "user_id",
    energy: "energy_user_id"
};

export { ACCESS_TOKEN_TYPES, USER_ID_TYPES };
