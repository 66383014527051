/* eslint-disable new-cap */
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import URLS from "../../configs/api-end-points";
import { PROJECT_TYPES } from "../../utils/constants/project-types";

function ImageRightContentLeftBannerCard({ bannerSection, classes }) {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }
  return (
    <div className={classes}>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-5 p-sm-5 p-md-4 p-lg-5">
        {ReactHtmlParser(bannerSection.banner_text)}
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center banner-right-image">
        <img
          src={`${PATH}${bannerSection.image_url}`}
          className="w-100 h-100"
        />
      </div>
    </div>
  );
}

export default ImageRightContentLeftBannerCard;
