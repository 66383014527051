// export default SubmitOtp;
import React, { useState, useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import "../../assets/css/login.css";
import UserRegistrationForm from "../../components/shared/forms/user-registration-form";
import leafImg from "../../assets/images/leaf.png";
import ConfirmPasswordForm from "../../components/shared/forms/confirm-password-form";
import { useNavigate, Link } from "react-router-dom";
import loginSide from "../../assets/images/login-side.png";
import SubmitQrCodeForm from "../../components/shared/forms/submit-otp-form";
import Logo from "../../assets/images/DcommLogo.png";

const SubmitOtp = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Google Authenticator";
  }, []);
  return (
    <>
      <div className="login-page">
        <div className="flex-rows">
          <div className="column-img">
            <div className="logo">
              <Link to={"/"}>
                <img src={Logo} title="" alt="" />
              </Link>
            </div>
            {/*<div className="img-box">
                  <h2>Please use google authentication app for 2 Factor Authentication,
                    OTP enter below to Authenticate your Identity</h2>
              </div>*/}
          </div>
          <SubmitQrCodeForm />
        </div>
      </div>
    </>
  );
};

export default SubmitOtp;
