import React from "react";
import "../../assets/css/investors.css";
import InvestorCard from "../modals/investor-card";

const Investors = ({ investors }) => {
  return (
    <div className="investors-main p-5">
      <h1 className="heading mb-lg-5">Superpowers for Smart Investors</h1>
      <div className="row">
        {investors.map((investor, index) => {
          return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <InvestorCard investor={investor} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Investors;
