import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const GoogleMap = (props) => {
  const { REACT_APP_ADDRESS_LATITUDE, REACT_APP_ADDRESS_LONGITUDE } =
    process.env;

  return (
    <Map
      google={props.google}
      zoom={18}
      style={props.styles}
      initialCenter={{
        lat: REACT_APP_ADDRESS_LATITUDE,
        lng: REACT_APP_ADDRESS_LONGITUDE,
      }}
    >
      <Marker
        position={{
          lat: REACT_APP_ADDRESS_LATITUDE,
          lng: REACT_APP_ADDRESS_LONGITUDE,
        }}
      />
    </Map>
  );
};

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMap);
