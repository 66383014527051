/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";

import Recentblog from "../../assets/images/recent-blog.png";
import blog1 from "../../assets/images/blog1.png";
import blog2 from "../../assets/images/blog2.png";
import blog3 from "../../assets/images/blog3.png";
import blog4 from "../../assets/images/blog4.png";

const Blog = ({ bannerSection }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  return (
    <section className="blog-section">
       <div className="container">
          <div className="title-head text-center">
             {ReactHtmlParser(bannerSection.title)}
          </div>
          <div className="flex">
            {bannerSection &&
              bannerSection.banner_image[0] &&
             <div className="col-left">
                <figure><img src={PATH+bannerSection.banner_image[0].image_url} alt="" /></figure>
                <div className="blog-text">
                   <div className="blog-teg">
                    <span className="cata">Master Blog</span>
                    <span className="time">. 10 min</span>
                   </div>
                   <a href="#">
                      <h3>{ReactHtmlParser(bannerSection.banner_image[0].heading)}</h3>
                   </a>
                   {ReactHtmlParser(bannerSection.banner_image[0].text)}
                </div>
             </div>
            }
             <div className="col-right">
                <ul>
                {bannerSection &&
                    bannerSection.banner_image.map((blog, blog_key) => {
                      return (
                        <>
                        {blog_key !== 0 &&
                       <li>
                          <figure><img src={PATH+blog.image_url} alt="" /></figure>
                          <div className="blog-title">
                             <div className="blog-teg">
                              <span className="cata blue">Master Blog</span>
                              <span className="time">. 10 min</span>                                
                             </div>
                             <a href="#">
                                {ReactHtmlParser(blog.heading)}
                             </a>
                          </div>
                       </li>
                     }
                       </>
                      );
                    })}
                </ul>
             </div>
          </div>
       </div>
    </section>
  );
};

export default Blog;
