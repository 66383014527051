import React from "react";
import spainForestNFTProjectImage from "../../assets/images/spain-forest-nft-project-image.png";
import brazilForestNFTProjectImage from "../../assets/images/brazil-forest-nft-project-image.png";
import ProjectCard from "../modals/project-card";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/project-list");
  };
  return (
    <div className="card border-radidus-16">
      <div className="card-body p-lg-5">
        <h5 className="font-w-600 font-24 mb-2 font-34A853">Projects</h5>
        <div className="row">
          <div className="col-lg-11">
            <h1 className="font-w-500 font-36 mb-2">Sunset Nature NFT</h1>
          </div>
          <div className="col-lg-1">
            <button
              className="w-100 border-radidus-8 border-2-00A14B p-lg-2 bg-body font-15 font-w-500"
              onClick={handleClick}
            >
              View All{" "}
            </button>{" "}
          </div>
        </div>
        <div
          id="carouselExampleCaptions"
          className="carousel slide mt-5"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner mb--lg-4">
            <div className="carousel-item active">
              <div className="row">
                <div className="col-lg-6">
                  <ProjectCard
                    image={brazilForestNFTProjectImage}
                    projectName="Brazil Forest NFT"
                    projectDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae."
                    id="1"
                  />
                </div>
                <div className="col-lg-6">
                  <ProjectCard
                    image={spainForestNFTProjectImage}
                    projectName="Amazon Forest NFT"
                    projectDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae."
                    id="2"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row">
                <div className="col-lg-6">
                  <ProjectCard
                    image={brazilForestNFTProjectImage}
                    projectName="Brazil Forest NFT"
                    projectDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae."
                    id="1"
                  />
                </div>
                <div className="col-lg-6">
                  <ProjectCard
                    image={spainForestNFTProjectImage}
                    projectName="Spain Forest NFT"
                    projectDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam tristique diam lacus, nec ullamcorper massa faucibus vitae."
                    id="1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-indicators position-static">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active sunset-D8D8D8-bg"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              className="sunset-D8D8D8-bg"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              className="sunset-D8D8D8-bg"
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
