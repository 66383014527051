import React, { useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import { useSelector, useDispatch } from "react-redux";
import { createPayment } from "../../core/services/api/nft";
import { setSuccessMessage } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { getAssetRequestId, getPaymentId } from "../../core/utils/token";
import useAlert from "./../../hooks/use-alert";
const ConfirmPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  // eslint-disable-next-line multiline-comment-style
  //   const { paymentId, assetRequestId } = useSelector(
  //     (state) => state.nftReducer
  //   );
  const confirmPayment = async () => {
    const urlString = window.location.href;
    const url = urlString.split("?")[1];
    const assetRequestId = getAssetRequestId();
    const paymentId = getPaymentId();
    const data = {
      transaction_id: parseFloat(paymentId, 10),
      asset_request_id: parseFloat(assetRequestId, 10),
      gateway_payment_response: url,
    };
    const res = await createPayment(data);
    if (res.status) {
      showAlert("SUCCESS", "Success", res.message);
      setTimeout(() => {
        navigate("/reports");
      }, 1000);
    } else {
      showAlert("ERROR", "Error", res.message);
      setTimeout(() => {
        navigate("/reports");
      }, 1000);
    }
  };
  useEffect(() => {
    confirmPayment();
  }, []);
  return (
    <>
      <ThemeContainer type="private" module="nature"></ThemeContainer>
    </>
  );
};
export default ConfirmPayment;
