import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../redux/actions/pagination-action";

const NatureEnergyTabs = ({ naturenft = "yes", enegrynft = "yes" }) => {
  const dispatch = useDispatch();
  const handleChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {naturenft === "yes" ? (
        <li className="nav-item" role="presentation">
          <button
            className="nav-link dashboard-tabs active font-20"
            id="naturenft-tab"
            onClick={() => handleChange("nature")}
            data-bs-toggle="tab"
            data-bs-target="#naturenft"
            type="button"
            role="tab"
            aria-controls="naturenft"
            aria-selected="true"
          >
            Nature NFT
          </button>
        </li>
      ) : (
        ""
      )}
      {enegrynft === "yes" ? (
        <li className="nav-item" role="presentation">
          <button
            onClick={() => handleChange("energy")}
            className={
              naturenft === "yes"
                ? "nav-link dashboard-tabs font-20"
                : "nav-link dashboard-tabs active font-20"
            }
            id="energynft-tab"
            data-bs-toggle="tab"
            data-bs-target="#energynft"
            type="button"
            role="tab"
            aria-controls="energynft"
            aria-selected="false"
          >
            Energy NFT
          </button>
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

export default NatureEnergyTabs;
