const NFTS = {
  SET_MASTER_DATA: "SET_MASTER_DATA",
  SET_ENERGY_MASTER_DATA: "SET_ENERGY_MASTER_DATA",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  SET_ENERGY_DASHBOARD_DATA: "SET_ENERGY_DASHBOARD_DATA",
  SET_SELECTED_CRYPTO: "SET_SELECTED_CRYPTO",
  SET_PAYMENT_ID: "SET_PAYMENT_ID",
  SET_ASSET_REQUEST: "SET_ASSET_REQUEST",
};

export { NFTS };
