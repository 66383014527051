/* eslint-disable nonblock-statement-body-position */
import React, { useEffect, useState } from "react";
import ThemeContainer from "../../containers/theme-container";
/*import "../../assets/css/home.css";*/
import "../../assets/css/home-new.css";
import "../../assets/css/custom.css";
import FactsAndFigures from "../../components/shared/facts-and-figures";
import Banner from "../../components/shared/banner";
import FAQ from "../../components/shared/faqs";
import Partner from "../../components/shared/partners";
import Media from "../../components/shared/media";
import Workflow from "../../components/shared/workflow";
import WhyUs from "../../components/shared/why-us";
import TableComparison from "../../components/shared/table-comparison";
import FeeStructure from "../../components/shared/fee-structure";
import LatestWhitepaper from "../../components/shared/latest-whitepaper";
import Blog from "../../components/shared/blog";
import { getPageTypeData } from "../../core/services/api/home";
import { BANNER_TYPES } from "../../utils/constants/banner-types";
import ImageLeftContentRightWidgetCard from "../../components/modals/image-left-content-right-widget-card";
import ImageLeftContentRightBannerCard from "../../components/modals/image-left-content-right-banner-card";
import ImageRightContentLeftBannerCard from "../../components/modals/image-right-content-left-banner-card";
import ImageRightContentLeftWidgetCard from "../../components/modals/image-right-content-left-widget-card";
import Image123BannerCard from "../../components/modals/image-123-banner-card";
import { useParams, useNavigate } from "react-router-dom";
import PromotionBanner from "../../components/modals/promotion-banner";
import { useDispatch, useSelector } from "react-redux";
import Investors from "../../components/shared/investors";
import { setHeaderListData } from "../../redux/actions/page-action";
import ImageLeftContentRightWidgetContentImage from "../../components/modals/image-left-content-right-widget-content-image";
import ImageRightContentLeftWidgetContentImage from "../../components/modals/image-right-content-left-widget-content-image";
import Image22 from "../../components/shared/image_2_2";
import HomepageInvestor from "../../components/modals/homepage-investor";
import ImagePartner from "./../../components/modals/image-partner";
import ImageLeftContentRightWidgetFull from "./../../components/modals/image-left-content-right-widget-full";
import Projects from "./../../components/shared/projects";
import Ecosystem from "../../components/shared/ecosystem";
import Operation from "../../components/shared/opertation-banner";
import Calculator from "../../components/modals/calculator";
import { Spinner } from "react-bootstrap";


const Home = () => {
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const { headerList } = useSelector((state) => state.pageReducer);
  const [banners, setBanners] = useState([]);
  const [factsAndFiguresTitle, setFactsAndFiguresTitle] = useState("");
  const [factsAndFigures, setFactsAndFigures] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const [promotionBody, setPromotionBody] = useState("");
  const [promotionContent, setPromotionContent] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [investerInfo, setInvesterInfo] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [partners, setPartners] = useState([]);
  const [faq_title, setFaqTitle] = useState("");
  const [partner_title, setPartnerTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPageData = async (
    link = "/api/v1/homepage/cryptodinar",
    pageType = "corporate"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      // result.count_title && setFactsAndFiguresTitle(result.count_title);
      result.homepage_count_widget &&
        setFactsAndFigures(result.homepage_count_widget);
      result.banner_section && setBannerSections(result.banner_section);
      // result.promotion_body && setPromotionBody(result.promotion_body);
      result.homepage_promition_line &&
        setPromotionContent(result.homepage_promition_line);
      result.cms_page_promition_line &&
        setInvestors(result.cms_page_promition_line);
      result.faqs &&
        setFaqs(result.faqs);
      result.partners &&
        setPartners(result.partners);
      result &&
        setFaqTitle(result.faq_title);
      result &&
        setPartnerTitle(result.partner_title);
      result.banner_content &&
        result.banner_image &&
        setBanners([
          {
            banner_text: result.banner_content,
            image_url: result.banner_image,
            page: "aboutUs",
          },
        ]);
      result.investors && setInvesterInfo(result.investors);
      setIsLoading(false);
    } else {
      console.log(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
    setIsLoading(true);
    setBanners([]);
    setFactsAndFiguresTitle("");
    setFactsAndFigures([]);
    setBannerSections([]);
    setPromotionBody("");
    setPromotionContent([]);
    setInvestors([]);
    setInvestors([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  useEffect(() => {    
    navigate("/login");
  }, []);

  return (
    <ThemeContainer type="public" pageTitle="Home page">
      {!isLoading ? (
      <>
        <Banner banners={banners} />
        <Calculator />
        {bannerSections &&
          bannerSections.length > 0 &&
          bannerSections.map((bannerSection, index) => {
            if (bannerSection.banner_for === "promotion")
            return (
              <WhyUs
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_type === "widget" && bannerSection.sequence === 1)
            return (
              <TableComparison
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_for === "Workflow")
            return (
              <Workflow
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_type === "widget" && bannerSection.sequence === 4)
            return (
              <FeeStructure
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_type === "text" && bannerSection.sequence === 5)
            return (
              <LatestWhitepaper
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_for === "media")
            return (
              <Media
                bannerSection={bannerSection}
                key={index}
              />
            );
            if (bannerSection.banner_for === "blog_and_news")
            return (
              <Blog
                bannerSection={bannerSection}
                key={index}
              />
            );
        })}
        <FAQ faqs={faqs} faq_title={faq_title}/>      
        <Partner partners={partners} partner_title={partner_title}/>
      </>
      ) : (
        <div className="Loader">
          <small><Spinner animation="border" /></small>
        </div>
      )}
    </ThemeContainer>
  );
};

export default Home;
