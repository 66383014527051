import React from 'react';
import "../../assets/css/count-entry-card.css";

const CountEntryCard = ({ widgets, titleColor = "font-C5997C" }) => {
    return (
        <div className="card count-entry-card-main border-radidus-16">
            <div className="card-body text-center">
                <div className="row">
                    {
                        widgets && widgets.map((widget, index) => {
                            return <div className="col-4 col-sm-4" key={index}>
                                <h2 className="font-30">{widget.name}</h2>
                                <p className={`font-16 ${titleColor}`}>{widget.value}</p>
                            </div>;
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default CountEntryCard;
