import React, { useState,useEffect } from "react";
import {
  cryptoConfirmPayment,
  rejectAssetRequest,
  hctRequested
} from "../../core/services/api/nft";
import "../../assets/css/token-popup.css";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../redux/actions";
import useAlert from "./../../hooks/use-alert";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.png";
import { NAME } from "../../utils/constants/project-types";

const BuyTokenPopUp = ({ data, wallet, hide,htc_flag }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [transId, setTransId] = useState("");
  const handleCancel = async () => {
    navigate("/orders");
  };

  const handleCopy = (address) => {
    showAlert("SUCCESS", "Success", "Copied Successfully");
    copyText(address);
  };


  useEffect(async () => {
    const datas = {
      hct_quant_requested: data.new_Token,
    };
    const res = await hctRequested(datas);
    if (res.success) {
      console.log("SUCCESS", "Success", res.message);
    } else {
      console.log("ERROR", "Error", res.message);
    }
    setLoading(false) 
    
  }, []);

  return (
    <>
      <div className="buy-token-payment-process font-w-600">
        <div className="row ">
          <div className="col header ">Payment Process</div>
          {/*<button type="button" className="btn-close" aria-label="Close" onClick={handleCancel}></button>*/}
          <i className="fa fa-times popupclose" onClick={handleCancel}></i>
        </div>
        <div className="row">
            <div className="col title font-w-500">
              {htc_flag === 'true' ? (
                <>
                  Make sure payment of {data.crypto_Token} {data.paymentCurrency} to receive THCNFT
                </>
              ) : (
                <>
                  Make sure payment of {data.cryptoToken} {data.paymentCurrency} to receive {data.token} {NAME.project}
                </>
              )}
            </div>
          </div>
        {data.paymentCurrency == "USDT" && (
          <div className="row ">
            <div className="col title-2 font-w-500">
              Only transfer USDT using the ERC20 network or your coins will be
              lost
            </div>
          </div>
        )}
        <div className="row">
          <div className="col subtitle">
            {htc_flag === 'true' ? (
                <>
                 Send Amount: {data.crypto_Token} {data.paymentCurrency}
                </>
              ) : (
                <>
                  Send Amount: {data.cryptoToken} {data.paymentCurrency}
                </>
              )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-9">
            <div className="input-group">
              <input
                className="form-control bg-gray"
                id="inputAddress"
                disabled
                value={data.adminWallet}
              />
              <div
                className="input-group-text bg-gr cursor-pointer copy-icon"
                onClick={() => handleCopy(data.adminWallet)}>
                <img src={CopyIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col title">
            Payment to the following {data.paymentCurrency} Wallet address
          </div>
        </div>
        <div className="row">
          <div className="col">
            <QRCode value={data.qrCode} size={180} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3 text-right wallet">
            <button
              type="button"
              onClick={hide}
              className="font-919191 button-tab-active w-100  no-bdr h-40 create-new cursor-pointer">
              Transfer Complete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyTokenPopUp;
