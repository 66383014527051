import URLS from "../../../configs/api-end-points";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";
import apiServices from "../data/api_service";

const getCorporatePageData = async () => {
  const url = URLS.HOMEPAGE_CORPORATE;
  const result = await apiServices.get(url);
  return result;
};

const getPageTypeData = async (pageType, link) => {
  const url = `${URLS.HOMEPAGE}/${pageType}`;
  let type = PROJECT_TYPES.PROJECT_TYPES_NATURE;
  if (pageType === PROJECT_TYPES.PROJECT_TYPES_ENERGY) {
    type = PROJECT_TYPES.PROJECT_TYPES_ENERGY;
  }
  const result = await apiServices.get(link, type);
  return result;
};

const getFooterData = async () => {
  const url = URLS.FOOTER;
  const result = await apiServices.get(url);
  return result;
};
const getDFenderAsset = async () => {
  const url = URLS.DFENDER_ASSET;
  const result = await apiServices.get(url);
  return result;
};

export {
  getCorporatePageData,
  getPageTypeData,
  getFooterData,
  getDFenderAsset,
};
