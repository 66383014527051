// import web3 in file to use
const Web3 = require("web3");
const { ADDRESSES } = require("../../../configs/contract-address");
const { MintTokenABI } = require("../abi/abi-provider");

// create the object of web3 with given provider, if there is no provider then connect with default 'wss://remotenode.com:8545'
const web3 = new Web3(Web3.givenProvider || "wss://remotenode.com:8545");
const blockchainToken = Math.pow(10, 18);
export const _isMetamaskInstalled = () => {
  return new Promise((resolve, reject) => {
    if (Web3.givenProvider !== null && typeof window.ethereum !== "undefined") {
      resolve(true);
    }
    reject(false);
  });
};

export const _connectToMetamask = async () => {
  const isMetamaskInstalled = await _isMetamaskInstalled();
  if (!isMetamaskInstalled) {
    return { error: true, message: "Metamask in not installed!" };
  }
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return { error: false, accountAddress: accounts[0] };
  }
};

export const _getActiveAccount = async () => {
  const accounts = await web3.eth.getAccounts();
  if (accounts.length > 0) {
    return { error: false, account: accounts[0] };
  }

  return { error: true, message: "No active account is found!" };
};

export const _mintToken = async (data) => {
  const tokenNumber = (data * blockchainToken).toString();
  await _connectToMetamask();
  const accountAddress = await _getActiveAccount();

  const myContact = new web3.eth.Contract(
    MintTokenABI,
    ADDRESSES.ADDRESS_BRAZIL_TOKEN
  );
  const tx = await myContact.methods
    .mint(accountAddress.account, tokenNumber)
    // eslint-disable-next-line comma-spacing
    .send({ from: accountAddress.account }, (err, mintHash) => {
      if (err) {
        console.log("=== An error occured ===", err);
        return;
      }
    });
  return tx;
};
export const _metaMintToken = async (data) => {
  await _connectToMetamask();
  const accountAddress = await _getActiveAccount();
  if (!accountAddress) {
    return false;
  }
  const myContact = new web3.eth.Contract(
    MintTokenABI,
    ADDRESSES.ADDRESS_BRAZIL_TOKEN
  );
  const tx = await myContact.methods
    .mint(
      accountAddress.account,
      data.token,
    )
    .send({ from: accountAddress.account }, (err, mintHash) => {
      if (err) {
        console.log("=== An error occured ===", err);
        return;
      }
    });
  return tx;
};
// this is the demo method for calling smart contract method using web3

/*
 * _lockToken = async (data) => {
 *     const { tokenValue } = data;
 *     await _connectToMetamask();
 *     const accountAddress = await _getActiveAccount();
 *     if (!accountAddress) {
 *         return false;
 *     }
 *     const stFactory = new web3.eth.Contract(ABI goes here, ADDRESSES.ADDRESS_STFACTORY);
 *     const tx = await stFactory.methods.lock(tokenValue).send({ from: accountAddress });
 *     return tx;
 * };
 */

/*module.exports = {
  _isMetamaskInstalled,
  _connectToMetamask,
  _getActiveAccount,
  _mintToken,
  _metaMintToken,
};*/
