import React, { useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { validateUserLogin } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { multiLogin } from "../../../core/services/api/user";
import { useSelector, useDispatch } from "react-redux";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";
import loginSubmit from "./../../../core/utils/login_submit";
import useAlert from "../../../hooks/use-alert";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const [showMessage, setShowMessage] = useState(false);
  const [showValues, setShowValues] = useState({
    showPassword1: false
  });

  const handleLogin = async () => {
    const result = await multiLogin(values);
    if (result && result.success) {
      const res = loginSubmit(result, dispatch, showAlert);
      navigate(res);
    } else if (result && !result.success) {
      showAlert("ERROR", "ERROR", result.message);
      setShowMessage(true);
    }
  };

  const handleClickShowPassword = (num) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    }
  };

  const { values, errors, handleSubmit, handleChange } = useForm(
    handleLogin,
    validateUserLogin
  );
  const { showAlert } = useAlert();
  return (
    <form onSubmit={handleSubmit}>
      <div className="log-box log-pt50">
        <div className="form-group">
            <input
              type="text"
              autoFocus
              placeholder="Email"
              className="form-control"
              name="login"
              value={values.login === undefined ? "" : values.login}
              onChange={handleChange}
            />
          {errors.login && (
            <p className="text-danger">
              <small>{errors.login}</small>
            </p>
          )}
        </div>
        <div className="form-group">
            <div className="input-group showhide-pass">
                <input
                  type={showValues.showPassword1 ? "text" : "password"}
                  placeholder="Password"
                  className="form-control"
                  name="password"
                  value={values.password === undefined ? "" : values.password}
                  onChange={handleChange}
                />
                <span className="input-group-text input-icon" onClick={() => handleClickShowPassword(1)} >
                  {showValues.showPassword1 ? (
                    <i className="fa fa-eye-slash"></i>
                  ) : (
                    <i className="fa fa-eye"></i>
                  )}
                </span>
            </div>
            {errors.password && (
              <p className="text-danger">
                <small>{errors.password}</small>
              </p>
            )}
            <div className="text-right">
                <Link to="/forgot-password" className="text-primary dis-iblock pt-20">Forgot Password?</Link>
            </div>
        </div>
        <div className="form-group pt-10">
            <button type="submit" className="buy-coin-btn w-100">Sign In</button>
        </div>
      </div>

    </form>
  );
};

export default LoginForm;
