import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import {
  getAccessToken,
  getWalletAddress,
  setWalletAddress,
} from "../core/utils/token";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserDetails,
  setUserLoginStatus,
  setUserWalletAddress,
} from "../redux/actions";
import metamaskService from "../core/services/smart-contracts/metamask";
import { getUserDetails } from "../core/services/api/user";
import SubsubKYC from "../components/shared/kyc/subsum-kyc";
import {
  dashboardAPI,
  energyDashboardAPI,
  getEnergyMasterData,
  getMasterData,
  updateWalletAddress,
} from "../core/services/api/nft";
import {
  setDashboardData,
  setEnergyDashboardData,
  setMasterData,
  setEnergyMasterData,
} from "../redux/actions/nft-action";
import { checkUserKYCStatus } from "../redux/actions/kyc-action";
import { getUsetKYCStatus } from "../core/services/api/kyc";
import { Spinner } from "react-bootstrap";

const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMetamaskInstalled } = useSelector((state) => state.userReducer);
  const { dashboard, masterData, energyDashboard, energyMasterData } =
    useSelector((state) => state.nftReducer);
  const { startKYC, isUserKYCDone } = useSelector((state) => state.kycReducer);

  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          /*
           *   const result = await getUserDetails();
           *   const values = {
           *       userEmail: result.user_email,
           *       userName: result.user_name,
           *       userId: result.user_id,
           *       kycDone: result.kyc_done
           *     };
           *   dispatch(setUserDetails(values));
           */
          !isUserKYCDone && dispatch(checkUserKYCStatus(getUsetKYCStatus));
          dispatch(setUserLoginStatus(true));
          setIsAuthenticate(true);
          setIsLoading(false);
        } else {
          setIsAuthenticate(false);
          dispatch(setUserLoginStatus(false));

          setIsLoading(false);
        }
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };

    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          fetchUserAddress();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserAddress = async () => {
      const walletAddress = getWalletAddress();
      if (walletAddress === "") {
        const result = await metamaskService.getUserAddress();
        if (result && !result.error) {
          setWalletAddress(result.account);
          dispatch(setUserWalletAddress(result.account));
          dispatch(setIsWalletConnected(true));
          const data = {
            walletAddress: result.account,
          };
          await updateWalletAddress(data);
        } else {
          dispatch(setUserWalletAddress(null));
          dispatch(setIsWalletConnected(false));
        }
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    };
    const fetchDashboard = async () => {
      setIsLoading(true);
      const res = await dashboardAPI();
      if (res.status) {
        const value = {
          dashboard: res.dashboard_data,
          offSet: res.utc_offset,
        };
        dispatch(setDashboardData(value));
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
      const result = await energyDashboardAPI();
      if (result.status) {
        dispatch(setEnergyDashboardData(result.dashboard_data));
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    };
    const fetchMasterData = async () => {
      setIsLoading(true);
      const res = await getMasterData();
      if (res.status == true) {
        dispatch(setMasterData(res));
        setIsLoading(false);
      }else{
        setIsLoading(false);
        if (res.message === "Invalid Either User Id or Access Token.") {
          localStorage.clear();
          navigate("/login");
        }
      }
      /*const result = await getEnergyMasterData();
      if (result.status == true) {
        dispatch(setEnergyMasterData(result));
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }*/
    };
    const LocalStorageWalletAddress = getWalletAddress();

    fetchData();
    !isMetamaskInstalled && fetchMetamaskInstallation();
    (isMetamaskInstalled || LocalStorageWalletAddress === "") &&
      fetchUserAddress();
    //(dashboard === null || energyDashboard === null) && fetchDashboard();
    (masterData === null || energyMasterData === null) && fetchMasterData();
  }, [location]);

  if (isLoading) return <div className="Loader"><small><Spinner animation="border" /></small></div>;

  if (startKYC) return <SubsubKYC />;

  return isAuthenticate ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
