import {
  ACCESS_TOKEN_TYPES,
  USER_ID_TYPES,
} from "../../utils/constants/localstorage-types";
import { PROJECT_TYPES } from "../../utils/constants/project-types";

const getAccessToken = (
  tokenType = ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]
) => {
  const accessToken = localStorage.getItem(tokenType);
  return accessToken;
};
const getLoginTime = () => {
  const loginTime = localStorage.getItem("login_time");
  return loginTime;
};
const getEnergyAccessToken = (
  tokenType = ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]
) => {
  const accessToken = localStorage.getItem(tokenType);
  return accessToken;
};
const getUserId = (
  userIdType = USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]
) => {
  const userId = localStorage.getItem(userIdType);
  return userId;
};
const getEnergyId = () => {
  const energyUserId = localStorage.getItem("energy_user_id");
  const result = parseInt(energyUserId, 10);
  return result;
};
const getUserName = () => {
  const userName = localStorage.getItem("user_name");
  return userName;
};
const getWalletAddress = () => {
  const walletAddress = localStorage.getItem("wallet_address");
  return walletAddress;
};
const setWalletAddress = (data) => {
  const walletAddress = localStorage.setItem("wallet_address", data);
};
const setAssestRequestId = (data) => {
  const setAssestRequestId = localStorage.setItem("assestRequestId", data);
};
const setPaymentId = (data) => {
  const paymentId = localStorage.setItem("paymentId", data);
};

const setAccessToken = (accessToken) => {
  localStorage.setItem("access_token", accessToken);
};

const getTFA = () => {
  const check = localStorage.getItem("otp_check");
  return check;
};

const getCryptoLimit = () => {
  const getCrypto = localStorage.getItem("crypto_decimal_limit");
  return getCrypto;
};
const getAssetRequestId = () => {
  const assetReq = localStorage.getItem("assestRequestId");
  return assetReq;
};
const getPaymentId = () => {
  const paymentId = localStorage.getItem("paymentId");
  return paymentId;
};

const getCashLimit = () => {
  const getCash = localStorage.getItem("cash_decimal_limit");
  return getCash;
};
const getAWSToken = () => {
  const getToken = localStorage.getItem("aws_access_token");
  const data = getToken === null ? "" : getToken;
  return data;
};
const getAWSSession = () => {
  const getToken = localStorage.getItem("aws_session");
  const data = getToken === null ? "" : getToken;
  return data;
};
const getUserEmail = () => {
  const userEmail = localStorage.getItem("user_email");
  const data = userEmail === null ? "" : userEmail;
  return data;
};

const clearLocalStorage = () => {
  localStorage.clear();
};

export {
  getAccessToken,
  setAssestRequestId,
  getPaymentId,
  getAssetRequestId,
  setPaymentId,
  getEnergyAccessToken,
  setAccessToken,
  getWalletAddress,
  getUserId,
  getAWSToken,
  getCashLimit,
  getCryptoLimit,
  setWalletAddress,
  getUserName,
  clearLocalStorage,
  getTFA,
  getAWSSession,
  getEnergyId,
  getUserEmail,
  getLoginTime,
};
