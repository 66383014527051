import React from "react";
import CryptoHeader from "./crypto-header";
import NFTTableData from "../list-data/buytoken-order-table-data";
import AddDFender from "./addDFender";
import DFenderTableData from "../list-data/dfender-table-data";

const NatureTabReports = ({ page }) => {
  return (
    <div className="order-detail">
      <CryptoHeader
        header={page == "dfender" ? "DFender Transactions" : "Orders"}
        subHeading=""
      />
      {page == "dfender" ? (
        <>
          <AddDFender />
          <DFenderTableData />
        </>
      ) : (
        <NFTTableData />
      )}
    </div>
  );
};

export default NatureTabReports;

