import React from "react";
import ProjectCryptoForm from "./project-crypto_form";
import ProjectFiatForm from "./project-fiat_form";

const ProjectForms = () => {
  return (
    <>
      <div className="">
        <nav>
          <div
            style={{ height: "45px" }}
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link w-50 db-form-tab active"
              id="crypto-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#crypto-home"
              type="button"
              role="tab"
              aria-controls="crypto-home"
              aria-selected="true"
              style={{ heigh: "45px !important" }}
            >
              Crypto
            </button>
            <button
              className="nav-link w-50 db-form-tab"
              id="fiat-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#fiat-profile"
              type="button"
              role="tab"
              aria-controls="fiat-profile"
              aria-selected="false"
              style={{ height: "45px !important" }}
            >
              Fiat
            </button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <ProjectCryptoForm />
          <ProjectFiatForm />
        </div>
      </div>
    </>
  );
};

export default ProjectForms;
