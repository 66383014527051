import React, { useState } from 'react';

const KYCDocuments = () => {
    const [documents, setdocuments] = useState([
        { value: "1", label: "Document 1" },

        { value: "2", label: "Document 2" },

        { value: "3", label: "Document 3" },

        { value: "4", label: "Document 4" }
    ]);

    return (
        <>
            <select name="documents" id="specificSizeSelect" className="form-select tb-form">
                {documents.map(({ value, label }, index) => <option value={value} key={index}>{label}</option>)}
            </select>
        </>
    );
};

export default KYCDocuments;
