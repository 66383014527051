import React from 'react';
import { useParams } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div className="card border-radidus-16 w-50 m-auto text-center pt-lg-5 pb-lg-5 mt-lg-5">
            <div className="card-body pt-lg-5 pb-lg-5">
                <h1 className="font-DC4437">ERROR!</h1>
                <h3 className="mt-0 pt-6 font-DC4437">Metamask is require to use this application!</h3>
            </div>
        </div>
    );
};

export default ErrorPage;
