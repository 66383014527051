/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Partner = ({ partners, partner_title }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  var settings = {
    slidesToShow: 5,
    slidesToScroll: 2,
    dots: true,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            adaptiveHeight: true,
          },
        },
        {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              adaptiveHeight: true,
            },
          },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: true,
          },
        },
      ],
  };

  return (
    <>
      {partners && partners.length > 0 ? (
        <section className="partner-section">
         <div className="container">
            <div className="title-head text-center">
               {ReactHtmlParser(partner_title)}
            </div>
            <Slider {...settings} className="partner-slider">
            {partners.map((partner, partner_key) => {
              return (
                 <div key={partner_key}>
                    <figure><img src={PATH+partner.image_url} alt="" /></figure>
                 </div>
               );
              })}
            </Slider>
         </div>
      </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Partner;
