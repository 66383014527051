import React from 'react';
import "../../assets/css/banner.css";

const EnergyBanner = ({ bannerImage, image1, image2, bannerText }) => {
    return (

        <div className="banner-image-main energy-banner-image-main">
            <img src={image1} className="image-1"/>
            <img src={image2} className="image-2" />
            <h1 className="font-42 font-w-500 font-ffffff">{bannerText}</h1>
            <button className="py-2 border-radidus-4 px-3 font-000000">Discover More</button>
            <img src={bannerImage} className="w-100"/>
        </div>

    );
};

export default EnergyBanner;
