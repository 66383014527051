import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/benefit.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getPageTypeData } from "../../core/services/api/home";
import { BANNER_TYPES } from "../../utils/constants/banner-types";
import Banner from "../../components/shared/benefits/banner";
import FAQ from "../../components/shared/faqs";
import { Spinner } from "react-bootstrap";

const FAQS = () => {
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const { headerList } = useSelector((state) => state.pageReducer);
  const [banners, setBanners] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchPageData = async (
    link = "/api/v1/homepage/faqs",
    pageType = "faqs"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      result.faqs && setFaqs(result.faqs);
      setIsLoading(false);
    } else {
      console.log(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
    setIsLoading(true);
    setBanners([]);
    setFaqs([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  useEffect(() => {    
    navigate("/login");
  }, []);
  
    return (
        <ThemeContainer type="public" pageTitle="Faqs">
        {!isLoading ? (
            <>
            <Banner banners={banners} />
            {faqs &&
              faqs.length > 0 &&
                <FAQ faqs={faqs} faq_title="" />               
            }
            </>
        ) : (
            <div className="Loader">
              <small><Spinner animation="border" /></small>
            </div>
        )}
        </ThemeContainer>
    );
};

export default FAQS;
