import React, { useState } from "react";
import {
  cryptoConfirmPayment,
  rejectAssetRequest,
} from "../../core/services/api/nft";
import "../../assets/css/token-popup.css";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../redux/actions";
import useAlert from "./../../hooks/use-alert";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.svg";
import QrCodeForm from "./forms/qrcode-form";

const UserProfilePopUp = ({ hide, getUserData }) => {
  return (
    <>
      <div className="container font-w-600">
        <h1 className="font-30 font-w-500 crypto-dinar-white mt-lg-5 text-center">
          Google Authenticator
        </h1>
        <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
          Scan the Code Below
        </p>
        <QrCodeForm hide={hide} getUserData={getUserData} />
      </div>
    </>
  );
};

export default UserProfilePopUp;
