/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { copyText } from "../../core/utils/copy";
import { useSelector } from "react-redux";
import useForm from "./../../hooks/use-form";
import {
  createAssetRequest,
  createEnergyAssetRequest,
  getEnergyPriceToken,
  getPriceToken,
  buyToken,
} from "../../core/services/api/nft";
import Modal from "react-bootstrap/Modal";
import BuyTokenPopUp from "./buy-token-popup";
import { validateNatureNFT } from "../../core/validations/user-validations";
import infoIcon from "../../assets/images/information.png";
import useAlert from "./../../hooks/use-alert";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import CopyIcon from "../../assets/images/copy-icon.png";
import { Dropdown } from "react-bootstrap";
import CryptoHeader from "./crypto-header";
import { useNavigate } from "react-router-dom";
import { NAME } from "../../utils/constants/project-types";
//import debounce from "lodash.debounce";
const NFTFormCrypto = ({ data, page }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(data.currency[0]);
  const refresh = process.env.REACT_APP_TOKEN_PRICE_REFRESH_TIME;
  const { masterData, energyMasterData, selectedCrypto } = useSelector(
    (state) => state.nftReducer
  );
  const { enableKyc, isUserKYCDone } = useSelector((state) => state.kycReducer);
  const { showAlert } = useAlert();
  const showModal = () => {
    setIsOpen(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const getTokenPrice = async (obj) => {
    const datas = {
      crypto_currency_id: obj ? obj.id : selected.id,
      crypto_asset_id: data.token.id,
      crypto_name: obj ? obj.name : selected.name,
      token:
        values.input == 2 ? 0 : values.token ? parseFloat(values.token, 10) : 0,
      crypto_amount:
        values.input == 1
          ? 0
          : values.cryptoToken
          ? parseFloat(values.cryptoToken, 10)
          : 0,
    };
    if (datas.crypto_amount == 0 && datas.token == 0) {
      return;
    }
    const result = await getPriceToken(datas);
    if (result.status) {
      setValues((preValues) => ({
        ...preValues,
        token: values.input == 1 ? values.token : result.token.toString(),
        cryptoTokenAmount: result.token_crypto_price,
        cryptoName: result.crypto_name,
        cryptoToken:
          values.input == 2
            ? values.cryptoToken
            : result.crypto_amount.toString(),
        usdPrice: result.usd_price,
      }));
    }
    //setCheck(true);
  };
  /*useEffect(() => {
    let a;
    if (check) {
      a = setTimeout(() => {
        getTokenPrice();
      }, masterData.exchange_data[0].tsp_price_valid_in_minutes);
    }
    return () => {
      clearTimeout(a);
    };
  }, [selected]);*/
  const handleTokenInput = () => {
    setValues((preValues) => ({
      ...preValues,
      cryptoToken: 0,
      input: 1,
      minToken: masterData.crypto_asset[0].min_token,
    }));
    getTokenPrice();
  };
  const handleCryptoInput = () => {
    setValues((preValues) => ({
      ...preValues,
      token: 0,
      input: 2,
      minToken: masterData.crypto_asset[0].min_token,
    }));

    getTokenPrice();
  };
  const handelAssetRequest = async () => {
    if (data.walletAddress === "" || data.walletAddress === "undefined") {
      showAlert("ERROR", "Error", "Wallet address cannot not be empty.");
      return;
    }
    /*const typeMaster = masterData.crypto_tokens;
    const typeToken = typeMaster[selectedCrypto - 1].geofancing;
    let geoFancingId = [];
    typeToken &&
      typeToken.map((val) => {
        geoFancingId.push({
          nft_id: val.id,
        });
      });*/
    const datas = {
      user_address: data.walletAddress,
      payment_acquirer_id: parseFloat(data.payment_acquirer_id, 10),
      currency_id: parseFloat(selected.id, 10),
      crypto_asset_id: parseFloat(data.token.id, 10),
    };
    const res = await buyToken(datas);
    if (res.success) {
      showAlert("SUCCESS", "Success", res.message);
      setValues((preValues) => ({
        ...preValues,
        paymentCurrency: res.currency_name,
        qrCode: res.crypto_address,
        adminWallet: res.crypto_address,
      }));
      showModal();
    } else {
      showAlert("ERROR", "Error", res.message);
    }
  };
  const { handleChange, handleSubmit, values, setValues, errors, setErrors } =
    useForm(handelAssetRequest, validateNatureNFT);
  const handleSelectChange = (e) => {
    const obj = JSON.parse(e);
    setSelected(obj);
    getTokenPrice(obj);
  };
  useEffect(() => {
    setValues((preValues) => ({
      ...preValues,
      minimumToken: masterData.crypto_asset[0].min_token
        ? masterData.crypto_asset[0].min_token
        : 1,
    }));

    if (data.token !== "" && isLoading == true) {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    errors.token && showAlert("ERROR", "Error", errors.token);
  }, [errors]);
  const message =
    "On successfull transaction, coins will be transfered to this account";

  useEffect(() => {
    if (
      data.walletAddress === "" ||
      data.walletAddress === "undefined" ||
      data.walletAddress === "false"
    ) {
      showAlert("ERROR", "Error", "Please enter your wallet address.");
      navigate("/wallet-address");
    }
    if (enableKyc && isUserKYCDone === false) {
      showAlert("ERROR", "Error", "Please complete your KYC.");
      navigate("/wallet-address");
    }
  }, [data]);

  return (
    <>
      <label className="crypto-dinar-white-header">
        A minimum 10000 $DCM is required for Dfender setup
      </label>
      <div
        className="tab-pane fade buy-token-radius show py-4 px-4 active"
        id="crypto-home"
        role="tabpanel"
        aria-labelledby="crypto-home-tab">
        <CryptoHeader header={`Buy ${NAME.project}`} subHeading="" />
        {isLoading == false ? (
          <>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-12">
                <label
                  for="inputAddress"
                  className="form-label crypto-dinar-white">
                  Your Wallet Address
                  <span className="color-red">*</span>
                </label>
                <Tippy interactive={true} content={message}>
                  {/*<img src={infoIcon} alt="" className="info-icon" />*/}
                  <i className="fa fa-info-circle info-icon"></i>
                </Tippy>
                <div className="input-group">
                  <input
                    className="form-control bg-gray"
                    id="inputAddress"
                    disabled
                    value={
                      data.walletAddress === "undefined" ||
                      data.walletAddress === "false"
                        ? ""
                        : data.walletAddress
                    }
                  />
                  <div
                    className="input-group-text bg-gr cursor-pointer copy-icon"
                    onClick={() => {
                      copyText(data.walletAddress),
                        showAlert(
                          "SUCCESS",
                          "Success",
                          "Wallet Address Copied"
                        );
                    }}>
                    <img src={CopyIcon} />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <label
                  for="inputEmail4"
                  className="form-label crypto-dinar-white">
                  Coin Quantity
                  <span className="color-red">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-gray"
                    id="inputEmail4"
                    name="token"
                    onChange={handleChange}
                    onKeyUp={handleTokenInput}
                    value={values.token}
                  />
                </div>
                <label
                  for="inputEmail4"
                  className="form-label notif-label-white usd-label">
                  USD price: $
                  {values.usdPrice && values.token ? values.usdPrice : "0"}
                </label>
              </div>
              <div className="col-md-12">
                <label
                  for="inputPassword4"
                  className="form-label crypto-dinar-white">
                  Amount
                  <span className="color-red">*</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-gray"
                    id="inputEmail4"
                    name="cryptoToken"
                    value={values.cryptoToken}
                    onChange={handleChange}
                    onKeyUp={handleCryptoInput}
                  />
                  <div
                    className="input-group-text bg-gray currency-icon"
                    // onClick={() => copyText(data.walletAddress)}
                  >
                    <Dropdown onSelect={handleSelectChange}>
                      <Dropdown.Toggle size="sm" id="set-dd">
                        <img
                          src={
                            process.env.REACT_APP_API_NATURE_BASE_URL +
                            selected.currency_image
                          }
                          height={20}
                        />{" "}
                        {selected.name}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {data.currency &&
                          data.currency.map((val, index) => (
                            <Dropdown.Item
                              eventKey={JSON.stringify(val)}
                              key={index}>
                              {" "}
                              <img
                                src={
                                  process.env.REACT_APP_API_NATURE_BASE_URL +
                                  val.currency_image
                                }
                                height={20}
                              />{" "}
                              {val.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {values.cryptoName ? (
                  <div className="text-right">
                    <label
                      for="inputEmail4"
                      className="form-label notif-label-white usd-label">
                      1 {values.cryptoName} = $
                      {values.cryptoTokenAmount.toFixed(2)}
                    </label>
                  </div>
                ) : (
                  ""
                )}

                <br />
                <label
                  for="inputEmail4"
                  className="form-label notif-label-white">
                  Minimum {NAME.project} to purchase is{" "}
                  {masterData.crypto_asset[0].min_token}
                </label>
              </div>
              {masterData.crypto_asset[0].crypto_message && (
                <div className="col-12 d-flex align-items-center">
                  <div className="currency-msg">
                    <div className="row p-2 my-2">
                      <div className="col-sm-1 col-xs-1 p-2 d-flex align-items-start justify-content-end ">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"></i>
                      </div>
                      <div className="col-sm-11 col-xs-11 pe-2 ps-2 ">
                        <p className="m-0 warning-texts">
                          {masterData.crypto_asset[0].crypto_message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-12 text-center">
                <button type="submit" className="buy-coin-btn w-100">
                  Buy {NAME.project}
                </button>
              </div>
            </form>
          </>
        ) : (
          ""
        )}
      </div>
      <Modal size="lg" show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <BuyTokenPopUp
            data={values}
            wallet={data.walletAddress}
            hide={hideModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NFTFormCrypto;
