import React from "react";
import { Badge } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const SignRegisterTabs = ({ page }) => {
  return (
    <>
      <div className="text-end">
        <NavLink to={`/login`}>
          <Badge
            pill
            bg={page == "signIn" ? "dark" : "light"}
            text={page == "signIn" ? "light" : "dark"}
            className="px-4 py-3 cursor-pointer font-16"
          >
            Sign In
          </Badge>
        </NavLink>
        <NavLink to={`/sign_up`}>
          <Badge
            pill
            bg={page == "signIn" ? "light" : "dark"}
            text={page == "signIn" ? "dark" : "light"}
            className="px-4 py-3 mx-3 cursor-pointer font-16  shadow-partner"
          >
            Register
          </Badge>
        </NavLink>
      </div>
    </>
  );
};

export default SignRegisterTabs;
