import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserLoginStatus,
  setUserWalletAddress,
} from "../redux/actions";
import { getAccessToken, setWalletAddress } from "../core/utils/token";
import metamaskService from "../core/services/smart-contracts/metamask";
import { Spinner } from "react-bootstrap";

const PublicRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isMetamaskInstalled, isLoggedIn } = useSelector(
    (state) => state.userReducer
  );

  useEffect(() => {
    const fetchData = () => {
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          dispatch(setUserLoginStatus(true));
        } else {
          dispatch(setUserLoginStatus(false));
        }
        setIsLoading(false);
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };
    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          fetchUserAddress();
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUserAddress = async () => {
      const result = await metamaskService.getUserAddress();
      if (result && !result.error) {
        dispatch(setUserWalletAddress(result.account));
        dispatch(setIsWalletConnected(true));
      } else {
        dispatch(setUserWalletAddress(null));
        dispatch(setIsWalletConnected(false));
      }
      setIsLoading(false);
    };

    fetchData();
    !isMetamaskInstalled && fetchMetamaskInstallation();
    isMetamaskInstalled && fetchUserAddress();
  }, [location]);

  if (isLoading) return <small><Spinner animation="border" /></small>;
  return <Outlet />;
};

export default PublicRoute;
