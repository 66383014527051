import React from "react";
const CalculatorTab = ({ crypto, setCrypto }) => {
  return (
    <>
      <div className="row calculator-tabs ">
        <div className="col-md text-end ">
          <button
            className={crypto === "fiat" ? "button-tab-active" : "button-tab"}
            id="button-tab-1"
            onClick={() => setCrypto("fiat")}
          >
            Fiat
          </button>
        </div>
        <div className="col-md">
          <button
            className={
              crypto === "crypto" ? "button-tab-active" : "button-tab "
            }
            id="button-tab-2"
            onClick={() => setCrypto("crypto")}
          >
            Crypto
          </button>
        </div>
      </div>
    </>
  );
};
export default CalculatorTab;
