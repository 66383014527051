import React, { useEffect, useState } from 'react';
import ThemeContainer from '../../containers/theme-container';
import "../../assets/css/about.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPageTypeData } from "../../core/services/api/home";
import { BANNER_TYPES } from "../../utils/constants/banner-types";
import Bgimg from "../../assets/images/banner.png";
import DetailImg from "../../assets/images/detail-img.png";
import { Spinner } from "react-bootstrap";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const { headerList } = useSelector((state) => state.pageReducer);
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPageData = async (
    link = "/api/v1/homepage/blogs",
    pageType = "blog"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      result.banner_section && setBannerSections(result.banner_section);
      setIsLoading(false);
    } else {
      console.log(result);
      setIsLoading(false);
    }
  };

  useEffect(() => {    
    window.scrollTo(0, 0);
    setIsLoading(true);
    setBanners([]);
    setBannerSections([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);
    return (
        <ThemeContainer type="public" pageTitle="Blogs">
        {!isLoading ? (
            <>
            <section className="contact-banner" style={{ backgroundImage: `url(${Bgimg})` }}>
              <div className="container">
                <div className="text-center benner-ext">
                  <h2>Crypto Dinar is rising like anything</h2>
                  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                </div>
              </div>
            </section>

            <section className="detal-section">
              <div className="container">
                  <figure>
                      <img src={DetailImg} alt="" />
                      <div className="date">
                          <p>25</p>
                          <span>MAR</span>
                      </div>
                  </figure>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
                  <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>

                  <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>

                  <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>
                  <p><u>Lorem ipsum dolor sit amet, consectetur</u></p>
                  <p><strong>Lorem ipsum dolor sit amet consectetur adipisicing elit.</strong></p>
                  <blockquote>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero labore dolorem, eligendi quidem natus vel quia nostrum praesentium quaerat rerum enim, sed repudiandae. Repudiandae, culpa reprehenderit. Natus autem quo earum.</blockquote>
                  <ul>
                      <li>dummy list one</li>
                      <li>dummy list two</li>
                      <li>dummy list three</li>
                      <li>dummy list four</li>
                  </ul>
                  <p><em>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis ipsa fugiat ratione doloribus perspiciatis iure modi, veniam earum. Dolorem, sed! Eligendi vel numquam iste placeat consequatur facilis ipsam esse exercitationem.</em></p>
                  <ol>
                      <li>dummy list one</li>
                      <li>dummy list two</li>
                      <li>dummy list three</li>
                      <li>dummy list four</li>
                  </ol>
              </div>
          </section>
            </>
        ) : (
            <div className="Loader">
              <small><Spinner animation="border" /></small>
            </div>
        )}
        </ThemeContainer>
    );
};

export default BlogDetail;
