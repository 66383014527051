import React, { useState, useEffect } from "react";
import {
  cryptoConfirmPayment,
  getReferalCode,
  rejectAssetRequest,
} from "../../core/services/api/nft";
import "../../assets/css/token-popup.css";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../redux/actions";
import useAlert from "../../hooks/use-alert";
import { copyText } from "../../core/utils/copy";
import CopyIcon from "../../assets/images/copy-icon.png";
import { NAME } from "../../utils/constants/project-types";

const ReferalCodePopUp = ({ hide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [refCode, setRefCode] = useState("");
  const handleCancel = async () => {
    navigate("/orders");
  };

  const handleCopy = (address) => {
    showAlert("SUCCESS", "Success", "Referral URL copied successfully");
    copyText(address);
  };

  const fetchReferalData = async () => {
    const email = localStorage.getItem("user_email");
    const body = {
      user_email: email,
    };
    const res = await getReferalCode(body);
    if (res.success) {
      setRefCode(res.ref_url);
    }
  };
  const newShortValue = (value) => {
    if (value) {
      if (value.length > 15) {
        return value.substring(0, 30) + "...";
      } else {
        return value;
      }
    } else {
      return "N/A";
    }
  };
  useEffect(() => {
    fetchReferalData();
  }, []);
  return (
    <>
      <div className="referal-code-modal font-w-600">
        <div className="row">
          <div className="row">
            <h3>Referral URL</h3>
          </div>
          <div className="row">
            <span>
              <div>{newShortValue(refCode)}</div>
              <button onClick={() => handleCopy(refCode)}>Copy</button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferalCodePopUp;
