import React from 'react';

const MarketAndFeatures = () => {
    return (
        <div className="card border-radidus-16">
            <div className="card-body">
                <h5 className="font-w-600 font-24 mb-2 font-34A853">Marketplace</h5>
                <h1 className="font-w-500 font-36 mb-2">Features</h1>
                <p className="font-16 font-w-400 font-656565 mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur molestie, nulla ut pretium egestas, erat nisl sodales odio, vitae consectetur urna enim et nulla. Maecenas.
                </p>
                <div className="row text-center">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div className="sunset-6CE3A4-bg border-radidus-50 circular-icon d-inline-block">
                            <i className="fal fa-user w-45 h-45 font-24 font-ffffff border-radidus-50 font-34A853 pt-2 mt-2"></i>
                        </div>
                        <p className="font-18 font-w-500 font-919191">Register yourself</p>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div className="sunset-6CE3A4-bg border-radidus-50 circular-icon d-inline-block">
                            <i className="fal fa-address-card w-45 h-45 font-24 font-ffffff border-radidus-50 font-34A853 pt-2 mt-2"></i>
                        </div>
                        <p className="font-18 font-w-500 font-919191">Verify your Identity</p>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div className="sunset-6CE3A4-bg border-radidus-50 circular-icon d-inline-block">
                            <i className="fal fa-search w-45 h-45 font-24 font-ffffff border-radidus-50 font-34A853 pt-2 mt-2"></i>
                        </div>
                        <p className="font-18 font-w-500 font-919191">Explore Opportunities</p>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                        <div className="sunset-6CE3A4-bg border-radidus-50 circular-icon d-inline-block">
                            <i className="fal fa-shopping-cart w-45 h-45 font-24 font-ffffff border-radidus-50 font-34A853 pt-2 mt-2"></i>
                        </div>
                        <p className="font-18 font-w-500 font-919191">Buy Tokens</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketAndFeatures;
