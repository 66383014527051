import React, { useRef, useState } from "react";
import ClubFormCrypto from "./addClubTabForm";
import { getWalletAddress } from "../../core/utils/token";
import { useSelector } from "react-redux";
const ClubForms = ({ page }) => {
  const { masterData, selectedCrypto, energyMasterData } = useSelector(
    (state) => state.nftReducer
  );

 let selected = 0
  const wallet = getWalletAddress();
  const data = masterData;
  const asset_data = data?.crypto_asset
  for (let i = 0; i < data?.crypto_asset.length; i+=1){
    if (asset_data[i].name.toLowerCase()==="thcnft"){
      selected = i
      break

    }
}
  const value = useRef(null);
  value.current = {
    walletAddress: wallet,
    token: data?.crypto_asset[selected],
    currency: data?.currency,
    currencyToken: data?.crypto_types,
    payment_acquirer_id: data?.crypto_acquirer[0].id,
  };

  const [token, setToken] = useState();

  return (
    <div className="tab-content" id="nav-tabContent">
      {data && <ClubFormCrypto data={value.current} page={page} />}
    </div>
  );
};

export default ClubForms;
