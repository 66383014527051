import React from "react";

const NFTMessageCrypto = ({ message }) => {
  
  return (
    <>
        <div
          className="nftmsg form-discount my-4"
          dangerouslySetInnerHTML={{ __html: message.cryptoMessage }}
        />
    </>
  );
};

export default NFTMessageCrypto;
