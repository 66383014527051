import React, { useState, useEffect } from "react";
import loginSide from "../../assets/images/login-side.png";
import "../../assets/css/crypto-style.css";
import "../../assets/css/crypto-reset.css";
import LoginForm from "../../components/shared/forms/login-fom";
import ForgetPasswordForm from "../../components/shared/forms/forget-password-form";
import leafImg from "../../assets/images/leaf.png";
import SignRegisterTabs from "../../components/shared/signup-and-register";
import Logo from "../../assets/images/DcommLogo.png";
import Dfender from "../../assets/images/sidebar/thc.png";
import Leader from "../../assets/images/successful-leader.png";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

const Login = () => {
  const [showForgetPasswordForm, setShowForgetPasswordForm] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  if (isLoggedIn) {
    navigate("/user-profile");
  }
  useEffect(() => {
    document.title = "Login";
    localStorage.clear();
  }, []);
  return (
    <>
      <div className="login-page">
        <div className="flex-rows">
          <div className="column-img">
            <Row>
              <Col md={2}>
                <div className="logo">
                  <Link to={"/"}>
                    <img src={Logo} title="" alt="" />
                  </Link>
                </div>
              </Col>
              {/* <Col md={3}>
                {" "}
                <div className="logo2">
                  <Link to={"/"}>
                    <img src={Dfender} title="" alt="" height={110} width={120} />
                  </Link>
                </div>
              </Col> */}
            </Row>

            {/*<div className="img-box">
                    <h2>Very good works are waiting for you <br />Sign In</h2>
                    <img src={Leader} className="img1" title="" alt="" />
                </div>*/}
          </div>
          <div className="column-text dcomm-login-form-layer">
            <div className="log-menu text-right">
              <ul>
                <li>
                  <NavLink to={"/login"} className="active">
                    Sign In
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/signup"}>Register</NavLink>
                </li>
              </ul>
            </div>

            <LoginForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
