/* eslint-disable new-cap */
import React from "react";
import InvestorCard from "./investor-card";
import ReactHtmlParser from "react-html-parser";

const HomepageInvestor = ({ banner, data, classes }) => {
  return (
    <>
      <div className={classes}>
        <div className="row">
          <div className="col-md-12">
            <div className="font-36 font-w-600">
              {ReactHtmlParser(banner.title)}
            </div>
          </div>
        </div>
        <div className="row">
          {data &&
            data.map((info, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                <InvestorCard investor={info} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default HomepageInvestor;
