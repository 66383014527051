/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import "../../../assets/css/banner.css";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";
import Bgimg from "../../../assets/images/banner.png";

const BlogBanner = ({ banners }) => {
  const location = useLocation();
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
    PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
  }

  return (
    <>
      {banners && banners.length > 0 ? (
        <section className="contact-banner" style={{ backgroundImage: `url(${Bgimg})` }}>
          <div className="container">
            <div className="text-center benner-ext">
              {ReactHtmlParser(banners[0].title)}
              {ReactHtmlParser(banners[0].banner_text)}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default BlogBanner;
