import React, { useState } from 'react';
import { validateContactUsForm } from '../../../core/validations/contact-us-validations';
import useForm from '../../../hooks/use-form';
import "../../../assets/css/contact-us.css";
import { saveContactUsData } from '../../../core/services/api/cms';
import { MESSAGE_VARIANT } from '../../../redux/redux-constant-types';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage, setSuccessMessage } from '../../../redux/actions';
import useAlert from '../../../hooks/use-alert';

function ContactUsForm() {
    const dispatch = useDispatch();
    const [showMessage, setShowMessage] = useState(false);
    const { message, messageType } = useSelector((state) => state.messageReducer);

    const handleContactUsSubmit = async () => {
        const result = await saveContactUsData(values);
        if (result && result.status) {
            setValues((preValues) => ({
              ...preValues,
              name: "",
              email: "",
              mobile: "",
              comments: "",
            }));
            showAlert("SUCCESS", "Success", "Submitted successfully.");
        } else {
            showAlert("ERROR", "Error", "Something went wrong. try again!");
        }
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 4000);
    };

    const { values, errors, handleSubmit, handleChange, setValues } = useForm(
        handleContactUsSubmit,
        validateContactUsForm
    );
    const { showAlert } = useAlert();


    return (          
        <section className="contact-form">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    {showMessage && message && (
                    <p className={
                        messageType === MESSAGE_VARIANT.ERROR
                        ? "text-danger"
                        : "text-success"
                    }
                    >
                    <small>{message}</small>
                    </p>
                    )}
                    <div className="form-group">
                        <input
                        type="text"
                        placeholder="e.g. James Align"
                        className="form-control"
                        name="name"
                        value={values.name === undefined ? "" : values.name}
                        onChange={handleChange}
                        />
                        {errors.name && (
                            <p className="text-danger">
                                <small>{errors.name}</small>
                            </p>
                        )}
                    </div>
                    <div className="form-group">
                        <input
                        type="email"
                        placeholder="e.g. james@interswitch.io"
                        className="form-control"
                        name="email"
                        value={values.email === undefined ? "" : values.email}
                        onChange={handleChange}
                        />
                        {errors.email && (
                            <p className="text-danger">
                                <small>{errors.email}</small>
                            </p>
                        )}
                    </div>
                    <div className="form-group">
                        <input
                        type="text"
                        placeholder="e.g. 9876543210"
                        className="form-control"
                        name="mobile"
                        value={values.mobile === undefined ? "" : values.mobile}
                        onChange={handleChange}
                        />
                        {errors.mobile && (
                            <p className="text-danger">
                            <small>{errors.mobile}</small>
                            </p>
                            )}
                    </div>
                    <div className="form-group">
                        <textarea
                        type="text"
                        rows={4}
                        className="form-control"
                        name="comments"
                        value={values.comments === undefined ? "" : values.comments}
                        onChange={handleChange}
                        placeholder="Enter your message"
                        />
                        {errors.comments && (
                            <p className="text-danger">
                            <small>{errors.comments}</small>
                            </p>
                        )}
                    </div>
                    <div className="submit-btn text-right">
                        <input type="submit" value="Send Message" className="btn btn-yellow" />
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ContactUsForm;
