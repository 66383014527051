/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import BgImg from "../../../assets/images/banner.png";

const Banner = ({ banners }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
  return (
    <>
      {banners && banners.length > 0 ? (
        <section className="banner-section token-banner" style={{ backgroundImage: `url(${BgImg})` }} >
          <div className="container">
              <div className="flex">
                <div className="col-left">
                    <div className="benner-ext">
                    {ReactHtmlParser(banners[0].title)}
                    {ReactHtmlParser(banners[0].banner_text)}
                    </div>
                </div>
                <div className="col-right">
                    <img
                    src={
                      banners && banners.length > 0
                        ? `${PATH}${banners[0].image_url}`
                        : ""
                    }
                    alt=""
                  />
                </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Banner;
