import React, { useEffect, useState } from "react";
import {
  getBuyTokenOrderList,
} from "../../core/services/api/nft";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../core/utils/pagination";
import Modal from "react-bootstrap/Modal";
import AssetRequestDetail from "./../shared/asset-request-detail-popup";
import paginationSubmit from "./../../hooks/pagination";
import { extendTime, formatDateTime } from "../../core/utils/time";
const NFTTableData = ({ counts, titleColor, page }) => {
  const { selectedCrypto, assetRequest, offSet } = useSelector(
    (state) => state.nftReducer
  );
  const { startDate, endDate, currentPage, pageNumber } = useSelector(
    (state) => state.paginationReducer
  );
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [assetRequestId, setAssetRequestId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const getData = async () => {
    const data = {
      /*page_no: pageNumber,
      crypto_token_id: selectedCrypto,*/
    };
    const res = await getBuyTokenOrderList(data);
    if (res.status) {
      setData(res.order_list);
      setTotalCount(5);
    }
  };
  const baseURL =
    currentPage == "nature"
      ? process.env.REACT_APP_API_NATURE_BASE_URL
      : process.env.REACT_APP_API_ENERGY_BASE_URL;
  const showModal = (e) => {
    setAssetRequestId(e);
    setIsOpen(true);
  };
  const dateOffSet = (val) => {
    if (offSet !== undefined) {
      const extendedTime = extendTime(val, offSet);
      const formatedDate = formatDateTime(extendedTime);
      return formatedDate;
    }
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (assetRequest === null) {
      getData();
    } else {
      setData(assetRequest.asset_request_list);
      setTotalCount(assetRequest.total_records);
    }
  }, [selectedCrypto, assetRequest, currentPage]);

  return (
    <>
      {data && offSet !== undefined && (
        <Pagination
          callback={(e) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            assetRequest === null
              ? getData()
              : paginationSubmit(
                  startDate,
                  endDate,
                  currentPage,
                  pageNumber,
                  selectedCrypto,
                  dispatch
                )
          }
          totalCount={totalCount}
          totalShow={data.length}
        >
        <div className="table-responsive">
          <table className="table" id="dtHorizontalExample">
            <thead className="table-ch">
              <tr className="">
                <th scope="col">Order Name</th>
                <th scope="col">Quantity</th>
                <th scope="col">Order Date</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody className="cursor-pointer">
            {data && data.length > 0 ? ( 
              <>
                {data.map((val, index) => (
                  <tr key={index}>
                    <th scope="row">{val.order_name}</th>
                    <td>{val.qty}</td>
                    <td>{dateOffSet(val.date_order)}</td>
                    <td>{val.state}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="4">No data found</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        </Pagination>
      )}
      <Modal size="lg" show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <AssetRequestDetail
            data={assetRequestId}
            hide={hideModal}
            page={page}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NFTTableData;
