import React, { useEffect, useState } from "react";
import URLS from "../../../configs/api-end-points";
import { NavLink, Link } from "react-router-dom";
import CryptoDinarLogo from "../../../assets/images/cryptoDinarLogo.svg";
import Logo from "../../../assets/images/DcommLogo.png";
import MobileLogo from "../../../assets/images/logo.svg";
// import Logo from "../../../assets/images/sidebar/sidebar-cryptodinar.svg";
import Paper from "../../../assets/images/paper.png";
import User from "../../../assets/images/user.png";
import Profile from "../profile";
import { useDispatch, useSelector } from "react-redux";
import {
  getCorporatePageData,
  getPageTypeData,
} from "../../../core/services/api/home";
import { setHeaderListData } from "../../../redux/actions/page-action";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import userProfile from "../../../assets/icons/user-profile.png";
import { clearLocalStorage, getUserName } from "../../../core/utils/token";
import MobileSideBar from "../../../components/shared/side-bar-mobile";

const Header = ({ page_type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = getUserName();
  const { isLoggedIn }    = useSelector((state) => state.userReducer);
  const { headerList }    = useSelector((state) => state.pageReducer);
  const [isFetching, setIsFetching] = useState(true);
  const [showMenuDropDown, setShowMenuDropDown] = useState(false);
  const [header_logo, setHeaderLogo] = useState("");
  const PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  const fetchPageData = async (
    link = "/api/v1/homepage/cryptodinar",
    pageType = "corporate"
  ) => {
    const result = await getPageTypeData(pageType, link);
    if (result) {
      setTimeout(() => {
        dispatch(setHeaderListData(result.header_list));
        setIsFetching(false);
      });
      setHeaderLogo(result.header_logo)
    } else {
      console.log(result);
    }
  };
  const handleRedirect = () => {
    navigate("/dashboard");
  };
  const handleLogout = () => {
    clearLocalStorage();
    navigate("/login");
  };
  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  }
  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  }
  useEffect(() => {
    fetchPageData();
  }, []);
  let headeLogo;
  if(header_logo){
    headeLogo = PATH+header_logo;
  }else{
    headeLogo = Logo;
  }

  return (
    <>
      {isLoggedIn && page_type === "private" ? (
        <div className="header-main">
          <nav className="navbar navbar-expand-md navbar-light bg-light">
            <div
              className="container-fluid "
              style={{
                justifyContent: "unset",
              }}
            >
              <button
                className="navbar-toggler ms-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={openNav}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <NavLink to="/" className="mobileLogo">
                <img src={MobileLogo} alt="" />
              </NavLink>

                {isLoggedIn && page_type === "private" && <Profile />}
            </div>
          </nav>
          {page_type === "private" &&
          <div id="mySidenav" className="sidenav">
            <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
            <MobileSideBar />          
          </div>
        }
        </div>
      ) : null}
    </>
  );
};

export default React.memo(Header);
