/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-negated-condition */
import {
  setSuccessMessage,
  setUserDetails,
  setUserLoginStatus,
  setUserName,
} from "../../redux/actions";
import {
  ACCESS_TOKEN_TYPES,
  USER_ID_TYPES,
} from "../../utils/constants/localstorage-types";

import { PROJECT_TYPES } from "../../utils/constants/project-types";

const loginSubmit = (data, dispatch, showAlert, page = "") => {
  if (page == "") {
    if (data.otp_verified === false) {
      const values = {
        userEmail: data.user_email,
      };
      const username = {
        username: data.user_email,
      };
      dispatch(setUserDetails(values));
      dispatch(setUserName(username));
      showAlert("SUCCESS", "Success", data.message);
      const nav = "/confirm_otp";
      return nav;
    } else {
      if (data && data.aws_session !== "" && data.aws_session !== undefined) {
        localStorage.setItem("aws_access_token", data.aws_access_token);
        localStorage.setItem("aws_session", data.aws_session);
        localStorage.setItem("user_email", data.user_email);
        localStorage.setItem("otp_check", true);
        localStorage.setItem(
          USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY],
          data.user_id ? data.user_id : null
        );
        localStorage.setItem(
          USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE],
          data.user_id
        );
        const nav = "/submit_otp";
        return nav;
      } else {
        localStorage.setItem(
          "cash_decimal_limit",
          data.cash_decimal_limit ? data.cash_decimal_limit : 2
        );
        localStorage.setItem(
          "crypto_decimal_limit",
          data.crypto_decimal_limit ? data.crypto_decimal_limit : 5
        );
        localStorage.setItem("sumsub_id", data.sumsub_user_applicant_id);
        localStorage.setItem("kyc_done", data.kyc_done);
        localStorage.setItem("user_email", data.user_email);
        localStorage.setItem("user_name", data.user_name);
        localStorage.setItem("wallet_address", data.wallet_address);
        localStorage.setItem("status", data.status);
        localStorage.setItem("otp_check", false);
        localStorage.setItem("login_time", Date.now());
        localStorage.setItem("aws_access_token", data.aws_access_token);
        localStorage.setItem("aws_session", data.aws_session);
        localStorage.setItem(
          ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE],
          data.access_token
        );
        localStorage.setItem(
          USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE],
          data.user_id
        );
        localStorage.setItem(
          ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY],
          data.access_token ? data.access_token : null
        );
        localStorage.setItem(
          USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY],
          data.user_id ? data.user_id : null
        );

        const values = {
          userEmail: data.user_email,
          userName: data.user_name,
          userId: data.user_id,
        };

        showAlert("SUCCESS", "Success", "User login successfully");
        dispatch(setUserLoginStatus(true));
        dispatch(setUserDetails(values));
        const nav = "/user-profile";
        return nav;
      }
    }
  } else {
    localStorage.setItem(
      "cash_decimal_limit",
      data.cash_decimal_limit ? data.cash_decimal_limit : 2
    );
    localStorage.setItem(
      "crypto_decimal_limit",
      data.crypto_decimal_limit ? data.crypto_decimal_limit : 5
    );
    localStorage.setItem("login_time", Date.now());
    localStorage.setItem("sumsub_id", data.sumsub_user_applicant_id);
    localStorage.setItem("kyc_done", data.kyc_done);
    localStorage.setItem("user_email", data.user_email);
    localStorage.setItem("user_name", data.user_name);
    localStorage.setItem("wallet_address", data.tsp.wallet_address);
    localStorage.setItem("status", data.status);
    localStorage.setItem("otp_check", true);
    localStorage.setItem("aws_access_token", data.tsp.aws_access_token);
    localStorage.setItem("aws_session", data.tsp.aws_session);
    localStorage.setItem(
      ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE],
      data.tsp.access_token
    );
    localStorage.setItem(
      USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE],
      data.tsp.user_id
    );
    localStorage.setItem(
      ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY],
      data.tsp.access_token ? data.tsp.access_token : null
    );
    localStorage.setItem(
      USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY],
      data.user_id ? data.tsp.user_id : null
    );

    const values = {
      userEmail: data.user_email,
      userName: data.user_name,
      userId: data.user_id,
    };
    showAlert("SUCCESS", "Success", "User login successfully");
    dispatch(setUserLoginStatus(true));
    dispatch(setUserDetails(values));
    const nav = "/user-profile";
    return nav;
  }
};
export default loginSubmit;
