import React, { useState, useEffect } from "react";
import NFTMessageCrypto from "./message-crypto";
import NFTMessageFiat from "./message-fait";
import { useSelector } from "react-redux";
import CryptoCard from "../../assets/images/cryptoCard.svg";
import CryptoLogo from "../../assets/images/crypto-card-logo.svg";
import CardOverlay from "./addDFender";
const NFTMessage = ({ page }) => {
  const { selectedCrypto, masterData, energyMasterData } = useSelector(
    (state) => state.nftReducer
  );
  const { currentCurrency } = useSelector((state) => state.paginationReducer);
  let message = {};
  const currentPage = page === "buyToken" ? masterData : energyMasterData;
  if (currentPage !== null) {
    message = {
      cryptoMessage: currentPage.crypto_asset[0].crypto_text,
    };
  }

  return (
    <>
      {message && message.cryptoMessage ? (
        <>
          {/*<CardOverlay img={CryptoCard} />*/}

          <div className="row px-4">
            {currentCurrency == "crypto" ? (
              <NFTMessageCrypto message={message} />
            ) : (
              <NFTMessageFiat message={message} />
            )}
          </div>
        </>
      ) : null}
    </>
  );
};

export default NFTMessage;
