import React, { useEffect, useState } from "react";
import { getDFenderOrderList } from "../../core/services/api/nft";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../core/utils/pagination";
import Modal from "react-bootstrap/Modal";
import AssetRequestDetail from "../shared/asset-request-detail-popup";
import paginationSubmit from "../../hooks/pagination";
import { extendTime, formatDateTime } from "../../core/utils/time";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/redemption.css";
import useAlert from "../../hooks/use-alert";
import CopyIcon from "../../assets/images/copy-icon.png";
import DF_Complete from "../../assets/images/dfender-complete.svg";
import DF_Reject from "../../assets/images/dfender-rejected.svg";
import DF_Pending from "../../assets/images/dfender-pending.svg";
import DF_InProcess from "../../assets/images/dfender-in-process.svg";
import { copyText } from "../../core/utils/copy";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Spinner } from "react-bootstrap";
const DFenderTableData = () => {
  const navigate = useNavigate();
  const { selectedCrypto, assetRequest, offSet } = useSelector(
    (state) => state.nftReducer
  );
  const { startDate, endDate, currentPage, pageNumber } = useSelector(
    (state) => state.paginationReducer
  );
  const baseURL = process.env.REACT_APP_API_NATURE_BASE_URL;
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [data, setData] = useState();
  const [assetRequestId, setAssetRequestId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const getData = async () => {
    const data = {
      page_no: pageNumber,
    };
    const res = await getDFenderOrderList(data);
    if (res.success) {
      setIsLoading(false);
      setData(res.data);
      setTotalCount(res.data.lenght);

      if (res.data.lenght < 0 || res.data == "") {
        navigate("/add-dfender");
      }
    }
  };
  const showModal = (e) => {
    setAssetRequestId(e);
    setIsOpen(true);
  };
  const dateOffSet = (val) => {
    if (offSet !== undefined) {
      const extendedTime = extendTime(val, offSet);
      const formatedDate = formatDateTime(extendedTime);
      return formatedDate;
    }
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const shortValue = (value) => {
    if (value) {
      const firstFour = value.substr(0, 4);
      const lastFour = value.substr(value.length - 4, value.length);
      return firstFour + "......" + lastFour;
    } else {
      return "N/A";
    }
    /*if(value.length > 15){
      return value.substring(0, 15) + "...";
    }else{
      return value;
    }*/
  };
  const newShortValue = (value) => {
    if (value) {
      if (value.length > 15) {
        return value.substring(0, 15) + "...";
      } else {
        return value;
      }
    } else {
      return "N/A";
    }
  };
  useEffect(() => {
    if (assetRequest === null) {
      getData();
    } else {
      setData(assetRequest.asset_request_list);
      setTotalCount(assetRequest.total_records);
    }
  }, [selectedCrypto, assetRequest, currentPage]);
  return (
    <>
      {isLoading ? (
        <div className="Loader">
          <small>
            <Spinner animation="border" />
          </small>
        </div>
      ) : null}
      {offSet !== undefined && (
        <Pagination
          callback={(e) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            assetRequest === null
              ? getData()
              : paginationSubmit(
                  startDate,
                  endDate,
                  currentPage,
                  pageNumber,
                  selectedCrypto,
                  dispatch
                )
          }
          totalCount={totalCount}
          totalShow={data && data.length}>
          <div className="table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>OT#</th>
                  <th>Wallet Id</th>
                  <th>Node Id</th>
                  <th>Dfender Ref</th>
                  <th>Referral Code</th>
                  <th>Status</th>
                  <th>Blockchain Txn</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  <>
                    {data.map((val, index) => (
                      <tr key={index}>
                        <td>{val.dfender_ref ? val.dfender_ref : "-"}</td>
                        <td>
                          {" "}
                          {shortValue(val.wallet_id)}{" "}
                          <img
                            className="cursor-pointer"
                            src={CopyIcon}
                            onClick={() => {
                              copyText(val.wallet_id),
                                showAlert(
                                  "SUCCESS",
                                  "Success",
                                  "Wallet Id Copied"
                                );
                            }}
                          />
                        </td>
                        <td>
                          {" "}
                          {shortValue(val.node_id)}{" "}
                          <img
                            className="cursor-pointer"
                            src={CopyIcon}
                            onClick={() => {
                              copyText(val.node_id),
                                showAlert(
                                  "SUCCESS",
                                  "Success",
                                  "Node Id Copied"
                                );
                            }}
                          />
                        </td>

                        <td>
                          {val.discord_username
                            ? newShortValue(val.discord_username)
                            : "-"}
                        </td>
                        <td>
                          {val.referral_code
                            ? newShortValue(val.referral_code)
                            : "-"}
                        </td>

                        <td>
                          {val.state == "pending" ? (
                            <Tippy interactive={true} content="Pending">
                              <img
                                src={DF_InProcess}
                                className="dfending-icon"
                              />
                            </Tippy>
                          ) : val.state == "done" ? (
                            <Tippy interactive={true} content="Completed">
                              <img
                                src={DF_Complete}
                                className="dfending-icon"
                              />
                            </Tippy>
                          ) : val.state == "reject" ? (
                            <Tippy interactive={true} content="Rejected">
                              <img src={DF_Reject} className="dfending-icon" />
                            </Tippy>
                          ) : (
                            <Tippy interactive={true} content="In Process">
                              <img src={DF_Pending} className="dfending-icon" />
                            </Tippy>
                          )}
                        </td>
                        <td>
                          {val.transaction_hash ? (
                            <a
                              onClick={() => {
                                window.open(val.explorer_url);
                              }}
                              target="_blank"
                              className="btan done pointer">
                              View
                            </a>
                          ) : (
                            <a
                              target="_blank"
                              className={
                                val.state == "reject"
                                  ? "btan reject"
                                  : "btan pending "
                              }>
                              {val.state == "reject" ? "Rejected" : "Pending"}
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="8">No records found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Pagination>
      )}
    </>
  );
};

export default DFenderTableData;
