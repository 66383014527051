/* eslint-disable new-cap */
import React, { useEffect, useState } from "react";
import "../../../assets/css/banner.css";
import URLS from "../../../configs/api-end-points";
import ReactHtmlParser from "react-html-parser";
import { useLocation } from "react-router";
import { PROJECT_TYPES } from "../../../utils/constants/project-types";
import Bgimg from "../../../assets/images/banner.png";
import PlayBtn from "../../../assets/images/play-btn.png";

const NewsList = ({ bannerSections }) => {
  let PATH = URLS.SERVER_IMAGE_FOLDER_URL;

  return (
    <>
      {bannerSections &&
        <section className="blog news">
          <div className="container">
              <div className="flex">
                {bannerSections &&
                  bannerSections.banner_image.map((blog, blog_key) => {
                    return (
                    <div className="box">
                      <div className="inner-box">
                          <div className="video-box">
                              <img src={PATH+blog.image_url} alt="" />
                              <div className="play-btn">
                                  <img src={PlayBtn} alt="" />
                              </div>
                          </div>
                          <div className="news-text">
                              {ReactHtmlParser(blog.heading)}
                              <div className="text-right">
                                  <a href="#">Read More...</a>
                              </div>
                          </div>
                      </div>
                    </div>
                  );
                })}
              </div>
          </div>
        </section>
      }
    </>
  );
};

export default NewsList;
