import React from "react";

const Ecosystem = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-2">ecosystem</div>
            <div className="col-md-8">
              <div className=""></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Ecosystem;
