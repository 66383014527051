import React, { useState, useEffect } from "react";
import CalculatorTab from "./calculator-tab";
import CalculatorForm from "./calculator-form";
import { getMasterData, getPriceToken } from "../../core/services/api/nft";

import Favourites from "../../assets/images/favourites.png";

const Calculator = () => {
  const [crypto, setCrypto] = useState("crypto");
  const [assetMaster, setAssetMaster] = useState();
  const getData = async () => {
    const res = await getMasterData();
    if (res && res.status) {
      setAssetMaster(res);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {crypto && assetMaster && (
        
        <section className="calc-section">
           <div className="container">
              <div className="flex">
                <div className="col-left">
                  <div className="calc-box">
                    {/*<CalculatorTab crypto={crypto} setCrypto={setCrypto} />*/}
                    <CalculatorForm crypto={crypto} assetMaster={assetMaster} />
                  </div> 
                </div> 
                 <div className="col-right">
                    <ul>
                       <li><img src={Favourites} alt="" /></li>
                       <li><img src={Favourites} alt="" /></li>
                       <li><img src={Favourites} alt="" /></li>
                       <li><img src={Favourites} alt="" /></li>
                       <li><img src={Favourites} alt="" /></li>
                    </ul>
                    <p>Based on 10.000+reviews!</p>
                 </div>
              </div>
           </div>
        </section>
      )}
    </>
  );
};
export default Calculator;
