/* eslint-disable default-param-last */
import { PAGINATION_TYPES } from "../redux-constant-types";

const initialState = {
  startDate: "",
  endDate: "",
  currentPage: "nature",
  pageNumber: 1,
  currentCurrency: "crypto",
};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGINATION_TYPES.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload.startDate,
      };
    case PAGINATION_TYPES.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload.endDate,
      };
    case PAGINATION_TYPES.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
      };
    case PAGINATION_TYPES.SET_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: action.payload.currentCurrency,
      };
    case PAGINATION_TYPES.SET_PAGE_NO:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
};

export default paginationReducer;
