import { PAGINATION_TYPES } from "../redux-constant-types";

const setStartDate = (values) => {
  return {
    type: PAGINATION_TYPES.SET_START_DATE,
    payload: {
      startDate: values,
    },
  };
};
const setEndDate = (values) => {
  return {
    type: PAGINATION_TYPES.SET_END_DATE,
    payload: {
      endDate: values,
    },
  };
};
const setCurrentPage = (values) => {
  return {
    type: PAGINATION_TYPES.SET_CURRENT_PAGE,
    payload: {
      currentPage: values,
    },
  };
};

const setCurrentCurrency = (values) => {
  return {
    type: PAGINATION_TYPES.SET_CURRENT_CURRENCY,
    payload: {
      currentCurrency: values,
    },
  };
};
const setPageNumber = (values) => {
  return {
    type: PAGINATION_TYPES.SET_PAGE_NO,
    payload: {
      pageNumber: values,
    },
  };
};

export {
  setStartDate,
  setEndDate,
  setCurrentPage,
  setPageNumber,
  setCurrentCurrency,
};
