/* eslint-disable new-cap */
import React from 'react';
import URLS from '../../configs/api-end-points';
import CountEntryCard from './count-entry-card';
import ReactHtmlParser from 'react-html-parser';
import { PROJECT_TYPES } from '../../utils/constants/project-types';
import { useLocation } from 'react-router';

function ImageRightContentLeftWidgetCard({ bannerSection, classes }) {
    const location = useLocation();
    let PATH = URLS.SERVER_IMAGE_FOLDER_URL;
    if (location.pathname.includes(PROJECT_TYPES.PROJECT_TYPES_ENERGY)) {
        PATH = URLS.SERVER_ENERGY_IMAGE_FOLDER_URL;
    }
    return (
        <div className={classes}>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                {ReactHtmlParser(bannerSection.banner_text)}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-2 p-sm-4 p-md-3 p-lg-4">
                { bannerSection && bannerSection.widget && <div className="entry-card container3 m-5">
                    <CountEntryCard widgets={bannerSection.widget}/>
                </div> }
                <img src={`${PATH}${bannerSection.image_url}`} className="w-100"/>
            </div>

        </div>
    );
}

export default ImageRightContentLeftWidgetCard;
