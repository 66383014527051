import React from 'react';
import facebookIcon from "../../assets/images/facebook-image.svg";
import twitterIcon from "../../assets/images/twitter-image.svg";
import discourceIcon from "../../assets/images/discource-image.svg";
import googleIcon from "../../assets/images/google-image.svg";
import platesIcon from "../../assets/images/plates-image.svg";
import redditIcon from "../../assets/images/reddit-image.svg";
import discordIcon from "../../assets/images/discord-image.svg";
import SocialIconCard from '../../components/modals/social-icon-card';


const GlobalCommunity = ({ title1, heading1 }) => {
    return (
        <div className="row">
            <div className="col-12 col-sm-12 col-md-6 align-self-center p-4 p-sm-5 p-md-4 p-lg-5">
                { title1 && <h3 className="font-24 font-w-600 mb-4 font-34A853">{title1}</h3> }
                { heading1 && <h2 className="font-36 font-w-600 mb-4">{heading1}</h2> }
                <p className="font-18 font-w-400 mb-4 font-656565">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                <p className="font-18 font-w-400 font-656565">Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            </div>
            <div className="col-12 col-sm-12 col-md-6 m-auto align-self-center">
                <div className="row ml-5 mt-lg-5">
                    <div className="col-4 col-sm-3 col-md-2 align-self-center">
                        <SocialIconCard icon={redditIcon}/>
                        <SocialIconCard icon={twitterIcon}/>
                    </div>
                    <div className="col-4 col-sm-3 col-md-2">
                        <SocialIconCard icon={facebookIcon}/>
                        <SocialIconCard icon={platesIcon}/>
                        <SocialIconCard icon={googleIcon}/>
                    </div>
                    <div className="col-4 col-sm-3 col-md-2 align-self-center">
                        <SocialIconCard icon={discourceIcon}/>
                        <SocialIconCard icon={discordIcon}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GlobalCommunity;
