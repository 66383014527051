import React, { useState, useEffect } from "react";
import ThemeContainer from "../../containers/theme-container";
import eclipseImage2 from "../../assets/images/sunset_banner_energy_eclipse2.png";
import eclipseImage from "../../assets/images/sunset_banner_energy_eclipse.png";
import target from "../../assets/images/target.png";
import "../../assets/css/login.css";
import CryptoDinarCoin from "../../assets/images/crypro-dinar-coin.svg";
import UploadImg from "../../assets/images/uploadImage.png";
import UserProfileForm from "../../components/shared/forms/userProfile-form";
import {
  getUserDetail,
  postUploadUserPhoto,
  disable2Fa,
} from "../../core/services/api/nft";
import UserProfileCard from "../../components/userProfile-card";
import Modal from "react-bootstrap/Modal";
import UserProfilePopUp from "../../components/shared/userProfile-popup";
import { useNavigate } from "react-router-dom";
import {
  getAccessToken,
  getUserId,
  getAWSToken,
  getUserEmail,
} from "../../core/utils/token";
import URLS from "../../configs/api-end-points";
import useAlert from "../../hooks/use-alert";
import { useSelector, useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/actions";
import { Spinner } from "react-bootstrap";

const QRCode = () => {
  const [data, setData] = useState();
  const [UserImage, setUserImage] = useState("");
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiUrl = URLS.NATURE_BASE_URL;
  const [fileview2, setfileView2] = useState();
  const { showAlert } = useAlert();
  const { isLoggedIn } = useSelector((state) => state.userReducer);
  const [isLoading, setIsLoading] = useState(true);

  if (!isLoggedIn) {
    navigate("/login");
  }

  const getUserData = async () => {
    const res = await getUserDetail();
    if (res.success) {
      setIsLoading(false);
      setData(res);
      setUserImage(res.image_url);
      setTwoFactorAuth(res.two_factor_auth);
      dispatch(setUserDetails(res));
    } else {
      setIsLoading(false);
    }
  };
  const onFileChange = async (e) => {
    const img = e.target.files[0];
    if (img?.type.includes("image") && !img?.type.includes("webp")) {
      setIsLoading(true);
      const userId = getUserId();
      const accessToken = getAccessToken();
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setfileView2(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("image", e.target.files[0], "[PROXY]");
      formData.append("user_id", userId);
      formData.append("access_token", accessToken);
      await fetch(apiUrl + "/api/v1/image/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.success) {
            setIsLoading(false);
            //getUserData();
            showAlert("SUCCESS", "Success", data.message);
            window.location.reload();
            //setUserImage("");
          } else {
            setIsLoading(false);
            showAlert("ERROR", "Error", data.message);
            //console.log(data);
          }
        });
    } else {
      showAlert(
        "ERROR",
        "Error",
        "Invalid file format. Only allowed jpg, png images."
      );
    }
  };
  const disableMFA = async () => {
    if (twoFactorAuth) {
      const userId = getUserId();
      const accessToken = getAccessToken();
      const awsToken = getAWSToken();
      const userName = getUserEmail();
      const request = {
        user_id: userId,
        user_name: userName,
        aws_sso_token: awsToken,
      };
      const res = await disable2Fa(request);
      if (res.status) {
        getUserData();
        showAlert("SUCCESS", "Success", res.message);
      } else {
        showAlert("ERROR", "Error", res.message);
        //console.log(data);
      }
    } else {
      showAlert("ERROR", "Error", "Please enable 2FA first.");
    }
  };
  const showModal = () => {
    if (!twoFactorAuth) {
      setIsOpen(true);
    } else {
      showAlert("ERROR", "Error", "Google Authenticator already enabled.");
    }
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    setIsLoading(true);
    getUserData();
  }, []);

  return (
    <ThemeContainer type="private" pageTitle="User Profile">
      {isLoading ? (
        <div className="Loader">
          <small>
            <Spinner animation="border" />
          </small>
        </div>
      ) : null}
      {data ? (
        <>
          <div className="dcomm-bg-layer">
            <div className="row user-image-section px-md-5 py-md-5">
              <div className="col-lg-3 text-center my-4">
                <div className="profile-img">
                  {UserImage ? (
                    <img
                      src={`${UserImage}`}
                      height="150px"
                      onClick={handleClick}
                      className="cursor-pointer"
                    />
                  ) : (
                    <>
                      {fileview2 ? (
                        <img
                          src={fileview2}
                          height="150px"
                          onClick={handleClick}
                          className="cursor-pointer"
                        />
                      ) : (
                        <img
                          src={UploadImg}
                          height="120px"
                          onClick={handleClick}
                          className="cursor-pointer"
                        />
                      )}
                    </>
                  )}
                  <div className="edit-pic">
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={onFileChange}
                      hidden
                    />
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-4 px-4">
                <div className="row">
                  <div className="row">
                    <h2 className="crypto-dinar-white font-w-600">
                      {data.name}
                    </h2>
                  </div>
                  <div className="row">
                    <UserProfileCard data={data} />
                    <div className="two-fa-section my-4">
                      <div className="crypto-dinar-white font-w-600 font-24">
                        Google Authenticator
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <button
                            className="profile-disable-btn"
                            onClick={showModal}>
                            Enable
                          </button>
                          <button
                            className="profile-disable-btn disable-2fabtn"
                            onClick={() => {
                              window.confirm(
                                "Are you sure you want to disable 2FA?"
                              ) && disableMFA();
                            }}>
                            Disable
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-center my-4">
                <button
                  className="profile-disable-btn"
                  onClick={() => navigate("/update/user-profile")}>
                  Edit Profile{"  "}
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <Modal size="lg" show={isOpen} onHide={hideModal}>
            <Modal.Body>
              <UserProfilePopUp hide={hideModal} getUserData={getUserData} />
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </ThemeContainer>
  );
};

export default QRCode;
