import URLS from "../../../configs/api-end-points";
import apiServices from "../data/api_service";

const saveContactUsData = async (values) => {
  const data = {
    name: values.name,
    email: values.email,
    mobile: values.mobile,
    comment: values.comments
  };
  const url = URLS.CONTACT_US;
  const result = await apiServices.post(url, data);
  return result;
};

const subscribeNewsletter = async (values) => {
  const data = {
    email: values.email
  };
  const url = URLS.SUBSCRIBE_NEWSLETTER;
  const result = await apiServices.post(url, data);
  return result;
};

const activateNewsletterSubscribe = async (values) => {
  const data = {
    email: values.email,
    secure_code: values.secureCode
  };
  const url = URLS.ACTIVATE_SUBSCRIBE_NEWSLETTER;
  const result = await apiServices.post(url, data);
  return result;
};

export { saveContactUsData, subscribeNewsletter, activateNewsletterSubscribe };
