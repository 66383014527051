import React, { useState } from "react";
import { validateQRCode } from "../../../core/validations/user-validations";
import useForm from "../../../hooks/use-form";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGE_VARIANT } from "../../../redux/redux-constant-types";
import { validateQrCodeAPI } from "../../../core/services/api/nft";
import { NavLink, useNavigate } from "react-router-dom";
import loginSubmit from "./../../../core/utils/login_submit";
import useAlert from "../../../hooks/use-alert";

import {
  clearLocalStorage,
  getAWSSession,
  getAWSToken,
  getEnergyId,
  getUserEmail,
} from "../../../core/utils/token";
import OtpInput from "react-otp-input";
import LockIcon from "../../../assets/images/icon-lock.png";
import BackIcon from "../../../assets/images/icon-back.png";

const SubmitQrCodeForm = () => {
  const dispatch = useDispatch();
  const { message, messageType } = useSelector((state) => state.messageReducer);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const { showAlert } = useAlert();

  const handleSubmitVerify = async () => {
    const data = {
      aws_sso_token: getAWSToken(),
      qr_code: values.password,
      aws_sso_session: getAWSSession(),
      user_name: getUserEmail(),
      multi_tsp_user_id: getEnergyId(),
    };
    const res = await validateQrCodeAPI(data);
    if (res.status) {
      const result = loginSubmit(res, dispatch, showAlert, "submit");
      navigate(result);
    } else {
      showAlert("ERROR", "Error", res.message);  
    }
  };
  const handleChange = (code) => {
    setValues((preValues) => ({
      ...preValues,
      password: code,
    }));
  };
  const redirect = () => {
    navigate("/login");
  }
  const { values, errors, handleSubmit, setValues } = useForm(
    handleSubmitVerify,
    validateQRCode
  );
  return (
    <>
    <div className="column-text dcomm-login-form-layer">
      <div className="text-left pb-40">
          <button type="button" className="btn-icon" onClick={() => clearLocalStorage()} onClick={redirect}><NavLink to="/login"><img src={BackIcon} title="Back" className="back-icon" /></NavLink></button>
      </div>
      <div className="text-center">
          <h3 className="crypto-dinar-white font-gil-bold">Verify OTP</h3>
          <div className="icon-box">
              {/*<span className="icon all-center">
                  <img src={LockIcon} title="" alt="" />
              </span>*/}
              <p className="text-white">Please enter the 6 Digit Code</p>
          </div>
      </div>
      <form onSubmit={handleSubmit}>
        {showMessage && message && (
          <p
            className={
              messageType === MESSAGE_VARIANT.ERROR
                ? "text-danger"
                : "text-success"
            }
          >
            <small>{message}</small>
          </p>
        )}
        <div className="log-box otp-diff-inputs">
            <div className="otp-inputs">
                <OtpInput
                  value={values.password}
                  onChange={handleChange}
                  numInputs={6}
                  name="password"
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    // border: "1px solid black",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "16px",
                    color: "rgba(25, 55, 110, 1)",
                    fontWeight: "600",
                    backgroundColor: "#F5F8FF",
                  }}
                  focusStyle={{
                    // border: "1px solid black",
                    outline: "none",
                  }}
                />
                {errors.password && (
                  <p className="text-danger">
                    <small>{errors.password}</small>
                  </p>
                )}
            </div>
            {errors.confirmationCode && (
              <p className="text-danger text-center">
                <small>{errors.confirmationCode}</small>
              </p>
            )}
            <div className="form-group pt-10">
                <button type="submit" className="buy-coin-btn w-100">Verify</button>
            </div>
        </div>
      </form>
    </div>
    </>
  );
};

export default SubmitQrCodeForm;
