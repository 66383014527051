import React, { useEffect } from "react";
import ScanIcon from "../../assets/images/scan-icon.png";
import Modal from "react-bootstrap/Modal";
import WalletScanPopup from "./wallet-scan-popup";
import useForm from "./../../hooks/use-form";
import {
  validateConfirmWallet,
  checkERC20,
} from "../../core/validations/user-validations";
import { getWalletAddress, setWalletAddress } from "../../core/utils/token";
import { updateWalletAddress } from "../../core/services/api/nft";
import metamaskService from "../../core/services/smart-contracts/metamask";
import {
  setUserWalletAddress,
  setIsWalletConnected,
} from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import useAlert from "./../../hooks/use-alert";
import CopyIcon from "../../assets/images/copy-icon.png";
import { copyText } from "../../core/utils/copy";
const WalletAddressFormNature = () => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  const { walletAddress, isWalletConnected, isMetamaskInstalled, userDetails } =
    useSelector((state) => state.userReducer);
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const handleConnectWithWallet = async () => {
    if (!isMetamaskInstalled) {
      alert("Please Install Metamask to use this service");
      return;
    }
    const result = await metamaskService.connectWithWallet();
    if (result && !result.error) {
      walletUpdate(result.accountAddress);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      alert(result.message);
    }
  };
  const walletUpdate = async (val) => {
    const value = val ? val : values.wallet;
    const res = await checkERC20(value);
    if (res.status == true) {
      const data = {
        walletAddress: value,
      };
      const result = await updateWalletAddress(data);
      if (result.status) {
        dispatch(setUserWalletAddress(value));
        dispatch(setIsWalletConnected(true));
        setWalletAddress(value);
        showAlert("SUCCESS", "Success", "Wallet Address Updated");
      }
    } else {
      setErrors((preValues) => ({
        ...preValues,
        confirmWallet: res.message,
      }));
    }
  };
  const getInfo = (data1, data2) => {
    setValues({
      wallet: data1,
      confirmWallet: data2,
    });
    walletUpdate();
  };
  const { values, errors, handleSubmit, handleChange, setErrors, setValues } =
    useForm(walletUpdate, validateConfirmWallet);
  useEffect(() => {
    const address = getWalletAddress();
    setValues({
      wallet: address ? address : walletAddress,
    });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <div className="wallet-form py-4 px-4">
            <div className="col-md-12 d-grid">
              <a
                onClick={() => {
                  window.open("https://wallet.dcomm.network");
                }}
                target="_blank">
                <button className="font-919191 button-tab-active w-100 mt-4 f-bold no-bdr h-50 create-new cursor-pointer">
                  Create New Wallet
                </button>
              </a>
            </div>
            <div className="col-md-12 d-grid">
              <div className="connect-wallet-option">
                <h5>or</h5>
              </div>
            </div>
            <div className="kyc-form-head my-4  row">
              <div className="col">
                {" "}
                <h2 className="crypto-dinar-white">
                  Confirm your wallet address
                </h2>
                <label className="form-label crypto-dinar-grey">
                  Address must be action chain ACT start with 0x
                </label>
              </div>
            </div>
            <form className="row g-3">
              <div className="col-md-12 my-3">
                <label
                  for="inputEmail4"
                  className="form-label crypto-dinar-white">
                  Wallet Address
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-gr"
                    id="inputEmail4"
                    placeholder="Wallet Address"
                    name="wallet"
                    onChange={handleChange}
                    value={
                      values.wallet === "undefined" || values.wallet === "false"
                        ? ""
                        : values.wallet
                    }
                  />
                  <div
                    className="input-group-text bg-gr cursor-pointer copy-icon"
                    onClick={() => {
                      copyText(values.wallet),
                        showAlert(
                          "SUCCESS",
                          "Success",
                          "Wallet Address Copied"
                        );
                    }}>
                    <img src={CopyIcon} />
                  </div>
                </div>
                {errors.wallet && (
                  <p className="text-danger">
                    <small>{errors.wallet}</small>
                  </p>
                )}
              </div>
            </form>
            <div className="col-md-12">
              <label
                for="inputEmail4"
                className="form-label crypto-dinar-white">
                Confirm Wallet Address
              </label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-gr"
                  id="inputEmail4"
                  placeholder="Confirm Wallet Address"
                  name="confirmWallet"
                  onChange={handleChange}
                  value={
                    values.confirmWallet === undefined
                      ? ""
                      : values.confirmWallet
                  }
                />
                <div
                  className="input-group-text bg-gr cursor-pointer copy-icon"
                  onClick={() => {
                    copyText(values.confirmWallet),
                      showAlert("SUCCESS", "Success", "Wallet Address Copied");
                  }}>
                  <img src={CopyIcon} />
                </div>
              </div>
              {errors.confirmWallet && (
                <p className="text-danger">
                  <small>{errors.confirmWallet}</small>
                </p>
              )}
            </div>
            <div className="col-md-12 d-grid">
              <button
                onClick={handleSubmit}
                className="font-919191 button-tab-active w-100 mt-4 f-bold no-bdr h-50 update">
                Confirm ACT Wallet Address
              </button>
            </div>
            {/* <div className="col-md-12 d-grid">
              <button
                className="font-919191 button-tab-active w-100 mt-4 f-bold no-bdr h-50 connect-wallet"
                onClick={handleConnectWithWallet}>
                Connect Wallet
              </button>
            </div> */}
            <div className="col-md-12 d-grid ">
              <a
                className="font-919191 mt-4 f-bold no-bdr d-flex align-items-center justify-content-center profile-disable-btn h-50 scan-address"
                onClick={showModal}>
                Scan Your ACT Address
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-3"></div>
      </div>

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Body>
          <WalletScanPopup hide={hideModal} data={getInfo} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletAddressFormNature;
